import {ID} from '../_metronic/helpers'

export interface Role {
  id?: ID
  name: string
  code?: string
  role_type?: string;

}

export const InitialValue = {
  name: '',
  code: '',
  role_type: '',
}

export interface RoleRequest {
  id?: ID
  code?: string
  name: string
  privilege?: Array<string>
  role_type?: string
}
