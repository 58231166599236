/* eslint-disable react/jsx-no-target-blank */
import Badge from 'react-bootstrap/Badge'
import {SidebarMenuItem} from './SidebarMenuItem'
import {VAuth} from '../../../../../pages/auth/core/Auth'
import {useApprovalCountContext} from '../../../../../pages/resources/context/ApprovalCountContext'
import {useApprovalOwnerCountContext} from '../../../../../pages/resources/context/ApprovalOwnerCountContext'

const SidebarMenuMain = () => {
  const {count} = useApprovalCountContext()
  const {countOwner} = useApprovalOwnerCountContext()
  return (
    <>
      <SidebarMenuItem to='/dashboard' title={'Live Dashboard'} fontIcon='bi-reception-4' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Visitor Management
          </span>
        </div>
      </div>
      <VAuth>
        <SidebarMenuItem to='/visitor' title={'Visitor Database'} fontIcon='bi-person-vcard-fill' />
      </VAuth>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Database</span>
        </div>
      </div>
      <VAuth>
        <SidebarMenuItem to='/user_danoners' title={'Users Danone'} fontIcon='bi-people' />
      </VAuth>
      <VAuth>
        <SidebarMenuItem to='/areas' title={'Area'} fontIcon='bi-geo-alt-fill' />
      </VAuth>
      <VAuth>
        <SidebarMenuItem
          to='/efectiveDate?date_owner=quesioners'
          title={'Kuisioner'}
          fontIcon='bi-check2-circle'
        />
      </VAuth>
      <VAuth>
        <SidebarMenuItem
          to='/efectiveDate?date_owner=post_test'
          title={'Post Test'}
          fontIcon='bi-check2-square'
        />
      </VAuth>
      <VAuth>
        <SidebarMenuItem to='/videos' title={'Video'} fontIcon='bi-play' />
      </VAuth>
      <VAuth>
        <SidebarMenuItem to='/master_maps' title={'Maps'} fontIcon='bi-globe-asia-australia' />
      </VAuth>
      <VAuth>
        <SidebarMenuItem to='/master_range' title={'Range'} fontIcon='bi-clock' />
      </VAuth>
      <VAuth>
        <SidebarMenuItem to='/factory' title={'Factory'} fontIcon='bi-globe-asia-australia' />
      </VAuth>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>User Management</span>
        </div>
      </div>
      <div className='row'>
        <div className='col-10'>
          <VAuth>
            <SidebarMenuItem to='/approvals' title='Approval User' fontIcon='bi-check' />
          </VAuth>
        </div>
        <div className='col-2'>
          <VAuth>{/* <SidebarMenuItem to='/approvals' title={count.toString()} /> */}</VAuth>
        </div>
      </div>
      <div className='row'>
        <div className='col-10'>
          <VAuth>
            <SidebarMenuItem to='/approvalOwner' title='Approval Owner' fontIcon='bi-check' />
          </VAuth>
        </div>
        <div className='col-2'>
          <VAuth>
            {/* <SidebarMenuItem to='/approvalOwner' title={countOwner.toString()} /> */}
          </VAuth>
        </div>
      </div>
      <VAuth>
        <SidebarMenuItem to='/user' title={'Users'} fontIcon='bi-people-fill' />
      </VAuth>
      <VAuth>
        <SidebarMenuItem to='/sysparam' title='Sysparam' fontIcon='bi-layers' />
      </VAuth>
      <VAuth>
        <SidebarMenuItem to='/role' title='Role' fontIcon='bi-layers' />
      </VAuth>
      <VAuth>
        <SidebarMenuItem to='/privileges' title='Privileges' fontIcon='bi-layers' />
      </VAuth>
    </>
  )
}

export {SidebarMenuMain}
