import {FC, useState, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useLocation, useNavigate, Link} from 'react-router-dom'
import {ErrorMessage} from '@hookform/error-message'
import {useApi} from '../../../../hooks/useApi'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Factory} from '../../../../models/factory'
import {MasterMaps} from '../../../../models/master_maps'
import ClipLoader from 'react-spinners/ClipLoader'

export const CreatePage: FC = () => {
  const routeParams = useLocation()
  const routeUrl = routeParams.pathname.replace('/', '')
  const splitCollection = routeUrl.split('/')
  const route = splitCollection[0]
  const {getAll, create} = useApi()
  const [imageFactory, setImageFactory] = useState('')
  const [iconFactory, setIconFactory] = useState('')
  const [exist, setExist] = useState('Required')
  const [factory, setFactory] = useState<Factory[]>([])
  const [url, setUrl] = useState<any | null>('')
  const [urlIcon, setUrlIcon] = useState<any | null>('')
  const [loading, setLoading] = useState(false)

  // const [valVidio, setValvidio] = useState<string>('')

  const MySwal = withReactContent(Swal)
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm()
  const onError = (errors: any, e: any) => console.log(errors, e)
  const upload = async (payload: any) => {
    const response = await create(`video/upload?bucket=maps`, payload)

    return response
  }
  const saveImage = (e: any) => {
    const data = e.target.files[0]
    const oFReader = new FileReader()
    oFReader.readAsDataURL(data)
    oFReader.onload = () => {
      const result = oFReader.result
      setUrl(result)
    }
    setImageFactory(data)
  }
  const saveIcon = (e: any) => {
    const data = e.target.files[0]
    const oFReader = new FileReader()
    oFReader.readAsDataURL(data)
    oFReader.onload = () => {
      const result = oFReader.result
      setUrlIcon(result)
    }
    setIconFactory(data)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFactoryList = async () => {
    const response = await getAll<Factory>('factory')
    const {data} = response.data
    setFactory(data)
  }
  useEffect(() => {
    getFactoryList()
  }, [])
  const onSubmit = async (data: any, e: any) => {
    setLoading(true)
    const formDataImg = new FormData()
    formDataImg.append('file', imageFactory)
    formDataImg.append('test', 'title')
    const formDataIcon = new FormData()
    formDataIcon.append('file', iconFactory)
    try {
      const responseImg = await upload(formDataImg)
      const responseIcon = await upload(formDataIcon)
      if (responseImg.data && responseIcon.data) {
        data.icon = responseIcon.data['name']
        data.image = responseImg.data['name']
        // saveMaps(data)
        await create(`master_maps`, data)
          .then((response) => {
            MySwal.fire({
              title: <strong className='text-black'>Success</strong>,
              html: <i className='text-black'>Data created!</i>,
              icon: 'success',
            })
            navigate(`/${route}`)
          })
          .catch((error) => {
            if (error.response) {
              const {data} = error.response
              MySwal.fire({
                title: <strong className='text-black'>Failed</strong>,
                html: <i className='text-black'>{data.errors[0].message}!</i>,
                icon: 'error',
              })
            }
          })
          .finally(() => setLoading(false))
      }
    } catch (error) {
      console.info(error)
    }
  }
  return (
    <div>
      {loading === true ? (
        <div className='loader'>
          <ClipLoader
            color={'#00008B'}
            loading={true}
            size={50}
            aria-label='Loading Spinner'
            data-testid='loader'
            // style={{}}
          />
        </div>
      ) : (
        ''
      )}
      <div className='card card-custom gutter-b'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>
              Create
              <small></small>
            </h3>
          </div>
        </div>
        <div className='card-body'>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className='row'>
              <div className={`mb-4 col-4`}>
                <div className='form-group'>
                  <label className='text-black'>Land Area</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    {...register('land_area', {
                      required: true,
                    })}
                  />
                  <ErrorMessage errors={errors} name='land_area' />
                  <ErrorMessage
                    errors={errors}
                    name='land_area'
                    render={() => <p className='text-danger'>land_area is required !</p>}
                  />
                </div>
              </div>
              <div className={`mb-4 col-4`}>
                <div className='form-group'>
                  <label className='text-black'>Building Area</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    {...register('building_area', {
                      required: true,
                    })}
                  />
                  <ErrorMessage errors={errors} name='building_area' />
                  <ErrorMessage
                    errors={errors}
                    name='building_area'
                    render={() => <p className='text-danger'>building_area is required !</p>}
                  />
                </div>
              </div>
              <div className={`mb-4 col-4`}>
                <div className='form-group'>
                  <label className='text-black'>Factory Id</label>
                  <select
                    className='form-control form-control-solid'
                    {...register('factory_id', {
                      required: true,
                      validate: async (value, formValues) => {
                        const parms = {
                          factory_id: value,
                        }
                        const response = await getAll<MasterMaps>('master_maps', parms)
                        const {data} = response.data
                        if (data.length) {
                          setExist('Existing')
                        }
                        return data.length === 0
                      },
                    })}
                  >
                    <option disabled selected>
                      Choice Factory
                    </option>
                    <>
                      {factory.map((entry: Factory) => {
                        return (
                          <option
                            key={entry.id}
                            className='form-control form-control-solid text-black'
                            id=''
                            value={entry.id}
                          >
                            {entry.name}
                          </option>
                        )
                      })}
                    </>
                  </select>
                  <ErrorMessage errors={errors} name='factory_id' />
                  <ErrorMessage
                    errors={errors}
                    name='factory_id'
                    render={() => <p className='text-danger'>Factory is {exist} !</p>}
                  />
                </div>
              </div>
              <div className={`mb-4 col-6`}>
                <div className='form-group'>
                  <label className='text-black'>Image</label>
                  <input
                    type='file'
                    className='form-control form-control-solid'
                    {...register('image', {
                      required: true,
                      validate: {
                        lessThan10MB: (files) => files[0]?.size < 5000000 || 'Max 5 mb',
                        // acceptedFormats: (files) =>
                        //   ['image/png', 'image/jpeg'].includes(files[0]?.type) || 'Only png and jpeg',
                      },
                    })}
                    accept='image/*'
                    onChange={saveImage}
                  />
                  <ErrorMessage errors={errors} name='image' />
                  <ErrorMessage
                    errors={errors}
                    name='image'
                    render={() => <p className='text-danger'>image is required ! </p>}
                  />
                </div>
              </div>
              <div className={`mb-4 col-6`}>
                <div className='form-group'>
                  <label className='text-black'>Icon</label>
                  <input
                    type='file'
                    className='form-control form-control-solid'
                    {...register('icon', {
                      required: true,
                      validate: {
                        lessThan10MB: (files) => files[0]?.size < 5000000 || 'Max 5 mb',
                        // acceptedFormats: (files) =>
                        //   ['image/png', 'image/jpeg'].includes(files[0]?.type) || 'Only png and jpeg',
                      },
                    })}
                    accept='image/*'
                    onChange={saveIcon}
                  />
                  <ErrorMessage errors={errors} name='icon' />
                  <ErrorMessage
                    errors={errors}
                    name='icon'
                    render={() => <p className='text-danger'>icon is required !</p>}
                  />
                </div>
              </div>

              <div className='col-6 my-5 text-center'>
                <>
                  {url !== '' ? (
                    <>
                      {/* <video key={url} controls width='320' height='240'>
                      <source key={url} src={url} type='video/mp4' />
                    </video> */}

                      <img key={url} src={url} alt='' width='320' height='240' />
                    </>
                  ) : (
                    ''
                  )}
                </>
              </div>
              <div className='col-6 my-5 text-center'>
                <>
                  {urlIcon !== '' ? (
                    <>
                      <img key={urlIcon} src={urlIcon} alt='' width='320' height='240' />
                      {/* <video key={urlIcon} controls width='320' height='240'>
                      <source key={urlIcon} src={urlIcon} type='video/mp4' />
                    </video> */}
                    </>
                  ) : (
                    ''
                  )}
                </>
              </div>
            </div>
            <Link to={`/${route}`}>
              <button className='btn btn-secondary cancel'>Cancel </button>
            </Link>
            <button type='submit' className='btn btn-success'>
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
