import {useQueryClient, useMutation} from 'react-query'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {bulkApprovalVisitor, bulkRejectVisitor} from '../../core/_requests'
import {useResourceContext} from '../../context/ResourceContext'
import {useConfirmationDialogContext} from '../../../../context/ConfirmationDialogContext'
import {useSnackbar} from 'notistack'
import { useApprovalCountContext } from '../../context/ApprovalCountContext'

const ListGrouping = () => {
  const {selected, clearSelected} = useListView()
  const {enqueueSnackbar} = useSnackbar()
  const {showConfirmation} = useConfirmationDialogContext()
  const queryClient = useQueryClient()
  const {query,refetch} = useQueryResponse()
  const {collection} = useResourceContext()
  const {count, updateCount} = useApprovalCountContext();

  const approvalSelectedItems = useMutation(() => bulkApprovalVisitor(collection, selected), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${query}`])
      enqueueSnackbar('Data successfully approved', {
        variant: 'success',
      })
      refetch();
      updateCount(count - selected.length)
      clearSelected()
    },
  })
  const rejectSelectedItems = useMutation(() => bulkRejectVisitor(collection, selected), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${query}`])
      enqueueSnackbar('Data successfully approved', {
        variant: 'success',
      })
      refetch();
      updateCount(count - selected.length)
      clearSelected()

    },
  })
  const clickHandler = async () => {
    const title = 'Approvals'
    const message = 'Are you sure want to approve this datas?'
    const response = await showConfirmation(title, message)
    if (response) {
      await approvalSelectedItems.mutateAsync()
    }
  }
  const clickHandlerReject = async () => {
    const title = 'Rejects'
    const message = 'Are you sure want to reject this datas?'
    const response = await showConfirmation(title, message)
    if (response) {
      await rejectSelectedItems.mutateAsync()
    }
  }
  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>

      <button
        type='button'
        className='btn btn-primary'
        onClick={clickHandler}
      >
        Approval Selected
      </button>
      <button
        type='button'
        className='btn btn-danger mx-2'
        onClick={clickHandlerReject}
      >
        Reject Selected
      </button>
    </div>
  )
}

export {ListGrouping}
