export interface MasterRange {
  id: string
  range: number 
  factory_id: number
}
export const useCollection = () => {
  const collection = 'master_range'
  // const forms: MasterRange = {
  //   id: '',
  //   range: null,
  //   factory_id: null
  // }

  const columns = [
    {
      name: 'range',
      required: true,
      label: 'Range',
      align: 'left',
      field: 'range',
      sortable: true
    },
    {
      name: 'factory_id',
      required: true,
      label: 'factory_id',
      align: 'left',
      field: 'factory_id',
      sortable: true
    },
  ]
  const layout = [ 
    {
    type: 'number',
    col: 'col-6',
    name: 'range',
    placeholder: 'how many days',
    label: 'Range (Day)',
    required: false
  }
  ]
  const filter = [{
    type: 'reference',
    advanceFilter: true,
    col: 'col-6',
    name: 'factory_id',
    placeholder: 'Factory',
    label: 'Select Factory',
    reference: 'factory',
    option : {
      value: 'id',
      label : 'name'
    }
  }]

  return {
    collection,
    // forms,
    columns,
    layout,
    filter
  }
}