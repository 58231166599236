import {FC, useEffect, useState} from 'react'
import topRight from '../../layout/img/page-14/img-other/top-right.png'
import illustrasi5 from '../../layout/img/area-khusus-yes.png'
import sideRight from '../../layout/img/page-14/img-other/side-right.png'
import {Footer} from '../../components/footer/Footer'
import {useApi} from '../../hooks/useApi'
import {areas} from '../../models/areas'
import {sysparam} from '../../models/sysparam'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import Alert from 'react-bootstrap/Alert'
import {ErrorMessage} from '@hookform/error-message'
import danone from '../../layout/img/Danone-Logo.png'
import {Helmet} from 'react-helmet'

const API_URL = process.env.REACT_APP_API_BASE_URL

const AreaKhusus: FC = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm()
  const onError = (errors: any, e: any) => console.log(errors, e)

  const navigate = useNavigate()
  const {getAll} = useApi()
  const [selectedArea, setSelectedArea] = useState<string | undefined>('');
  const [selectedZone, setSelectedZone] = useState<string | undefined>('');
  const [area, setAreas] = useState<areas[]>([])
  const [zone, setZone] = useState<sysparam[]>([])
  const [alertFiil, setAlertFiil] = useState({
    show: false,
    text: '',
    color: '',
  })
  const [iconFactory, setIconFactory] = useState('')
  const [billingual, setBillingual] = useState({
    title : 'AREA KHUSUS',
    desc : `Area khusus yang dimaksud adalah area produksi meliputi High Care Area/Medium Care
    Area/Low Care Area.`,
    selectZone : 'Pilih Zona',
    question : 'Apakah anda akan mengunjungi area khsusus ?'
  })
  const fetchData = async () => {
    let factory: any = localStorage.getItem('factory')
    if (factory) {
      factory = JSON.parse(factory)
    }
    const payload = {
      factory_id: factory['factory_id'],
      // zone: e,
    }
    const response = await getAll<areas>('areas', payload)
    const {data} = response.data
    setAreas(data)
    return data
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getZone = async () => {
    const params = {
      group: 'zone',
    }
    const response = await getAll<sysparam>('sysparam', params)
    const {data} = response.data
    setZone(data)
  }
  const onSubmit = async (data: any, e: any) => {
    if (data) {
      localStorage.setItem('area-zona', JSON.stringify(data))
      navigate('/form-area')
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFormArea = async () => {
    const data = localStorage.getItem('area-zona')
    if (data) {
      const parse = JSON.parse(data)
      const zone = await fetchData()
      if (zone) {
        setSelectedArea(parse.area)
        setSelectedZone(parse.zona)
        setValue('zona', parse.zona)
        setValue('area', parse.area)
      }
    }
  }
  const handleChangeArea = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedArea(event.target.value);
  };
  const handleChangeZone = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedZone(event.target.value);
  };

  const [labelFactory, setLabelFactory] = useState('')
  useEffect(() => {
    const getLocalLabel = localStorage.getItem('factory')
    if (getLocalLabel) {
      const parse = JSON.parse(getLocalLabel)
      const icon = `${API_URL}/maps/${parse.icon}`
      setIconFactory(icon)
      setLabelFactory(parse.factoryName)
    }
    const getLang = localStorage.getItem('language')
    if(getLang === 'en'){
      setBillingual ({
        title : 'DESIGNATED AREA',
        desc :`The Designated Area is production area which divided into 3 areas, such as High Care Area /
        Medium Care Area / Low Care Area.`,
        selectZone : 'Choose The Zone',
        question : 'Do you want to visit specific area ?'
      })
    }
    getZone()
    fetchData()
    getFormArea()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section className='d-flex flex-column align-items-center '>
        <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
          <img src={danone} alt='' width={220} />
          <img src={iconFactory} alt='' width={100} />
        </div>
        <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
        <div className='row gx-10 align-items-center'>
          <div className='col-12 col-md-6 text-center-sm'>
            <h2>{labelFactory} Factory</h2>
            <h1 className='fw-bold text-black'>{billingual.title}</h1>
            <p className='mt-10 d-none d-md-block'>
              {billingual.desc}
            </p>
            <a href='area-1' className='btn btn-secondary btn-md-back mt-3'>
              Back
            </a>
          </div>
          <div className='col-12 col-md-6 text-center'>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <Alert
                dismissible
                onClose={() => setAlertFiil({...alertFiil, show: false})}
                show={alertFiil.show}
                variant={alertFiil.color}
              >
                {alertFiil.text}
              </Alert>
              <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
              <div className='d-flex flex-column flex-wrap align-content-center justify-content-start'>
                <div className='text-center mb-4'>
                  <p className='mt-10 d-none d-md-block'>
                    {billingual.question}
                  </p>
                  <img src={illustrasi5} className='mb-4 w-100' alt='' />
                  <div className='px-4 mb-2'>
                    <div className='form-group'>
                      <select
                        className='form-select'
                        {...register('area', {
                          required: true,
                        })}
                        aria-label='default select example'
                        value={selectedArea} onChange={handleChangeArea}
                      >
                        <>
                        <option value='' disabled>
                          Spray Drier, IWS&F, B&P
                        </option>
                          {area.map((entry: areas, indexOpt: number) => {
                            return <option key={indexOpt} value={entry.id}>{entry.name}</option>
                          })}
                        </>
                      </select>
                      <ErrorMessage errors={errors} name='area' />
                      <ErrorMessage
                        errors={errors}
                        name={'area'}
                        render={() => <p className='text-danger'>Area is required !</p>}
                      />
                    </div>
                  </div>
                  <div className='d-flex flex-column gap-4'>
                    <div className='px-4'>
                      <div className='form-group'>
                        <select
                          className='form-select'
                          aria-label='default select example'
                          {...register('zona', {
                            required: true,
                          })}
                          value={selectedZone}
                          onChange={handleChangeZone}
                        >
                          <>
                          <option value='' disabled>
                            {billingual.selectZone}
                          </option>
                            {zone.map((entry: sysparam, indexOpt: number) => {
                              return <option key={indexOpt} value={entry.value}>{entry.long_value}</option>
                            })}
                          </>
                        </select>
                        <ErrorMessage errors={errors} name='zona' />
                        <ErrorMessage
                          errors={errors}
                          name={'zona'}
                          render={() => <p className='text-danger'>Zona is required !</p>}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-10 text-center'>
                <button type='submit' className='btn btn-primary rounded-100 fw-semibold'>
                  Next
                </button>
              </div>
              <img src={sideRight} className='img-illustrasi-side-right' alt='' />
            </form>
          </div>
        </div>
        <Footer />
      </section>
    </>
  )
}

export {AreaKhusus}
