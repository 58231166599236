/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
// import {Logout, AuthPage, useAuth} from '../modules/auth'
import {Logout, AuthPage, useAuth} from '../pages/auth'
import {App} from '../App'
import {HomeWrapper} from '../pages/web/HomeWrapper'
import {ChooseFactoryWrapper} from '../pages/web/ChooseFactoryWrapper'
import {IdentityWrapper} from '../pages/web/IdentityWrapper'
import {PostTest} from '../pages/web/PostTest'
import {FormRegister} from '../pages/web/formRegister'
import {FormArea} from '../pages/web/formArea'
import {AreaKhusus} from '../pages/web/areaKhusus'
import {StatusCheck} from '../pages/web/statusCheck'
import {Welcome} from '../pages/web/welcome'
import {VisitorNote} from '../pages/web/visitor-note'
import {Maps} from '../pages/web/maps'
import {HygieneRules} from '../pages/web/hygiene-rules'
import {Zone} from '../pages/web/zone'
import {SafetyCardinalRules} from '../pages/web/safetyCardinalRules'
import {SafetyRules} from '../pages/web/safetyRules'
import {VisitorAnnounctment} from '../pages/web/vistorAnnouctment'
import {Emergency} from '../pages/web/emergency'
import {Vidio} from '../pages/web/vidio'
import {Area1} from '../pages/web/area-1'
import {Hasil} from '../pages/web/hasil'
import {Hasil2} from '../pages/web/hasil-2'
import {Quesioner} from '../pages/web/quesioner'
import {MenuTestPage} from '../pages/MenuTestPage'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {IzinGadget} from '../pages/web/izinGadget'
import {Reject} from '../pages/web/reject'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='home' element={<HomeWrapper />} />
          <Route path='choose-factory' element={<ChooseFactoryWrapper />} />
          <Route path='identity' element={<IdentityWrapper />} />
          <Route path='builder' element={<BuilderPageWrapper />} />
          <Route path='menu-test' element={<MenuTestPage />} />
          <Route path='post-test' element={<PostTest />} />
          <Route path='form-register' element={<FormRegister />} />
          <Route path='hygiene-rules' element={<HygieneRules />} />
          <Route path='zone' element={<Zone />} />
          <Route path='safety-cardinal-rules' element={<SafetyCardinalRules />} />
          <Route path='safety-rules' element={<SafetyRules />} />
          <Route path='vistor-annouctment' element={<VisitorAnnounctment />} />
          <Route path='emergency' element={<Emergency />} />
          <Route path='video' element={<Vidio />} />
          <Route path='area-1' element={<Area1 />} />
          <Route path='quesioner' element={<Quesioner />} />
          <Route path='welcome' element={<Welcome />} />
          <Route path='visitor-note' element={<VisitorNote />} />
          <Route path='maps' element={<Maps />} />
          <Route path='hasil' element={<Hasil />} />
          <Route path='hasil-2' element={<Hasil2 />} />
          <Route path='form-area' element={<FormArea />} />
          <Route path='area-khusus' element={<AreaKhusus />} />
          <Route path='status-check' element={<StatusCheck />} />
          <Route path='izin-gadget' element={<IzinGadget />} />
          <Route path='reject' element={<Reject />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/home' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
