/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {ID,KTSVG} from '../../../../_metronic/helpers'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useResourceContext} from '../../context/ResourceContext'
import {approvalVisitor, rejectVisitor} from '../../core/_requests'
import {useConfirmationDialogContext} from '../../../../context/ConfirmationDialogContext'
import {useSnackbar} from 'notistack'
import { useApprovalCountContext } from '../../context/ApprovalCountContext'

type Props = {
  id: ID
}

const ActionCellApprove: FC<Props> = ({id}) => {
  const {query, refetch} = useQueryResponse()
  const {enqueueSnackbar} = useSnackbar()
  const {count, updateCount} = useApprovalCountContext();
  const queryClient = useQueryClient()
  const {showConfirmation} = useConfirmationDialogContext()
  const {collection} = useResourceContext()
 
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const params = {
    approvalFor: 'user_danone',
  }
  const deleteItem = useMutation(() =>  approvalVisitor(collection, id, params), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${collection}-${query}`])
      enqueueSnackbar('Data successfully approved', {
        variant: 'success',
      })
      refetch();
      updateCount(count - 1)
    },
  })
  const rejectItem = useMutation(() =>  rejectVisitor(collection, id, params), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${collection}-${query}`])
      enqueueSnackbar('Data successfully rejected', {
        variant: 'success',
      })
      refetch();
      updateCount(count - 1)
    },
  })

  const clickHandler = async () => {
    const title = 'Approval'
    const message = 'Are you sure want to approve this data?'
    const response = await showConfirmation(title, message)
    if (response) {
      await deleteItem.mutateAsync()
    }
  }
  const clickHandlerReject = async () => {
    const title = 'Reject'
    const message = 'Are you sure want to reject this data?'
    const response = await showConfirmation(title, message)
    if (response) {
      await rejectItem.mutateAsync()
    }
  }

  return (
    <>
          <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
                <div className='menu-item'>
                <a
          className='btn btn-primary'
          onClick={clickHandler}
        >
          Approve
        </a>
        <a
          className='btn btn-danger'
          onClick={clickHandlerReject}
        >
          Reject
        </a>
        </div>
      </div>
    </>
  )
}

export { ActionCellApprove }
