import {FC, useEffect, useState} from 'react'
import topRight from '../../layout/img/page-7/img-other/top-right.png'
import buttonRight from '../../layout/img/page-7/img-other/botton-right.png'
import {Footer} from '../../components/footer/Footer'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useApi} from '../../hooks/useApi'
import Alert from 'react-bootstrap/Alert'
// import {Visitor} from '../../models/visitor'
import {Helmet} from 'react-helmet'
import { PublicVisitor } from '../../models/publicVisitor'

const StatusCheck: FC = () => {
  const [alertFiil, setAlertFiil] = useState({
    show: false,
    text: '',
    color: '',
  })
  const {getAll} = useApi()
  const [submit, setSubmit] = useState(false)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getParams = () => {
    const type: any = searchParams.get('type')
    const noIdentitas: any = searchParams.get('noIdentitas')
    const localPayload = localStorage.getItem('payload')
    if (localPayload) {
      const parse = JSON.parse(localPayload)
      setPayload(parse)
    }
    if (type && noIdentitas) {
      setPayload({
        type_identitas: type,
        no_identitas: noIdentitas,
        '!limit': 5,
        '!sort[id]': -1,
      })
    }
  }

  const [visitor, setVisitor] = useState<PublicVisitor[]>([])
  const [payload, setPayload] = useState({
    type_identitas: '',
    no_identitas: undefined,
    '!limit': 5,
    '!sort[id]': -1,
  })
  const [billingual, setBillingual] = useState({
    title : 'CHECK KODE BOKING',
    descBelow : `Mohon untuk mengisi semua kolom isian dibawah.`,
    kodeBoking : 'Kode boking'
  })
  const fetchData = async (params: any) => {
    const response = await getAll<PublicVisitor>('public-visitor', params)

    const {data} = response.data
    setVisitor(data)
  }
  const getFromStorage = () => {
    const data = localStorage.getItem('visitor')
    if (data) {
      const parse = JSON.parse(data)
      setVisitor(() => parse)
      setSubmit(true)
    }
  }
  useEffect(() => {
    const getLang = localStorage.getItem('language')
    if(getLang === 'en'){
      setBillingual ({
        title : 'BOOKING CODE CHECK',
        descBelow : `Please fill in the blank fields below :`,
        kodeBoking : 'Booking Code'
      })
    }
    getParams()
    getFromStorage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getValue = (e: any) => {
    setPayload({
      ...payload,

      [e.target.name]: e.target.value.trim(),
    })
  }
  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (payload.no_identitas !== null && payload.type_identitas !== '') {
      localStorage.setItem('payload', JSON.stringify(payload))
      setSubmit(true)
      fetchData(payload)
    } else {
      console.log('tidak bisa')
    }
  }
  const redirectKode = (kode_boking: any, status_boking: any) => {
    localStorage.setItem('kode', kode_boking)
    localStorage.setItem('visitor', JSON.stringify(visitor))
    if (status_boking === 'approve') {
      navigate('/hasil-2')
    } else if (status_boking === 'reject') {
      navigate('/reject')
    } else {
      navigate('/hasil')
    }
  }
  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section
        id='vms-page-7A'
        className='d-flex flex-wrap align-items-center justify-content-center flex-column overflow-hidden'
      >
        <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
        <form onSubmit={handleSubmit} className='text-center'>
          <div className='d-flex flex-column flex-wrap align-content-center gap-1 justify-content-start mb-4'>
            <Alert
              dismissible
              onClose={() => setAlertFiil({...alertFiil, show: false})}
              show={alertFiil.show}
              variant={alertFiil.color}
            >
              {alertFiil.text}
            </Alert>
            <div className='text-center mb-4'>
              <h5 className='fw-bold text-black'>{billingual.title}</h5>
              <p className='text-black'>{billingual.descBelow}</p>
            </div>
            <div className='col-12 col-md-12 m-b-1'>
              <div className='input-group border-1 rounded-1 mb-3'>
                <div className='input-group-prepend'>
                  <span className='input-group-text'>
                    <select
                      onChange={getValue}
                      name='type_identitas'
                      id=''
                      className='form-control'
                    >
                      <option selected disabled>
                        Type
                      </option>
                      <option value='ktp' selected={payload.type_identitas === 'ktp'}>
                        KTP
                      </option>
                      <option value='sim' selected={payload.type_identitas === 'sim'}>
                        SIM
                      </option>
                      <option value='pasport' selected={payload.type_identitas === 'pasport'}>
                        Pasport
                      </option>
                      <option value='kitas' selected={payload.type_identitas === 'kitas'}>
                        KITAS
                      </option>
                    </select>
                    <svg
                      width='36'
                      height='36'
                      viewBox='0 0 36 36'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z'
                        fill='#9FA2B4'
                      />
                      <path
                        d='M12 15.01C11.81 15.01 11.62 14.94 11.47 14.79L7.93999 11.26C7.80051 11.1189 7.72229 10.9284 7.72229 10.73C7.72229 10.5316 7.80051 10.3412 7.93999 10.2C8.22999 9.91001 8.70999 9.91001 8.99999 10.2L12 13.2L15 10.2C15.29 9.91001 15.77 9.91001 16.06 10.2C16.35 10.49 16.35 10.97 16.06 11.26L12.53 14.79C12.38 14.94 12.19 15.01 12 15.01Z'
                        fill='#9FA2B4'
                      />
                    </svg>
                  </span>
                </div>

                <input
                  onChange={getValue}
                  type='text'
                  className='form-control'
                  placeholder='ID Number'
                  name='no_identitas'
                  value={payload.no_identitas}
                  aria-label='Username'
                  aria-describedby='basic-addon1'
                />
              </div>
            </div>
            <div className='col-12 text-start'>
              <ol className='list-group list-group-numbered'>
                <>
                  {visitor.length !== 0 && submit === true ? (
                    <>
                      <span>Visitor List </span>
                      {visitor.map((entry: PublicVisitor) => {
                        return (
                          <li
                            onClick={() => redirectKode(entry.kode_boking, entry.status_boking)}
                            key={entry.id}
                            className='list-group-item d-flex justify-content-between align-items-start'
                            style={{cursor: 'pointer'}}
                          >
                            <div className='ms-2 me-auto'>
                              <span className='text-gray'>{billingual.kodeBoking}</span>
                              <div className='fw-bold'>{entry.kode_boking}</div>
                            </div>
                            <span
                              className={`badge rounded-pill ${
                                entry.status_boking === 'waiting'
                                  ? 'bg-warning'
                                  : entry.status_boking === 'approve'
                                  ? 'bg-primary'
                                  : 'bg-danger'
                              }`}
                            >
                              {entry.status_boking}
                            </span>
                          </li>
                        )
                      })}
                    </>
                  ) : visitor.length === 0 && submit === true ? (
                    <>
                      <h5 className='text-danger'>Not Found</h5>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              </ol>
            </div>
          </div>
          <div className='mt-2 mb-5'>
            <button type='submit' className='btn btn-primary rounded-100 fw-semibold'>
              Check
            </button>
          </div>
        </form>
        <img src={buttonRight} className='img-illustrasi-side-right' alt='' />
        <div className='button-back'>
          <a href='home'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.87866 12L13.9393 3.93934L16.0606 6.06066L10.1213 12L16.0606 17.9393L13.9393 20.0607L5.87866 12Z'
                fill='#585858'
              />
            </svg>
          </a>
        </div>
        <Footer />
      </section>
    </>
  )
}

export {StatusCheck}
