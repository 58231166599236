import {FC, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useLocation, Link, useNavigate} from 'react-router-dom'
import {ErrorMessage} from '@hookform/error-message'
import {useApi} from '../../../../hooks/useApi'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Factory} from '../../../../models/factory'
import {MasterMaps} from '../../../../models/master_maps'
import ClipLoader from 'react-spinners/ClipLoader'
const API_URL = process.env.REACT_APP_API_BASE_URL

export const EditPage: FC = () => {
  const navigate = useNavigate()
  const [collectionName, setCollectionName] = useState<string>('')
  const [url, setUrl] = useState<any | null>('')
  const [urlIcon, setUrlIcon] = useState<any | null>('')
  const [imgValue, setImgValue] = useState('')
  const [iconValue, setIconValue] = useState('')
  const [imageMaps, setImageMaps] = useState('')
  const [iconMaps, setIconMaps] = useState('')
  const routeParams = useLocation()
  const [oldImg, setOldImg] = useState('')
  const [oldIcon, setOldIcon] = useState('')
  const [exist, setExist] = useState('Required')
  const routeUrl = routeParams.pathname.replace('/', '')
  const splitCollection = routeUrl.split('/')
  const route = splitCollection[0]
  const id = splitCollection[1]
  const {getOneById, update, getAll, create} = useApi()
  const MySwal = withReactContent(Swal)
  const [dataMaps, setDataMaps] = useState<MasterMaps[]>([])

  const [factory, setFactory] = useState<Factory[]>([])
  const [factorySelector, setFactorySelector] = useState<number>()
  const [changed, setChanged] = useState(false)
  const [changedIcon, setChangedIcon] = useState(false)
  const [propImage, setPropImage] = useState({
    size: 0,
    type: '',
  })
  const [propIcon, setPropIcon] = useState({
    size: 0,
    type: '',
  })
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm()
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const getFactory = async () => {
    const response = await getAll<Factory>('factory')
    const {data} = response.data
    setFactory(data)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getOneById<MasterMaps>(`${route}`, `${id}`).then((response) => {
      const {data} = response
      const nameImg = data.image
      setOldImg(nameImg)
      setOldIcon(data.icon)
      const url = API_URL + '/maps/' + data.image
      const urlIcon = API_URL + '/maps/' + data.icon
      setFactorySelector(data['factory_id'])
      setValue('factory_id', data['factory_id'])
      setValue('land_area', data['land_area'])
      setValue('building_area', data['building_area'])
      setValue('icon', data['icon'])
      setUrl(url)
      setUrlIcon(urlIcon)
      setImgValue(data['image'])
      setIconValue(data['icon'])
    })
    const renameCollection = () => {
      const words = route.split('_')
      const titles: string[] = []
      for (const key in words) {
        const word = words[key]
        titles.push(word.charAt(0).toUpperCase() + word.slice(1))
      }
      return titles.join(' ')
    }
    const getCurrentMaps = async () => {
      const response = await getOneById<MasterMaps>(`${route}`, `${id}`)
      const {data} = response
      return data
    }
    const getDataMaps = async () => {
      const response = await getAll<MasterMaps>('master_maps')
      const {data} = response.data
      const currentMaps = await getCurrentMaps()
      const dataFilter = data.filter((entry: MasterMaps) => {
        return entry.factory_id !== currentMaps?.factory_id
      })

      setDataMaps(dataFilter)
      return dataFilter
    }

    setCollectionName(renameCollection())
    getFactory()
    getCurrentMaps()
    getDataMaps()

    if (changed) {
      register('image', {
        required: true,
        validate: {
          lessThan10MB: (files) => propImage.size < 5000000 || 'Max 5 mb',
          // acceptedFormats: (files) =>
          //   ['image/png', 'image/jpeg'].includes(propImage.type) || 'Only png and jpeg',
        },
      })
    }
    if (changedIcon) {
      register('icon', {
        required: true,
        validate: {
          lessThan10MB: (files) => propIcon.size < 5000000 || 'Max 5 mb',
          // acceptedFormats: (files) =>
          //   ['image/png', 'image/jpeg'].includes(propIcon.type) || 'Only png and jpeg',
        },
      })
    }
    // getDataMaps()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue])
  const upload = async (payload: any) => {
    const response = await create(`video/upload?bucket=maps`, payload)

    return response
  }
  const onSubmit = async (data: any, e: any) => {
    setLoading(true)
    let dataPayload = data
    const formDataImage = new FormData()
    const formDataIcon = new FormData()
    formDataImage.append('file', imageMaps)
    formDataIcon.append('file', iconMaps)

    const responseImage = await upload(formDataImage)
    const responseIcon = await upload(formDataIcon)
    if (responseImage.data) {
      dataPayload.image = responseImage.data['name']
      dataPayload.oldImg = oldImg
    }
    if (responseIcon.data) {
      dataPayload.icon = responseIcon.data['name']
      dataPayload.oldIcon = oldIcon
    }

    await update(`${route}`, id, dataPayload)
      .then((response) => {
        MySwal.fire({
          title: <strong className='text-black'>Success</strong>,
          html: <i className='text-black'>Data Update!</i>,
          icon: 'success',
        })
        navigate(`/${route}`)
      })
      .catch((error) => {
        if (error.response) {
          const {data} = error.response
          MySwal.fire({
            title: <strong className='text-black'>Failed</strong>,
            html: <i className='text-black'>{data.errors[0].message}!</i>,
            icon: 'error',
          })
        }
      })
      .finally(() => setLoading(false))
  }
  const onError = (errors: any, e: any) => console.log(errors, e)
  const saveImage = (e: any) => {
    const data = e.target.files[0]
    if (data) {
      const propImg = {
        size: data.size,
        type: data.type,
      }
      setPropImage(propImg)
    }
    // const oFReader = new FileReader()
    // oFReader.readAsDataURL(data)
    // oFReader.onload = () => {
    //   const result = oFReader.result
    //   setUrl(result)
    // }
    setUrl(URL.createObjectURL(data))

    setValue('image', data)
    setChanged(true)
    setImageMaps(data)
  }
  const saveIcon = (e: any) => {
    const data = e.target.files[0]
    if (data) {
      const propIcon = {
        size: data.size,
        type: data.type,
      }
      setPropIcon(propIcon)
    }
    // const oFReader = new FileReader()
    // oFReader.readAsDataURL(data)
    // oFReader.onload = () => {
    //   const result = oFReader.result
    //   setUrlIcon(result)
    // }
    setUrlIcon(URL.createObjectURL(data))

    setValue('icon', data)
    setChangedIcon(true)
    setIconMaps(data)
  }

  return (
    <div>
      {loading === true ? (
        <div className='loader'>
          <ClipLoader
            color={'#00008B'}
            loading={true}
            size={50}
            aria-label='Loading Spinner'
            data-testid='loader'
            // style={{}}
          />
        </div>
      ) : (
        ''
      )}
      <div className='card card-custom gutter-b'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>
              Edit {collectionName}
              <small></small>
            </h3>
          </div>
        </div>
        <div className='card-body'>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className='row'>
              <div className={`mb-4 col-4`}>
                <div className='form-group'>
                  <label className='text-black'>Land Area</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    {...register('land_area', {
                      required: true,
                    })}
                  />
                  <ErrorMessage errors={errors} name='land_area' />
                  <ErrorMessage
                    errors={errors}
                    name='land_area'
                    render={() => <p className='text-danger'>land_area is required !</p>}
                  />
                </div>
              </div>
              <div className={`mb-4 col-4`}>
                <div className='form-group'>
                  <label className='text-black'>Building Area</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    {...register('building_area', {
                      required: true,
                    })}
                  />
                  <ErrorMessage errors={errors} name='building_area' />
                  <ErrorMessage
                    errors={errors}
                    name='building_area'
                    render={() => <p className='text-danger'>building_area is required !</p>}
                  />
                </div>
              </div>

              <div className={`mb-4 col-4`}>
                <div className='form-group'>
                  <label className='text-black'>Factory Id</label>
                  <select
                    className='form-control form-control-solid'
                    {...register('factory_id', {
                      required: true,
                      validate: async (value, formValues) => {
                        const existingFactory: any = dataMaps.filter((entry: MasterMaps) => {
                          return entry.factory_id === parseInt(value)
                        })
                        if (existingFactory.length !== 0) {
                          setExist('Existing')
                        }
                        return existingFactory.length === 0
                      },
                    })}
                  >
                    <>
                      {factory.map((entry: Factory) => {
                        return (
                          <option
                            key={entry.id}
                            className='form-control form-control-solid text-black'
                            id=''
                            value={entry.id}
                            selected={factorySelector === parseInt(entry.id)}
                          >
                            {entry.name}
                          </option>
                        )
                      })}
                    </>
                  </select>
                  <ErrorMessage errors={errors} name='factory_id' />
                  <ErrorMessage
                    errors={errors}
                    name='factory_id'
                    render={() => <p className='text-danger'>Factory is {exist}</p>}
                  />
                </div>
              </div>

              <div className={`mb-4 col-6`}>
                <div className='form-group'>
                  <label className='text-black'>Image</label>
                  <label
                    htmlFor='imageMaps'
                    style={{cursor: 'pointer'}}
                    className='form-control form-control-solid'
                  >
                    {imgValue}
                  </label>
                  <ErrorMessage errors={errors} name='image' />
                  <ErrorMessage
                    errors={errors}
                    name='image'
                    render={() => <p className='text-danger'>image is required !</p>}
                  />
                </div>
              </div>
              <div className={`mb-4 col-6`}>
                <div className='form-group'>
                  <label className='text-black'>Icon</label>
                  <label
                    htmlFor='iconMaps'
                    style={{cursor: 'pointer'}}
                    className='form-control form-control-solid'
                  >
                    {iconValue}
                  </label>

                  <ErrorMessage errors={errors} name='icon' />
                  <ErrorMessage
                    errors={errors}
                    name='icon'
                    render={() => <p className='text-danger'>icon is required !</p>}
                  />
                </div>
              </div>
              <div className='col-6 my-5 text-center'>
                <>
                  {url !== '' ? (
                    <>
                      <label htmlFor='imageMaps' style={{cursor: 'pointer'}}>
                        <img src={url} width='320' height='240' alt='' />
                      </label>
                      <input
                        type='file'
                        id='imageMaps'
                        accept='image/*'
                        onChange={saveImage}
                        hidden
                      />
                    </>
                  ) : (
                    ''
                  )}
                </>
              </div>
              <div className='col-6 my-5 text-center'>
                <>
                  {urlIcon !== '' ? (
                    <>
                      {/* <img src={urlIcon} width='320' height='240' alt='' /> */}
                      <label htmlFor='iconMaps' style={{cursor: 'pointer'}}>
                        <img src={urlIcon} width='320' height='240' alt='' />
                      </label>
                      <input
                        type='file'
                        id='iconMaps'
                        onChange={saveIcon}
                        accept='image/*'
                        hidden
                      />
                    </>
                  ) : (
                    ''
                  )}
                </>
              </div>
            </div>
            <Link to={`/${route}`}>
              <button className='btn btn-secondary cancel'>Cancel </button>
            </Link>
            <button type='submit' className='btn btn-success'>
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
