import {FC, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {Footer} from '../../components/footer'
import topRight from '../../layout/img/page-7/img-other/top-right.png'
import Alert from 'react-bootstrap/Alert'
import {useApi} from '../../hooks/useApi'
import {useNavigate} from 'react-router-dom'
import Select from 'react-select'
import danone from '../../layout/img/Danone-Logo.png'
import {Helmet} from 'react-helmet'
import { PublicUser } from '../../models/public_user'

const API_URL = process.env.REACT_APP_API_BASE_URL

const givenResult = (questions: any) => {
  let complete = true
  for (const key in questions) {
    if (!questions[key]) {
      complete = false
    }
  }
  return {
    complete,
  }
}
const FormArea: FC = () => {
  const {getAll} = useApi()
  const [ownerArea, setOwnerArea] = useState<PublicUser[]>([])
  const [ownerSelected, setOwnerSelected] = useState<PublicUser[]>([])
  const [zona, setZona] = useState({
    area: '',
  })
  const [labelFactory, setLabelFactory] = useState('')
  const [iconFactory, setIconFactory] = useState('')
  const [billingual, setBillingual] = useState({
    title : 'FORM AREA KHUSUS',
    descBehind : `Mohon untuk mengisi semua kolom isian disamping.`,
    descBelow : `Mohon untuk mengisi semua kolom isian dibawah.`,
    alert : 'Semua kolom wajib diisi !!'
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = async () => {
    let factory: any = localStorage.getItem('factory')
    let areaZone: any = localStorage.getItem('area-zona')
    if (factory) {
      factory = JSON.parse(factory)
    }
    if (areaZone) {
      areaZone = JSON.parse(areaZone)
    }
    const payload = {
      factory_id: factory['factory_id'],
      // zone: areaZone['zona'],
    }
    const response = await getAll<PublicUser>('public-user', payload)
    const {data} = response.data
    const localUserArea = localStorage.getItem('form-area')
    const filter = data.filter((entry: PublicUser) => {
      return entry.area_ids !== null && entry.area_ids.includes(areaZone['area'])
    })
    const option = filter.map((v) => ({...v, value: v.id, label: v.name}))
    if(localUserArea){
      const dataPrse = JSON.parse(localUserArea)
      const ownerSelect = option.filter((x) => x.id === dataPrse["owner-area"])
      setFormArea({
        ...formArea,
        'owner-area': `${ownerSelect[0]?.id}`,
      })
      setOwnerSelected(ownerSelect)
    }
    setOwnerArea(option)
  }

  useEffect(() => {
    const getLocalLabel = localStorage.getItem('factory')
    if (getLocalLabel) {
      const parse = JSON.parse(getLocalLabel)
      const icon = `${API_URL}/maps/${parse.icon}`
      setIconFactory(icon)
      setLabelFactory(parse.factoryName)
    }
    const getLang = localStorage.getItem('language')
    if(getLang === 'en'){
      setBillingual ({
        title : 'DESIGNATED AREA PERMISSION FORM',
        descBehind : `Please complete the fields below.`,
        descBelow : `Please fill in all the fields below :`,
        alert : 'The field cannot be empty !!'
      })
    }
    fetchData()
    areaZone()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const areaZone = () => {
    const data = localStorage.getItem('area-zona')
    if (data) {
      const parse = JSON.parse(data)
      setZona(parse)
    }
  }
  const formRegistrasi = localStorage.getItem('form-register')
  const area = localStorage.getItem('area-zona')
  let dataPrse: any
  let areaParse: any
  if (area) {
    areaParse = JSON.parse(area)
  }
  if (formRegistrasi) {
    dataPrse = JSON.parse(formRegistrasi)
  }
  let initialFormData = Object.freeze({
    end: '',
    instansi: dataPrse.perusahaan,
    name: dataPrse.nama,
    'owner-area': '',
    start: '',
    'tgl-kedatangan': dataPrse.tgl_kedatangan,
    tujuan: dataPrse.tujuan_bertemu,
  })
  const data = localStorage.getItem('form-area')
  if (data) {
    const dataPrse = JSON.parse(data)
    initialFormData = dataPrse
  }

  const navigate = useNavigate()
  const [formArea, setFormArea] = useState(initialFormData)
  const [alertFiil, setAlertFiil] = useState({
    show: false,
    text: '',
    color: '',
  })
  const {handleSubmit} = useForm({})
  const getValue = (e: any) => {
    setFormArea({
      ...formArea,

      [e.target.name]: e.target.value.trim(),
    })
  }
  const onSubmit = async (data: any, e: any) => {
    const {complete} = givenResult(formArea)
    if (!complete) {
      setAlertFiil({
        show: true,
        text: billingual.alert,
        color: 'danger',
      })
      return
    }
    const emailOwner = ownerArea.find(obj =>obj.id.toString() === formArea['owner-area']);
    const payload = {...formArea, area: parseInt(zona['area']), ownerEmail: emailOwner?.email}
    localStorage.setItem('form-area', JSON.stringify(payload))
    navigate('/quesioner')
  }
  // const createAreaKhusus = async (payload: {}) => {
  //   await create('form-area', payload)
  // }
  const ownerAreaUser = (e: any) => {
    const ownerSelect = ownerArea.filter((x) => x.id === e.value)
    setOwnerSelected(ownerSelect)
    setFormArea({
      ...formArea,
      'owner-area': e.value,
    })
  }
  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section className='d-flex flex-column align-items-center '>
        <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
          <img src={danone} alt='' width={220} />
          <img src={iconFactory} alt='' width={100} />
        </div>
        <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
        <div className='row gx-10 align-items-center'>
          <div className='col-12 col-md-6 text-center-sm'>
            <h1 className='fw-bold text-black'>{billingual.title}</h1>
            <h2>{labelFactory} Factory</h2>
            <p className='mt-10 d-none d-md-block'>
              {billingual.descBehind}
            </p>
            <a href='izin-gadget' className='btn btn-secondary btn-md-back mt-3'>
              Back
            </a>
          </div>
          <div className='col-12 col-md-6 text-center'>
            <p className='text-black fw-bold'>{billingual.descBelow}</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Alert
                dismissible
                onClose={() => setAlertFiil({...alertFiil, show: false})}
                show={alertFiil.show}
                variant={alertFiil.color}
              >
                {alertFiil.text}
              </Alert>
              <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
              <div className='d-flex flex-column flex-wrap align-content-center gap-2 justify-content-start px-4 mb-4'>
                <div className='col-12 col-md-12 m-b-1'>
                  <div className='input-group border-1 rounded-1 mb-3'>
                    <div className='input-group-prepend'>
                      <span className='input-group-text'>
                        <span className='svg-icon svg-icon-1x'>
                          <svg
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M11.5 15.0001H2.94445C1.87056 15.0001 1 14.1295 1 13.0557C1 9.88178 5.66667 9.94455 7.22223 9.94455C8.77779 9.94455 13.4445 9.88178 13.4445 13.0557C13.4445 14.1295 12.5739 15.0001 11.5 15.0001Z'
                              stroke='#87898E'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M7.22226 7.22223C8.94045 7.22223 10.3334 5.82934 10.3334 4.11112C10.3334 2.39289 8.94045 1 7.22226 1C5.50404 1 4.11115 2.39289 4.11115 4.11112C4.11115 5.82934 5.50404 7.22223 7.22226 7.22223Z'
                              stroke='#87898E'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                      </span>
                    </div>
                    <input
                      name='name'
                      value={formArea.name}
                      onChange={getValue}
                      type='text'
                      className='form-control'
                      placeholder='Nama Lengkap (Autofill)'
                      aria-label='Username'
                      aria-describedby='basic-addon1'
                    />
                  </div>
                </div>
                <div className='col-12 col-md-12 m-b-1'>
                  <div className='input-group border-1 rounded-1 mb-3'>
                    <div className='input-group-prepend'>
                      <span className='input-group-text'>
                        <span className='svg-icon svg-icon-1x'>
                          <svg
                            width='18'
                            height='16'
                            viewBox='0 0 18 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M1 14.9926H17M14.6582 15V11.7137M1.8 14.9926V3.22526C1.8 1.74421 2.52727 1 3.97455 1H8.50545C9.95273 1 10.6727 1.74421 10.6727 3.22526V14.9926M4.49091 4.86105H8.09091M4.49091 7.62421H8.09091M6.27273 14.9926V12.2295M14.6727 6.80632C13.7855 6.80632 13.0727 7.52842 13.0727 8.42737V10.1C13.0727 10.9989 13.7855 11.7211 14.6727 11.7211C15.56 11.7211 16.2727 10.9989 16.2727 10.1V8.42737C16.2727 7.52842 15.56 6.80632 14.6727 6.80632Z'
                              stroke='#87898E'
                              strokeWidth='1.5'
                              strokeMiterlimit='10'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                      </span>
                    </div>
                    <input
                      name='instansi'
                      value={formArea.instansi}
                      onChange={getValue}
                      type='text'
                      className='form-control'
                      placeholder='Asal Instansi (Autofill)'
                      aria-label='Username'
                      aria-describedby='basic-addon1'
                    />
                  </div>
                </div>
                <div className='col-12 col-md-12 m-b-1'>
                  <div className='input-group border-1 rounded-1 mb-3'>
                    <div className='input-group-prepend'>
                      <span className='input-group-text'>
                        <span className='svg-icon svg-icon-1x'>
                          <svg
                            width='18'
                            height='18'
                            viewBox='0 0 18 18'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M1.49701 6.375H8.62201M4.49701 12.375H5.99701M7.87201 12.375H10.872'
                              stroke='#87898E'
                              strokeWidth='1.5'
                              strokeMiterlimit='10'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M16.497 9.0225V12.0825C16.497 14.715 15.8295 15.375 13.167 15.375H4.82701C2.16451 15.375 1.49701 14.715 1.49701 12.0825V5.9175C1.49701 3.285 2.16451 2.625 4.82701 2.625H10.872'
                              stroke='#87898E'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M14.307 3.0975L11.5245 5.88C11.4195 5.985 11.3145 6.195 11.292 6.345L11.142 7.41C11.0895 7.7925 11.3595 8.0625 11.742 8.01L12.807 7.86C12.957 7.8375 13.167 7.7325 13.272 7.6275L16.0545 4.845C16.5345 4.365 16.7595 3.81 16.0545 3.105C15.342 2.3925 14.787 2.6175 14.307 3.0975Z'
                              stroke='#87898E'
                              strokeWidth='1.5'
                              strokeMiterlimit='10'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M13.9095 3.495C14.1495 4.3425 14.8095 5.0025 15.6495 5.235'
                              stroke='#87898E'
                              strokeWidth='1.5'
                              strokeMiterlimit='10'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                      </span>
                    </div>
                    <input
                      name='tujuan'
                      value={formArea.tujuan}
                      onChange={getValue}
                      type='text'
                      className='form-control'
                      placeholder='Tujuan ke area khususa (Autofill)'
                      aria-label='Username'
                      aria-describedby='basic-addon1'
                    />
                  </div>
                </div>
                <div className='col-12 col-md-12 m-b-1'>
                  <div className='input-group border-1 rounded-1 mb-3'>
                    <div className='input-group-prepend'>
                      <span className='input-group-text'>
                        <span className='svg-icon svg-icon-1x'>
                          <svg
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M11.5 15.0001H2.94445C1.87056 15.0001 1 14.1295 1 13.0557C1 9.88178 5.66667 9.94455 7.22223 9.94455C8.77779 9.94455 13.4445 9.88178 13.4445 13.0557C13.4445 14.1295 12.5739 15.0001 11.5 15.0001Z'
                              stroke='#87898E'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M7.22226 7.22223C8.94045 7.22223 10.3334 5.82934 10.3334 4.11112C10.3334 2.39289 8.94045 1 7.22226 1C5.50404 1 4.11115 2.39289 4.11115 4.11112C4.11115 5.82934 5.50404 7.22223 7.22226 7.22223Z'
                              stroke='#87898E'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                      </span>
                    </div>
                    <Select
                      value={ownerSelected}
                      options={ownerArea}
                      placeholder='User Danoners'
                      onChange={ownerAreaUser}
                      className='form-control text-start border-none '
                    />
                  </div>
                </div>
                <div className='col-12 col-md-12 m-b-1'>
                  <div className='input-group border-1 rounded-1 mb-3'>
                    <div className='input-group-prepend'>
                      <span className='input-group-text'>
                        <span className='svg-icon svg-icon-1x'>
                          <svg
                            width='19'
                            height='19'
                            viewBox='0 0 19 19'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M6.33333 1.58334V3.95834M12.6667 1.58334V3.95834M2.77083 7.19625H16.2292M16.625 6.72917V13.4583C16.625 15.8333 15.4375 17.4167 12.6667 17.4167H6.33333C3.5625 17.4167 2.375 15.8333 2.375 13.4583V6.72917C2.375 4.35417 3.5625 2.77084 6.33333 2.77084H12.6667C15.4375 2.77084 16.625 4.35417 16.625 6.72917Z'
                              stroke='#87898E'
                              strokeWidth='1.5'
                              strokeMiterlimit='10'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M12.4253 10.8458H12.4324M12.4253 13.2208H12.4324M9.49612 10.8458H9.50404M9.49612 13.2208H9.50404M6.56616 10.8458H6.57408M6.56616 13.2208H6.57408'
                              stroke='#87898E'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </span>
                      </span>
                    </div>
                    <input
                      name='tgl-kedatangan'
                      value={formArea['tgl-kedatangan']}
                      onChange={getValue}
                      type='date'
                      className='form-control'
                      placeholder='Tanggal Kedatangan (Autofill)'
                      aria-label='Username'
                      aria-describedby='basic-addon1'
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6 col-md-6 m-b-1'>
                    <div className='input-group border-1 rounded-1 mb-3'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>
                          <span className='svg-icon svg-icon-1x'>
                            <svg
                              width='19'
                              height='19'
                              viewBox='0 0 19 19'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M9.50008 6.33333V10.2917M16.4272 10.4896C16.4272 14.3133 13.3238 17.4167 9.50008 17.4167C5.67633 17.4167 2.573 14.3133 2.573 10.4896C2.573 6.66583 5.67633 3.5625 9.50008 3.5625C13.3238 3.5625 16.4272 6.66583 16.4272 10.4896Z'
                                stroke='#87898E'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.125 1.58333H11.875'
                                stroke='#87898E'
                                strokeWidth='1.5'
                                strokeMiterlimit='10'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </span>
                        </span>
                      </div>
                      <input
                        name='start'
                        value={formArea.start}
                        onChange={getValue}
                        type='time'
                        className='form-control'
                        placeholder='HH:MM'
                        aria-label='Username'
                        aria-describedby='basic-addon1'
                      />
                    </div>
                  </div>
                  <div className='col-6 col-md-6 m-b-1'>
                    <div className='input-group border-1 rounded-1 mb-3'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>
                          <span className='svg-icon svg-icon-1x'>
                            <svg
                              width='19'
                              height='19'
                              viewBox='0 0 19 19'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M9.50008 6.33333V10.2917M16.4272 10.4896C16.4272 14.3133 13.3238 17.4167 9.50008 17.4167C5.67633 17.4167 2.573 14.3133 2.573 10.4896C2.573 6.66583 5.67633 3.5625 9.50008 3.5625C13.3238 3.5625 16.4272 6.66583 16.4272 10.4896Z'
                                stroke='#87898E'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.125 1.58333H11.875'
                                stroke='#87898E'
                                strokeWidth='1.5'
                                strokeMiterlimit='10'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </span>
                        </span>
                      </div>
                      <input
                        name='end'
                        value={formArea.end}
                        onChange={getValue}
                        type='time'
                        className='form-control'
                        placeholder='HH:MM'
                        aria-label='Username'
                        aria-describedby='basic-addon1'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-10 text-center'>
                <button type='submit' className='btn btn-primary rounded-100 fw-semibold'>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className='button-back d-block d-md-none'>
          <a href='izin-gadget'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.87866 12L13.9393 3.93934L16.0606 6.06066L10.1213 12L16.0606 17.9393L13.9393 20.0607L5.87866 12Z'
                fill='#585858'
              />
            </svg>
          </a>
        </div>
        <Footer />
      </section>
    </>
  )
}

export {FormArea}
