import {FC, useState, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useLocation, Link, useNavigate} from 'react-router-dom'
import {ErrorMessage} from '@hookform/error-message'
import {useApi} from '../../../../hooks/useApi'
import {useFileCheck} from '../../../../hooks/useModelCheck'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Factory} from '../../../../models/factory'
import {MasterQuestion} from '../../../../models/master_question'
import {Form} from 'react-bootstrap'
import ClipLoader from 'react-spinners/ClipLoader'

export const CreatePage: FC = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<MasterQuestion>()
  const [collectionName, setCollectionName] = useState<string>('')
  const [options, setOptions] = useState<string[]>([''])
  const [optionsEn, setOptionsEn] = useState<string[]>([''])
  const [factory, setFactory] = useState<Factory[]>([])
  const [eDate, setEDate] = useState(0)
  const [paramEDate, setparamEDate] = useState('')
  const routeParams = useLocation()
  const routeUrl = routeParams.pathname.replace('/', '')
  const splitCollection = routeUrl.split('/')
  const route = splitCollection[0]
  const {getModel} = useFileCheck()
  const {create, getAll} = useApi()
  const MySwal = withReactContent(Swal)
  const collectionExist = getModel(`${route}`)
  const {layout} = collectionExist
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [correctAnswerEn, setCorrectAnswerEn] = useState<string>('');
  const [correctAnswer, setCorrectAnswer] = useState<string>('');


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFactory = async () => {
    const response = await getAll<Factory>('factory')
    const {data} = response.data
    setFactory(data)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const efective_date = queryParameters.get('efective_date')
  
    if (efective_date) {
      const data = parseInt(efective_date)
      setEDate(data)
      setparamEDate(`?efective_date=${data}`)
    }
    const renameCollection = () => {
      const words = route.split('_')
      const titles: string[] = []
      for (const key in words) {
        const word = words[key]
        titles.push(word.charAt(0).toUpperCase() + word.slice(1))
      }
      return titles.join(' ')
    }
    setCollectionName(renameCollection())
    getFactory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const onSubmit = async (data: MasterQuestion, e: any) => {
    setLoading(true)
    const payload = {data}
    payload.data.efective_date_id = eDate
    await create(`${route}`, payload.data)
      .then((response) => {
        MySwal.fire({
          title: <strong className='text-black'>Success</strong>,
          html: <i className='text-black'>Data created!</i>,
          icon: 'success',
        })
        navigate(`/${route}?efective_date=${eDate}`)
      })
      .catch((error) => {
        if (error.response) {
          const {data} = error.response
          MySwal.fire({
            title: <strong className='text-black'>Failed</strong>,
            html: <i className='text-black'>{data.errors[0].message}!</i>,
            icon: 'error',
          })
        }
      })
      .finally(() => setLoading(false))
  }
  const onError = (errors: any, e: any) => console.log(errors, e)
  const handleChange = (i: number, e: any) => {
    let newFormValues = [...options]
    newFormValues[i] = e.target.value
    setOptions(newFormValues)
    setValue('answer', newFormValues)
    if (correctAnswer === options[i]) {
      setCorrectAnswer(e.target.value);
      setValue('correct_answer', e.target.value);
    }
  }
  const addFormFields = () => {
    setOptions((options) => [...options, ''])
  }
  const removeFormFields = (i: number) => {
    let newFormValues = [...options]
    newFormValues.splice(i, 1)
    setOptions(newFormValues)
  }
  const handleChangeEn = (i: number, e: any) => {
    let newFormValues = [...optionsEn]
    newFormValues[i] = e.target.value
    setOptionsEn(newFormValues)
    setValue('answer_en', newFormValues)
    if (correctAnswerEn === optionsEn[i]) {
      setCorrectAnswerEn(e.target.value);
      setValue('correct_answer_en', e.target.value);
    }
  }
  const addFormFieldsEn = () => {
    setOptionsEn((optionsEn) => [...optionsEn, ''])
  }
  const removeFormFieldsEn = (i: number) => {
    let newFormValues = [...optionsEn]
    newFormValues.splice(i, 1)
    setOptions(newFormValues)
  }
  const handleCorrectAnswerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCorrectAnswer(e.target.value);
    setValue('correct_answer', e.target.value);
  };
  const handleCorrectAnswerChangeEn = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCorrectAnswerEn(e.target.value);
    setValue('correct_answer_en', e.target.value);
  };
  return (
    <div>
      {loading === true ? (
        <div className='loader'>
          <ClipLoader
            color={'#00008B'}
            loading={true}
            size={50}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      ) : (
        ''
      )}
      <div className='card card-custom gutter-b'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>
              Create {collectionName}
              <small></small>
            </h3>
          </div>
        </div>
        <div className='card-body'>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className='row'>
            <div className='mb-4 col-4'>
                <Form.Group>
                  <Form.Label>Factory Id</Form.Label>
                  <select
                          className='form-control form-control-solid text-black'
                    {...register('factory_id', {
                      required: true,
                    })}
                  >
                    <option value=''>Select Factory</option>
                    {factory.map((entry: Factory) => {
                      return (
                        <option key={entry.id} id='' value={entry.id}>
                          {entry.name}
                        </option>
                      )
                    })}
                  </select>
                </Form.Group>
                <ErrorMessage errors={errors} name='factory_id' />
                <ErrorMessage
                  errors={errors}
                  name='factory_id'
                  render={() => <p className='text-danger'>Factory is required !</p>}
                />
            </div>
              {layout.map((fields: any, index: number) => {
                if (['text'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        type='text'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['textarea'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </label>
                      <textarea
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['select'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <div className='form-group'>
                        <label className='text-black'>{fields.label}</label>
                        <select
                          className='form-control form-control-solid text-black'
                          {...register(fields.name)}
                        >
                          {fields.options.map((option: any, indexOpt: number) => (
                            <option className='text-black' key={indexOpt} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <ErrorMessage errors={errors} name={fields.name} />
                        <ErrorMessage
                          errors={errors}
                          name={fields.name}
                          render={() => <p className='text-danger'>{fields.name} is required !</p>}
                        />
                      </div>
                    </div>
                  )
                }
                if (['date'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        type='date'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['email'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                          pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        })}
                        type='email'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                return layout
              })}
            </div>
            <h3 className='mt-5'>In Indonesian</h3>
            <hr />
            <div className='row'>
              <div className='col-12'>
                <label className='form-label text-black'>Question (Indonesian)</label>
                <textarea
                    {...register('question', {
                      required: true,
                    })}
                    className='text-black form-control form-control-solid'
                    placeholder='Write your question in Indonesian'
                    name='question'
                  />
                  <ErrorMessage errors={errors} name='question' />
                  <ErrorMessage
                    errors={errors}
                    name='question'
                    render={() => <p className='text-danger'>Question is required !</p>}
                  />
              </div>
              <div className='col-6'>
                <label className='form-label text-black'>Answer (Indonesian)</label>
                {options.map((element: string, indexOpt: number) => (
                  <div key={indexOpt} className='row'>
                    <div className='mb-2 col-10'>
                      <input
                        type='text'
                        className='form-control form-control-solid text-black'
                        placeholder={`answer${indexOpt + 1}`}
                        value={element}
                        onChange={(e) => handleChange(indexOpt, e)}
                      />
                      <ErrorMessage errors={errors} name='answer' />
                      <ErrorMessage
                        errors={errors}
                        name='answer'
                        render={() => (
                          <p className='text-danger'>option{indexOpt + 1} is required !</p>
                        )}
                      />
                    </div>
                    {indexOpt ? (
                      <div className='mb-2 col-1'>
                        <button
                          type='button'
                          className='btn btn-light-danger remove'
                          onClick={() => removeFormFields(indexOpt)}
                          style={{width: '10px'}}
                        >
                          <i className='fas fa-minus'></i>
                        </button>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
              <div className='mb-2 col-12'>
                <button
                  className='btn btn-light-primary add'
                  type='button'
                  onClick={() => addFormFields()}
                  style={{width: '10px'}}
                >
                  <i className='fas fa-plus'></i>
                </button>
              </div>
              <div className='mb-2 col-6'>
                <label className='form-label text-black'>Corect Answer</label>
                <select
                  {...register('correct_answer', {
                    required: true,
                  })}
                  value={correctAnswer}
                  className='form-control form-control-solid text-black'
                  id=''
                  onChange={handleCorrectAnswerChange}

                >
                  <option value={''} selected disabled>
                    Choice Corect Answer
                  </option>
                  <>
                  {options.map((element: string, index: number) => (
                    <option key={index} value={element}>
                      {element}
                    </option>
                  ))}
                  </>
                </select>
                <ErrorMessage errors={errors} name='correct_answer' />
                <ErrorMessage
                  errors={errors}
                  name={`correct_answer`}
                  render={() => <p className='text-danger'>Correct Answer is required !</p>}
                />
              </div>
            </div>
            <h3 className='mt-5'>In English</h3>
            <hr />
            <div className='row'>
              <div className='col-12'>
                <label className='form-label text-black'>Question (English)</label>
                <textarea
                        {...register('question_en', {
                          required: true,
                        })}
                        className='text-black form-control form-control-solid'
                        placeholder='Write your question in English'
                        name='question_en'
                      />
                      <ErrorMessage errors={errors} name='question_en' />
                      <ErrorMessage
                        errors={errors}
                        name='question_en'
                        render={() => <p className='text-danger'>English Question is required !</p>}
                      />
              </div>
              <div className='col-6'>
                <label className='form-label text-black'>Answer</label>
                {optionsEn.map((element: string, indexOpt: number) => (
                  <div key={indexOpt} className='row'>
                    <div className='mb-2 col-10'>
                      <input
                        type='text'
                        className='form-control form-control-solid text-black'
                        placeholder={`answer_en${indexOpt + 1}`}
                        value={element}
                        onChange={(e) => handleChangeEn(indexOpt, e)}
                      />
                      <ErrorMessage errors={errors} name='answer' />
                      <ErrorMessage
                        errors={errors}
                        name='answer_en'
                        render={() => (
                          <p className='text-danger'>option{indexOpt + 1} is required !</p>
                        )}
                      />
                    </div>
                    {indexOpt ? (
                      <div className='mb-2 col-1'>
                        <button
                          type='button'
                          className='btn btn-light-danger remove'
                          onClick={() => removeFormFieldsEn(indexOpt)}
                          style={{width: '10px'}}
                        >
                          <i className='fas fa-minus'></i>
                        </button>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
              <div className='mb-2 col-12'>
                <button
                  className='btn btn-light-primary add'
                  type='button'
                  onClick={() => addFormFieldsEn()}
                  style={{width: '10px'}}
                >
                  <i className='fas fa-plus'></i>
                </button>
              </div>
              <div className='mb-2 col-6'>
                <label className='form-label text-black'>Corect Answer</label>
                <select
                  {...register('correct_answer_en', {
                    required: true,
                  })}
                  value={correctAnswerEn}
                  className='form-control form-control-solid text-black'
                  id=''
                  onChange={handleCorrectAnswerChangeEn}

                >
                  <option value={''} selected disabled>
                    Choice Corect Answer
                  </option>
                  <>
                  {optionsEn.map((element: string, index: number) => (
                    <option key={index} value={element}>
                      {element}
                    </option>
                  ))}
                  </>
                </select>
                <ErrorMessage errors={errors} name='correct_answer' />
                <ErrorMessage
                  errors={errors}
                  name={`correct_answer_en`}
                  render={() => <p className='text-danger'>Correct Answer is required !</p>}
                />
              </div>
            </div>
            <div className='mt-10'>
              <Link to={`/${route + paramEDate}`}>
                <button className='btn btn-secondary cancel'>Cancel </button>
              </Link>
              <button type='submit' className='btn btn-success'>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
