/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import topRight from '../../layout/img/page-11/img-other/top-right.png'
import ilustrasi from '../../layout/img/newImages/sefety rules 1.png'
import illustrasi3 from '../../layout/img/newImages/sefety rules 3.png'
import illustrasi4 from '../../layout/img/newImages/sefety rules 4.png'
import illustrasi6 from '../../layout/img/newImages/sefety rules 7.png'
import illustrasi5 from '../../layout/img/newImages/sefety rules 6.png'
import sideRight from '../../layout/img/page-11/img-other/side-right.png'
import danone from '../../layout/img/Danone-Logo.png'
import {Footer} from '../../components/footer/Footer'
import {useNavigate} from 'react-router-dom'
import {Helmet} from 'react-helmet'
const API_URL = process.env.REACT_APP_API_BASE_URL

const SafetyRules: FC = () => {
  const navigate = useNavigate()
  const [labelFactory, setLabelFactory] = useState('')
  const [iconFactory, setIconFactory] = useState('')
  const [billingual, setBillingual] = useState({
    walk: `Visitor wajib berjalan di area pedestrian.`,
    apd: `Wajib menggunakan APD sesuai standart pada saat melakukan pekerjaan dan selalu
    menerapkan perilaku safety selama di dalam area pabrik.`,
    noPict: 'Dilarang mengambil gambar dan merekam video selama berada di dalam area pabrik.',
    noSmoke: 'Dilarang merokok diseluruh area pabrik.',
    careful: `Ketika berada disekitar area forklift, harap berhati-hati dan waspada,
    perhatikan pergerakan forklift dan selalu patuhi aturan standart prioritas
    forklift.`,
    geser: 'Tekan Untuk Geser',
  })
  useEffect(() => {
    const getLocalLabel = localStorage.getItem('factory')
    if (getLocalLabel) {
      const parse = JSON.parse(getLocalLabel)
      const icon = `${API_URL}/maps/${parse.icon}`
      setIconFactory(icon)
      setLabelFactory(parse.factoryName)
    }
    const getLang = localStorage.getItem('language')
    if (getLang === 'en') {
      setBillingual({
        walk: `Visitors are required to adhere walk in the pedestrian area`,
        apd: 'Visitors must adhere to safety procedures at all times while in the factory area and use PPE according to regulations.',
        noPict: 'While inside the factory, photography, and videography are prohibited.',
        noSmoke: 'Smoking is prohibited in the factory area.',
        careful: `Please be cautious and vigilant when around the forklift area, pay attention to forklift movements,
        and always abide by the forklift priority standard guidelines.`,
        geser: 'Press to slide',
      })
    }
  }, [])
  const rulesSafety = () => {
    const safety = {
      safety_rules: true,
    }
    localStorage.setItem('safety_rules', JSON.stringify(safety))
    navigate('/emergency')
  }
  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section
        className='d-flex flex-wrap align-items-center justify-content-center flex-column'
        style={{overflow: 'inherit'}}
      >
        <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
          <img src={danone} alt='' width={220} />
          <img src={iconFactory} alt='' width={100} />
        </div>
        <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
        <div className='row gx-10 align-items-center w-100'>
          <div className='col-12 col-md-6 text-center-sm'>
            <h2 className='text-subtitle'>{labelFactory} Factory</h2>
            <h1>SAFETY RULES</h1>
            <div className='d-md-flex d-none gap-3 mt-10'>
              <a href='safety-cardinal-rules' className='btn btn-secondary btn-md-back'>
                Back
              </a>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div
              id='carouselExampleIndicators'
              className='carousel slide carousel-hygiene'
              data-bs-ride='true'
              data-bs-wrap='false'
            >
              {/* <div className='carousel-indicators'>
                <button
                  type='button'
                  data-bs-target='#carouselExampleIndicators'
                  data-bs-slide-to='0'
                  className='active'
                  aria-current='true'
                  aria-label='Slide 1'
                ></button>
                <button
                  type='button'
                  data-bs-target='#carouselExampleIndicators'
                  data-bs-slide-to='1'
                  aria-label='Slide 2'
                ></button>
                <button
                  type='button'
                  data-bs-target='#carouselExampleIndicators'
                  data-bs-slide-to='2'
                  aria-label='Slide 3'
                ></button>
                <button
                  type='button'
                  data-bs-target='#carouselExampleIndicators'
                  data-bs-slide-to='3'
                  aria-label='Slide 4'
                ></button>
                <button
                  type='button'
                  data-bs-target='#carouselExampleIndicators'
                  data-bs-slide-to='4'
                  aria-label='Slide 5'
                ></button>
              </div> */}
              <div className='carousel-inner'>
                <div className='carousel-item text-center active'>
                  <img src={ilustrasi} alt='' width='100%' />
                  <p className='px-5 mb-5'>{billingual.walk}</p>
                </div>
                <div className='carousel-item text-center'>
                  <img src={illustrasi3} alt='' width='100%' />
                  <p className='px-5 mb-5'>{billingual.apd}</p>
                </div>
                <div className='carousel-item text-center'>
                  <img src={illustrasi4} alt='' width='100%' />
                  <p className='px-5 mb-5'>{billingual.noPict}</p>
                </div>
                <div className='carousel-item text-center'>
                  <img src={illustrasi5} alt='' width='100%' />
                  <p className='px-5 mb-5'>{billingual.noSmoke}</p>
                </div>
                <div className='carousel-item text-center'>
                  <img src={illustrasi6} alt='' width='100%' />
                  <p className='px-5 mb-5'>{billingual.careful}</p>
                  <div className='mt-10'>
                    <a onClick={rulesSafety} className='btn btn-primary rounded-100 fw-semibold'>
                      Next
                    </a>
                  </div>
                </div>
              </div>
              <button
                className='carousel-control-prev'
                type='button'
                data-bs-target='#'
                data-bs-slide='#'
              >
                <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                <span className='visually-hidden'>Previous</span>
              </button>
              <button
                className='carousel-control-next ms-1'
                type='button'
                data-bs-target='#carouselExampleIndicators'
                data-bs-slide='next'
              >
                <div className='d-flex bg-white nav-carousel p-4 border'>
                  <div className='text-nowrap d-md-none'>Geser</div>
                  <div className='text-nowrap d-none d-md-block'>{billingual.geser}</div>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z'
                      fill='#000000'
                      transform='translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) '
                      // fill='#585858'
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
        <img src={sideRight} className='img-illustrasi-side-right' alt='' />
        <div className='button-back'>
          <a href='safety-cardinal-rules'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.87866 12L13.9393 3.93934L16.0606 6.06066L10.1213 12L16.0606 17.9393L13.9393 20.0607L5.87866 12Z'
                fill='#585858'
              />
            </svg>
          </a>
        </div>
        <Footer />
      </section>
    </>
  )
}

export {SafetyRules}
