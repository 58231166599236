export interface ExampleModel {
  name: string
  nik: string
}
export const useCollection = () => {
  const collection = 'example'
  const forms: ExampleModel = {
   name: '',
   nik: ''
  }

  const columns = [
    {
      name: 'name',
      required: true,
      label: 'Name',
      align: 'left',
      field: 'name',
      sortable: true
    },
    // Always give this columns as default
    {
      name: 'nik',
      required: true,
      label: 'NIK',
      align: 'left',
      field: 'nik',
      sortable: true
    },
  ]
  const layout = [

      {
        type: 'text',
        col: 'col-6',
        name: 'name',
        placeholder: '',
        label: 'Name',
        required: false
      },
      {
        type: 'text',
        col: 'col-6',
        name: 'nik',
        placeholder: '',
        label: 'NIk',
        required: false
      },
     
    
      
  ]
  return {
    collection,
    forms,
    columns,
    layout,
  }
}