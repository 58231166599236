import {FC} from 'react'
import ilustrasi from '../../layout/img/page-12/img-other/illustrasi.png'
import {Footer} from '../../components/footer/Footer'
import {Helmet} from 'react-helmet'

const VisitorAnnounctment: FC = () => {
  return (
    <>
    <Helmet>
      <link rel="stylesheet" href="/visitor.css" />
    </Helmet>
      <section>
        <div className='d-flex flex-wrap align-items-center justify-content-center flex-column h-100 overflow-hidden'>
          <div className='d-flex flex-column flex-wrap align-content-center justify-content-start'>
            <div className='text-center mb-4'>
              <p className='text-black'>Pengunjung Yang Terhormat</p>
              <img src={ilustrasi} className='mb-2 img-mobile-responsive' alt='' />
            </div>
          </div>
          <p className='longText text-gray w-text mb-5'>
            Jika situasi darurat terjadi pada perusahaan selama kunjungan anda dan perlu dilakukan
            evakuasi, prosedur tanggap darurat akan diterapkan. tim tanggap darurat akan
            mengevakuasi keseluruhan departemen dan anda diwajibkan mengikuti arahan dari tim
            tanggap darurat dan secepat mungkin menuju area evakuasi terdekat.
          </p>
          <div className='mt-2 mb-5'>
            <a href='emergency' className='btn btn-primary rounded-100 fw-semibold'>
              Next
            </a>
          </div>
        </div>
        <div className='button-back'>
          <a href='emergency'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.87866 12L13.9393 3.93934L16.0606 6.06066L10.1213 12L16.0606 17.9393L13.9393 20.0607L5.87866 12Z'
                fill='#585858'
              />
            </svg>
          </a>
        </div>
        <Footer />
      </section>
    </>
  )
}

export {VisitorAnnounctment}
