import {FC, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useParams, Link} from 'react-router-dom'
import { ErrorMessage } from '@hookform/error-message'
import {useApi} from '../../../../hooks/useApi'
import {useFileCheck} from '../../../../hooks/useModelCheck'
import Form from 'react-bootstrap/Form';
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const ReadPage: FC = () => {
  const routeParams = useParams()
  const route = routeParams.collection ? routeParams.collection: ''
  const [collectionName, setCollectionName] = useState<string>('')
  const id = routeParams.id
  const {getModel} = useFileCheck()
  const { getOneById } = useApi()
  const collectionExist = getModel(`${route}`)
  const {layout} = collectionExist
  const [formUpdate, setFormUpdate] :any = useState({});
  const [currentImage, setCurrentImage] = useState<File>();
  const [previewImage, setPreviewImage] = useState<string>(toAbsoluteUrl('/image-danone/user-profile.jpg'));
  const [progress, setProgress] = useState<number>(0);
  const [message, setMessage] = useState<string>("");
  const {
    register,
    setValue,
    formState: {errors},
  } = useForm()

  useEffect(() => {
   const getName = layout.map(function (layoutName: any) {
    const obj = {
      name : layoutName.name,
      type: layoutName.type
    }
    return obj
  })
     getOneById(`${route}`, `${id}`).then((response) => {
      const { data }:any = response
      getName.forEach((field:any) => {
        if(field.type === 'date'){
          const date = new Date(data[field.name]);
          let day = date.getDate();
          let month = ("0" + (date.getMonth() + 1)).slice(-2)
          let year = date.getFullYear();
          const currentDate = `${year}-${month}-${day}`
          Object.assign(data,{
            [field.name] : currentDate,
          })
        }
        setValue(field.name, data[field.name]);
      })
      setFormUpdate(data)
    })
    const renameCollection = () => {
      const words = route.split('_')
       const titles: string[] = []
       for (const key in words) {
         const word = words[key]
         titles.push(word.charAt(0).toUpperCase() + word.slice(1))
       }
      return titles.join(' ')
     };
     setCollectionName(renameCollection())
}, []);

  const selectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList;
    setCurrentImage(selectedFiles?.[0]);
    setPreviewImage(URL.createObjectURL(selectedFiles?.[0]));
    setProgress(0);
  };
  return (
    


<div className="card card-custom gutter-b">
	<div className="card-header">
		<div className="card-title">
			<h3 className="card-label">
				Show {collectionName}
				<small></small>
			</h3>
		</div>
	</div>
	<div className="card-body">
  <form>
    <div className="row">
    {
      layout.map((fields: any,index: number) => {
        if(['text'].indexOf(fields.type) >= 0){
          return (
          <div className={`mb-4 ${fields.col}`}  key={`field-${fields.name}`}>
            <Form.Label className="text-black" htmlFor="input-field">{fields.label}</Form.Label>
            <Form.Control
              disabled readOnly
              defaultValue={formUpdate[fields.name]}
              type="text"
              className="text-black form-control-solid"
              placeholder={fields.placeholder}
              name={fields.name}
              />
            </div>
          )
        }
        if(['textarea'].indexOf(fields.type) >= 0){
          return (
          <div className={`mb-4 ${fields.col}`}  key={`field-${fields.name}`}>
            <Form.Label className="text-black" htmlFor="input-field">{fields.label}</Form.Label>
            <Form.Control
              type='textarea'
              readOnly disabled
              defaultValue={formUpdate[fields.name]}
              className="text-black form-control form-control-solid"
              placeholder={fields.placeholder}
              name={fields.name}
              />
            </div>
          )
        }
        if(['select'].indexOf(fields.type) >= 0){
          return  (
            <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                <div className="form-group">
                <label className="text-black">{fields.label}</label>
                <select className="form-control form-control-solid" disabled
                >
                  {fields.options.map((option: any, indexOpt:number) => (
                    <option key={indexOpt} value={option.value}>{option.label}</option>
                  ))}
              </select>
        </div>
          </div>
          )
        }
        if(['date'].indexOf(fields.type) >= 0){
          return (
            <div className={`mb-4 ${fields.col}`}  key={`field-${fields.name}`}>
            <Form.Label className="text-black" htmlFor="input-field">{fields.label}</Form.Label>
                <Form.Control
                readOnly disabled
                defaultValue={formUpdate[fields.name]}
                type="date"
                className="text-black form-control-solid"
                placeholder={fields.placeholder}
                name={fields.name}
                />
              </div>
            )
        }
        if(['email'].indexOf(fields.type) >= 0){
          return (
            <div className={`mb-4 ${fields.col}`}  key={`field-${fields.name}`}>
              <Form.Label className="text-black" htmlFor="input-field">{fields.label}</Form.Label>
              <Form.Control
                readOnly
                defaultValue={formUpdate[fields.name]}
                type="email"
                className="text-black form-control form-control-solid"
                placeholder={fields.placeholder}
                name={fields.name}
                />
              </div>
            )
        }
        if(['image'].indexOf(fields.type) >= 0){
          return(
            <div key={`field-${fields.name}`}>
            {currentImage && progress > 0 && (
              <div className="progress my-3">
                <div
                  className="progress-bar progress-bar-info"
                  role="progressbar"
                  aria-valuenow={progress}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: progress + "%" }}
                >
              {progress}%
                </div>
              </div>
            )}

            {previewImage && (
              <div>
                <img className="preview" src={previewImage} alt="User blank logo" width="30%"/>
              </div>
            )}

          {message && (
            <div className="alert alert-secondary mt-3" role="alert">
              {message}
            </div>
          )}
            <div className={`mb-4 ${fields.col}`}>
            <Form.Label className="text-black" htmlFor="input-field">{fields.label}</Form.Label>
            <Form.Control
              readOnly
              type="file"
              onChange={selectImage}
              accept="image/*"
              className="text-black form-control form-control-solid"
              placeholder={fields.placeholder}
            />
            <Form.Text className="text-muted">{fields.note}</Form.Text>
            </div>
          </div>
          )
        }
      })
    }
    </div>
    <Link to={`/${route}`}>
    <button className="btn btn-secondary cancel">Cancel </button>
    </Link>
  </form>
	</div>
</div>
  )
}
export default ReadPage