import {FC, useState, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useLocation, useNavigate, Link} from 'react-router-dom'
import {ErrorMessage} from '@hookform/error-message'
import {useApi} from '../../../../hooks/useApi'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Factory} from '../../../../models/factory'
import {Videos} from '../../../../models/videos'
import ClipLoader from 'react-spinners/ClipLoader'

export const CreatePage: FC = () => {
  const routeParams = useLocation()
  const routeUrl = routeParams.pathname.replace('/', '')
  const splitCollection = routeUrl.split('/')
  const route = splitCollection[0]
  const {getAll, create} = useApi()
  const [fileTes, setFileTes] = useState('')
  const [exist, setExist] = useState('Required')
  const [factory, setFactory] = useState<Factory[]>([])
  const [url, setUrl] = useState<any | null>('')
  const [loading, setLoading] = useState(false)
  // const [valVidio, setValvidio] = useState<string>('')

  const MySwal = withReactContent(Swal)
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm()
  const onError = (errors: any, e: any) => console.log(errors, e)
  const upload = async (payload: any) => {
    const response = await create(`video/upload?bucket=videoFactory`, payload)

    return response
  }
  const saveVideo = async (payload: any) => {
    const response = await create(`videos`, payload)
      .then((response) => {
        MySwal.fire({
          title: <strong className='text-black'>Success</strong>,
          html: <i className='text-black'>Data created!</i>,
          icon: 'success',
        })
        navigate(`/${route}`)
      })
      .catch((error) => {
        if (error.response) {
          const {data} = error.response
          MySwal.fire({
            title: <strong className='text-black'>Failed</strong>,
            html: <i className='text-black'>{data.errors[0].message}!</i>,
            icon: 'error',
          })
        }
      })
    return response
  }
  const saveFile = (e: any) => {
    const data = e.target.files[0]
    const oFReader = new FileReader()
    oFReader.readAsDataURL(data)
    oFReader.onload = () => {
      const result = oFReader.result
      setUrl(result)
    }
    setFileTes(data)
  }
  const exixtFactory = async (e: any) => {
    const parms = {
      factory_id: e,
    }
    const response = await getAll<Videos>('videos', parms)
    const {data} = response.data
    if (data.length) {
      setExist('Existing')
    }
    return data
  }
  const getFactory = (e: any) => {
    // setFactroy(e.target.value)
    exixtFactory(e.target.value)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFactoryList = async () => {
    const response = await getAll<Factory>('factory')
    const {data} = response.data
    setFactory(data)
  }
  useEffect(() => {
    getFactoryList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const onSubmit = async (data: any, e: any) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('file', fileTes)

    const response = await upload(formData)
    if (response.data) {
      setLoading(false)
      const payload = {...response.data, factory_id: data.factory_id}
      saveVideo(payload)
    }
  }
  return (
    <div>
      {loading === true ? (
        <div className='loader'>
          <ClipLoader
            color={'#00008B'}
            loading={true}
            size={50}
            aria-label='Loading Spinner'
            data-testid='loader'
            // style={{}}
          />
        </div>
      ) : (
        ''
      )}

      <div className='card card-custom gutter-b'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>
              Create
              <small></small>
            </h3>
          </div>
        </div>

        <div className='card-body'>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className='row'>
              <div className={`mb-4 col-6`}>
                <div className='form-group'>
                  <label className='text-black'>Video</label>
                  <input
                    type='file'
                    className='form-control form-control-solid'
                    {...register('video', {
                      required: true,
                      validate: {
                        lessThan10MB: (files) => files[0]?.size < 100000000 || 'Max 100 mb',
                        // acceptedFormats: (files) =>
                        //   ['video/mp4'].includes(files[0]?.type) || 'Only MP4',
                      },
                    })}
                    accept='video/*'
                    onChange={saveFile}
                  />
                  <ErrorMessage errors={errors} name='video' />
                  <ErrorMessage
                    errors={errors}
                    name='video'
                    render={() => <p className='text-danger'>Video is required !</p>}
                  />
                </div>
              </div>

              <div className={`mb-4 col-6`}>
                <div className='form-group'>
                  <label className='text-black'>Factory Id</label>
                  <select
                    className='form-control form-control-solid'
                    {...register('factory_id', {
                      required: true,
                      onChange: getFactory,
                      validate: async (value, formValues) => {
                        const parms = {
                          factory_id: value,
                        }
                        const response = await getAll<Videos>('videos', parms)
                        const {data} = response.data
                        if (data.length) {
                          setExist('Existing')
                        }
                        return data.length === 0
                      },
                    })}
                  >
                    <option disabled selected>
                      Choice Factory
                    </option>
                    <>
                      {factory.map((entry: Factory) => {
                        return (
                          <option
                            key={entry.id}
                            className='form-control form-control-solid text-black'
                            id=''
                            value={entry.id}
                          >
                            {entry.name}
                          </option>
                        )
                      })}
                    </>
                  </select>
                  <ErrorMessage errors={errors} name='factory_id' />
                  <ErrorMessage
                    errors={errors}
                    name='factory_id'
                    render={() => <p className='text-danger'>Factory is {exist} !</p>}
                  />
                </div>
              </div>
              <div className='col-6 my-5 text-center'>
                <>
                  {url !== '' ? (
                    <>
                      <video key={url} controls width='320' height='240'>
                        <source key={url} src={url} type='video/mp4' />
                      </video>
                    </>
                  ) : (
                    ''
                  )}
                </>
              </div>
            </div>

            <Link to={`/${route}`}>
              <button className='btn btn-secondary cancel'>Cancel </button>
            </Link>

            <button type='submit' className='btn btn-success'>
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
