import {FC, useState, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useLocation, useNavigate, Link} from 'react-router-dom'
import {ErrorMessage} from '@hookform/error-message'
import {useApi} from '../../../../hooks/useApi'
import {useFileCheck} from '../../../../hooks/useModelCheck'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Form from 'react-bootstrap/Form'
import {Factory} from '../../../../models/factory'
import {MasterRange} from '../../../../models/master_range'
import ClipLoader from 'react-spinners/ClipLoader'

export const CreatePage: FC = () => {
  const [collectionName, setCollectionName] = useState<string>('')
  const routeParams = useLocation()
  const navigate = useNavigate()
  const routeUrl = routeParams.pathname.replace('/', '')
  const splitCollection = routeUrl.split('/')
  const route = splitCollection[0]
  const {getModel} = useFileCheck()
  const {create, getAll} = useApi()
  const MySwal = withReactContent(Swal)
  const collectionExist = getModel(`${route}`)
  const {layout} = collectionExist

  const [factory, setFactory] = useState<Factory[]>([])
  const [loading, setLoading] = useState(false)
  const [exist, setExist] = useState('Required')

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<MasterRange>()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFactory = async () => {
    const response = await getAll<Factory>('factory')
    const {data} = response.data
    setFactory(data)
  }

  useEffect(() => {
    const renameCollection = () => {
      const words = route.split('_')
      const titles: string[] = []
      for (const key in words) {
        const word = words[key]
        titles.push(word.charAt(0).toUpperCase() + word.slice(1))
      }
      return titles.join(' ')
    }
    setCollectionName(renameCollection())
    getFactory()
  }, [])
  const onSubmit = async (data: MasterRange, e: any) => {
    setLoading(true)
    const payload = {data}
    await create(`${route}`, payload.data)
      .then((response) => {
        MySwal.fire({
          title: <strong className='text-black'>Success</strong>,
          html: <i className='text-black'>Data created!</i>,
          icon: 'success',
        })
        navigate(`/${route}`)
      })
      .catch((error) => {
        if (error.response) {
          const {data} = error.response
          MySwal.fire({
            title: <strong className='text-black'>Failed</strong>,
            html: <i className='text-black'>{data.errors[0].message}!</i>,
            icon: 'error',
          })
        }
      })
      .finally(() => setLoading(false))
  }
  const onError = (errors: any, e: any) => console.log(errors, e)
  // for type file Image
  return (
    <div>
      {loading === true ? (
        <div className='loader'>
          <ClipLoader
            color={'#00008B'}
            loading={true}
            size={50}
            aria-label='Loading Spinner'
            data-testid='loader'
            // style={{}}
          />
        </div>
      ) : (
        ''
      )}
      <div className='card card-custom gutter-b'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>
              Create {collectionName}
              <small></small>
            </h3>
          </div>
        </div>
        <div className='card-body'>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className='row'>
              {layout.map((fields: any, index: number) => {
                if (['number'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <Form.Label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </Form.Label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        type='number'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <Form.Text className='text-muted'>{fields.note}</Form.Text>
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['text'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <Form.Label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </Form.Label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        type='text'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <Form.Text className='text-muted'>{fields.note}</Form.Text>
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['textarea'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <Form.Label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </Form.Label>
                      <textarea
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <Form.Text className='text-muted'>{fields.note}</Form.Text>
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['select'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <div className='form-group'>
                        <label className='text-black'>{fields.label}</label>
                        <select
                          className='form-control form-control-solid'
                          {...register(fields.name)}
                        >
                          {fields.options.map((option: any, indexOpt: number) => (
                            <option key={indexOpt} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <Form.Text className='text-muted'>{fields.note}</Form.Text>
                        <ErrorMessage errors={errors} name={fields.name} />
                        <ErrorMessage
                          errors={errors}
                          name={fields.name}
                          render={() => <p className='text-danger'>{fields.name} is required !</p>}
                        />
                      </div>
                    </div>
                  )
                }
                if (['date'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <Form.Label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </Form.Label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        type='date'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <Form.Text className='text-muted'>{fields.note}</Form.Text>
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['email'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <Form.Label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </Form.Label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                          pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        })}
                        type='email'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <Form.Text className='text-muted'>{fields.note}</Form.Text>
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }

                return layout
              })}
              <div className={`mb-4 col-6`}>
                <Form.Group>
                  <Form.Label>Factory Id</Form.Label>
                  <Form.Select
                    {...register('factory_id', {
                      required: true,
                      onChange: getFactory,
                      validate: async (value, formValues) => {
                        const parms = {
                          factory_id: value,
                        }
                        const response = await getAll<MasterRange>('master_range', parms)
                        const {data} = response.data
                        if (data.length) {
                          setExist('Existing')
                        }
                        return data.length === 0
                      },
                    })}
                  >
                    <option value=''>Select Factory</option>
                    {factory.map((entry: Factory) => {
                      return (
                        <option key={entry.id} id='' value={entry.id}>
                          {entry.name}
                        </option>
                      )
                    })}
                  </Form.Select>
                </Form.Group>
                <ErrorMessage errors={errors} name='factory_id' />
                <ErrorMessage
                  errors={errors}
                  name='factory_id'
                  render={() => <p className='text-danger'>Factory is required !</p>}
                />
              </div>
            </div>
            <Link to={`/${route}`}>
              <button className='btn btn-secondary cancel'>Cancel </button>
            </Link>
            <button type='submit' className='btn btn-success'>
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
