export interface Factory {
    id: string
    name:string
    long_name:string
  }
  export const useCollection = () => {
    const collection = 'factory'
    const forms: Factory = {
      id:'',
      name: '',
      long_name:''
    }
  
    const columns = [
      {
        name: 'name',
        required: true,
        label: 'Name',
        align: 'left',
        field: 'name',
        sortable: true
      },
      // Always give this columns as default
      {
        name: 'long_name',
        required: true,
        label: 'Zone',
        align: 'left',
        field: 'zone',
        sortable: true
      },
    ]
    const layout = [
  
        {
          type: 'text',
          advanceFilter: true,
          col: 'col-6',
          name: 'name',
          placeholder: 'Name',
          label: 'Name',
          required: false
        },{
          type: 'text',
          advanceFilter: true,
          col: 'col-6',
          name: 'long_name',
          placeholder: 'Long Name',
          label: 'Long Name',
          required: false
        }
    ]
    const filter = [
      {
        type: 'reference',
        advanceFilter: true,
        col: 'col-6',
        name: 'factory_id',
        placeholder: 'Factory',
        label: 'Select Factory',
        reference: 'factory',
        option : {
          value: 'id',
          label : 'name'
        }
      },
    ]
    return {
      collection,
      forms,
      columns,
      layout,
      filter
    }
  }
