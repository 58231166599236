export interface MasterQuestion {
    id: string
    question: string
    answer: string[] | string
    correct_answer: string
    question_status: string
    factory_id:string
    area_khusus:boolean
    efective_date_id:number
    order:string
    question_en?: string
    answer_en?: string[] | string
    correct_answer_en?: string
  }
  export const useCollection = () => {
    const collection = 'master_question'
    const forms: MasterQuestion = {
        id:'',
      question: '',
      question_status: '',
      answer: [],
      correct_answer: '',
      factory_id: '',
      area_khusus:false,
      efective_date_id:0,
      order:'',
      question_en: '',
      answer_en: [],
      correct_answer_en: '',
    }
  
    const columns = [
      {
        name: 'question',
        required: true,
        label: 'Question',
        align: 'left',
        field: 'question',
        sortable: true
      },
      // Always give this columns as default
      {
        name: 'correct_answer',
        required: true,
        label: 'Correct Answer',
        align: 'left',
        field: 'correct_answer',
        sortable: true
      },
      {
        name: 'question_status',
        required: true,
        label: 'Question Status',
        align: 'left',
        field: 'question_status',
        sortable: true
      },
      {
        name: 'area_khusus',
        required: true,
        label: 'Area Khusus',
        align: 'left',
        field: 'area_khusus',
        sortable: true
      },
      {
        name: 'factory_id',
        required: true,
        label: 'Factory',
        align: 'left',
        field: 'factory_id',
        sortable: true
      },
    ]
    const layout = [
      {
        type: 'select',
        col: 'col-4',
        name: 'question_status',
        label: 'Status show',
        options: [
          {
            label: 'Active',
            value: 'active'
          },
          {
            label: 'Inactive',
            value: 'inactive'
          }
        ]
      },
      {
        type: 'select',
        col: 'col-4',
        name: 'area_khusus',
        label: 'Area Khusus',
        options: [
          {
            label: 'Ya',
            value: true
          },
          {
            label: 'Tidak',
            value: false
          }
        ]
      },
    ]
    const filter = [{
      type: 'reference',
      advanceFilter: true,
      col: 'col-6',
      name: 'factory_id',
      placeholder: 'Factory',
      label: 'Select Factory',
      reference: 'factory',
      option : {
        value: 'id',
        label : 'name'
      }
    }]

    return {
      collection,
      forms,
      columns,
      layout,
      filter

    }
  }
