import {FC, useState, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useLocation, Link, useNavigate} from 'react-router-dom'
import {ErrorMessage} from '@hookform/error-message'
import {useApi} from '../../../../hooks/useApi'
import {useFileCheck} from '../../../../hooks/useModelCheck'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Factory} from '../../../../models/factory'
import {Form} from 'react-bootstrap'
import ClipLoader from 'react-spinners/ClipLoader'
import {sysparam} from '../../../../models/sysparam'
import {efectiveDate} from '../../../../models/efectiveDate'

export const CreatePage: FC = () => {
  const [collectionName, setCollectionName] = useState<string>('')
  const [factory, setFactory] = useState<Factory[]>([])
  const [efectiveDateOwner, setEfectiveDateOwner] = useState<sysparam[]>([])
  const routeParams = useLocation()
  const routeUrl = routeParams.pathname.replace('/', '')
  const splitCollection = routeUrl.split('/')
  const route = splitCollection[0]
  const {getModel} = useFileCheck()
  const {create, getAll} = useApi()
  const MySwal = withReactContent(Swal)
  const collectionExist = getModel(`${route}`)
  const {layout} = collectionExist
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<efectiveDate>()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFactory = async () => {
    const response = await getAll<Factory>('factory')
    const {data} = response.data
    setFactory(data)
  }
  const getEfectiveOwner = async () => {
    const params = {
      group: 'efectiveDate',
    }
    const response = await getAll<sysparam>('sysparam', params)
    const {data} = response.data
    setEfectiveDateOwner(data)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const renameCollection = () => {
      const words = route.split('_')
      const titles: string[] = []
      for (const key in words) {
        const word = words[key]
        titles.push(word.charAt(0).toUpperCase() + word.slice(1))
      }
      return titles.join(' ')
    }
    setCollectionName(renameCollection())
    getFactory()
    getEfectiveOwner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const onSubmit = async (data: efectiveDate, e: any) => {
    setLoading(true)
    const payload = {data}
    create<efectiveDate>(`${route}`, payload.data)
      .then((response) => {
        const {data} = response
        console.log(data)
        MySwal.fire({
          title: <strong className='text-black'>Success</strong>,
          html: <i className='text-black'>Data created!</i>,
          icon: 'success',
        })
        navigate(`/${route}?date_owner=${data?.date_owner}`)
      })
      .catch((error) => {
        if (error.response) {
          const {data} = error.response
          MySwal.fire({
            title: <strong className='text-black'>Failed</strong>,
            html: <i className='text-black'>{data.errors[0].message}!</i>,
            icon: 'error',
          })
        }
      })
      .finally(() => setLoading(false))
  }
  const onError = (errors: any, e: any) => console.log(errors, e)
  return (
    <div>
      {loading === true ? (
        <div className='loader'>
          <ClipLoader
            color={'#00008B'}
            loading={true}
            size={50}
            aria-label='Loading Spinner'
            data-testid='loader'
            // style={{}}
          />
        </div>
      ) : (
        ''
      )}
      <div className='card card-custom gutter-b'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>
              Create {collectionName}
              <small></small>
            </h3>
          </div>
        </div>
        <div className='card-body'>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className='row'>
              {layout.map((fields: any, index: number) => {
                if (['select'].indexOf(fields.type) >= 0) {
                  if (fields.name === 'factory_id') {
                    return (
                      <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                        <Form.Group>
                          <Form.Label>{fields.label}</Form.Label>
                          <Form.Select
                            {...register(fields.name, {
                              required: true,
                            })}
                          >
                            <option value=''>Select Factory</option>
                            {factory.map((entry: Factory) => {
                              return (
                                <option key={entry.id} id='' value={entry.id}>
                                  {entry.name}
                                </option>
                              )
                            })}
                          </Form.Select>
                        </Form.Group>
                        <ErrorMessage errors={errors} name='factory_id' />
                        <ErrorMessage
                          errors={errors}
                          name='factory_id'
                          render={() => <p className='text-danger'>Factory is required !</p>}
                        />
                      </div>
                    )
                  } else {
                    return (
                      <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                        <Form.Group>
                          <Form.Label>{fields.label}</Form.Label>
                          <Form.Select
                            {...register(fields.name, {
                              required: true,
                            })}
                          >
                            <option value=''>Select {fields.label}</option>
                            {efectiveDateOwner.map((entry: sysparam) => {
                              return (
                                <option key={entry.id} id='' value={entry.value}>
                                  {entry.long_value}
                                </option>
                              )
                            })}
                          </Form.Select>
                        </Form.Group>
                        <ErrorMessage errors={errors} name='factory_id' />
                        <ErrorMessage
                          errors={errors}
                          name='factory_id'
                          render={() => <p className='text-danger'>Factory is required !</p>}
                        />
                      </div>
                    )
                  }
                }
                if (['date'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        type='date'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                return layout
              })}
            </div>
            <div className='mt-10'>
              <Link to={`/${route}`}>
                <button className='btn btn-secondary cancel'>Cancel </button>
              </Link>
              <button type='submit' className='btn btn-success'>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
