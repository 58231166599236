import {useLocation} from 'react-router-dom'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {KTCard} from '../../../../_metronic/helpers'
import {ListHeader} from '../../components/headerMasterQuestion/ListHeader'
import {Table} from '../../table/QuestionTable'
import {useEffect, useState} from 'react'
import {useApi} from '../../../../hooks/useApi'
import {MasterQuestion} from '../../../../models/master_question'
import {ActionsCell} from '../../table/questionColumns/ActionsCell'

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(startIndex, 0, removed)
  return result
}
const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgray',
  padding: 8,
  width: 250,
})
export const ListPage = () => {
  const {getAll} = useApi()
  const routeParams = useLocation()
  const routeUrl = routeParams.pathname.replace('/', '')
  const splitCollection = routeUrl.split('/')
  const route = splitCollection[0]
  const id = splitCollection[1]
  const [item, setItem] = useState([])
  useEffect(() => {
    const param = {
      efective_date_id: id,
    }
    getAll<MasterQuestion>('master_question', param).then((response) => {
      const data: any = response.data.data
      setItem(data)
    })
  }, [])
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }
    const reorderItem = reorder(item, result.source.index, result.destination.index)
    // setItem(reorderItem)
  }
  return (
    // <>
    //   <KTCard className='py-5 px-5'>
    //     <ListHeader />

    //     {/* <DragDropContext onDragEnd={onDragEnd}>
    //       <Droppable droppableId='droppable'>
    //         {(provided, snapshot) => (
    //           <div
    //             {...provided.droppableProps}
    //             ref={provided.innerRef}
    //             style={getListStyle(snapshot.isDraggingOver)}
    //           >
    //             {item.map((entry: any, index) => (
    //               <Draggable key={entry.id} draggableId={entry.id} index={index}>
    //                 {(provided, snapshot) => (
    //                   <div
    //                     className='p-3 m-3 card '
    //                     ref={provided.innerRef}
    //                     {...provided.draggableProps}
    //                     {...provided.dragHandleProps}
    //                   >
    //                     <div className='card-body shadow-sm'>
    //                       <div className='row'>
    //                         <div className='col-8'>This is some text within a card body.</div>
    //                         <div className='col-2'>{entry.id}</div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 )}
    //               </Draggable>
    //             ))}
    //           </div>
    //         )}
    //       </Droppable>
    //     </DragDropContext> */}
    //     <div>
    //       {item.map((entry: any) => (
    //         <div className='m-5 card '>
    //           <div className='card-body shadow-sm'>
    //             <div className='row'>
    //               <div className='col-8'>{entry.question}</div>
    //               <div className='col-2'>{entry.correct_answer}</div>
    //               <div className='col-2 text-end'>
    //                 <ActionsCell id={entry.id} />
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       ))}
    //     </div>
    //   </KTCard>
    // </>
    <>
      <KTCard>
        <ListHeader />
        <Table />
      </KTCard>
    </>
  )
}
