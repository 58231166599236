import {FC, useEffect, useState} from 'react'
import shapePage4 from '../../layout/img/page-4/img-other/Shape-page4-1.png'
import danone from '../../layout/img/Danone-Logo.png'
import {Footer} from '../../components/footer/Footer'
import {useApi} from '../../hooks/useApi'
import {MasterMaps} from '../../models/master_maps'
import {Helmet} from 'react-helmet'
const API_URL = process.env.REACT_APP_API_BASE_URL

const Maps: FC = () => {
  const {getAll} = useApi()
  const [dataMaps, setDataMaps] = useState<MasterMaps>()
  const [imageSrc, setImageSrc] = useState('')
  const [iconSrc, setIconSrc] = useState('')
  const [billingual, setBillingual] = useState({
    meters3: 'M',
    pangkat: '3'
   })
  const fetchData = async () => {
    const factory = localStorage.getItem('factory')
    let idFactory
    if (factory) {
      idFactory = JSON.parse(factory)
    }
    const params = {
      factory_id: idFactory['factory_id'],
    }
    const response = await getAll<MasterMaps>('master_maps', params)
    const {data} = response.data
    setDataMaps(data[0])
    const image = API_URL + `/maps/` + data[0].image
    const icon = API_URL + `/maps/` + data[0].icon
    setImageSrc(image)
    setIconSrc(icon)
  }
  const [labelFactory, setLabelFactory] = useState('')
  useEffect(() => {
    const getLocalLabel = localStorage.getItem('factory')
    if (getLocalLabel) {
      const parse = JSON.parse(getLocalLabel)
      setLabelFactory(parse.factoryName)
    }
    const getLang = localStorage.getItem('language')
    if(getLang === 'en'){
      setBillingual ({
        meters3 : 'Hectare',
        pangkat : ''
      })
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section
        id='vms-page-6'
        className='d-flex flex-wrap align-items-center justify-content-around flex-column overflow-hidden'
      >
        <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
          <img src={danone} alt='' width={220} />
          <img src={iconSrc} alt='' width={100} />
        </div>
        <div
          className='bg-img-page-3 w-100 h-100 position-absolute d-block d-md-none'
          style={{zIndex: -1}}
        ></div>
        <img src={shapePage4} className='shape-page-1 img-shape-3' alt='' />
        <div className='row gx-10 align-items-center w-100'>
          <div className='col-12 col-md-6 text-center-sm'>
            <h2>{labelFactory} FACTORY</h2>
            <h1>Maps</h1>
            <div className='d-none d-md-block'>
              <h2 className='text-dark-gray'>OUR SITE</h2>
              <p className='mb-0 text-dark-gray'>
                TOTAL LAND AREA : {dataMaps?.land_area} {billingual.meters3} <sup>{billingual.pangkat}</sup>
              </p>
              <p className='text-dark-gray'>
                TOTAL BUILDING AREA : {dataMaps?.building_area} {billingual.meters3}<sup>{billingual.pangkat}</sup>
              </p>
            </div>
            <div className='gap-3 d-none d-md-flex mt-10'>
              <a href='visitor-note' className='btn btn-secondary btn-md-back'>
                Back
              </a>
              <a href='hygiene-rules' className='btn btn-primary rounded-100 fw-semibold'>
                Next
              </a>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='position-relative'>
              <img src={imageSrc} className='my-5 w-100' alt='' />
              {/* <img src={iconSrc} className='w-25 position-absolute top-0 start-0' alt='' /> */}
            </div>
            <div className='d-block d-md-none text-center'>
              <h2 className='text-dark-gray'>OUR SITE</h2>
              <p className='mb-0 text-dark-gray'>
                TOTAL LAND AREA : {dataMaps?.land_area} M <sup>3</sup>
              </p>
              <p className='text-dark-gray'>
                TOTAL BUILDING AREA : {dataMaps?.building_area} M<sup>3</sup>
              </p>
              <div className='mt-10 d-block d-md-none'>
                <a href='hygiene-rules' className='btn btn-primary rounded-100 fw-semibold'>
                  Next
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='button-back'>
          <a href='visitor-note'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.87866 12L13.9393 3.93934L16.0606 6.06066L10.1213 12L16.0606 17.9393L13.9393 20.0607L5.87866 12Z'
                fill='#585858'
              />
            </svg>
          </a>
        </div>
        <Footer />
      </section>
    </>
  )
}

export {Maps}
