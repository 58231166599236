import React, {useRef} from 'react'
import $ from 'jquery'
import 'select2'
import Form from 'react-bootstrap/Form'
interface Select2Props {
  onChange: (value: string) => void
  fieldForm: any
}
const Select2: React.FC<Select2Props> = ({onChange, fieldForm}) => {
  const selectRef = useRef<HTMLSelectElement>(null)

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value
    onChange(selectedValue)
  }

  return (
    <>
    <Form.Group>
      <Form.Label className='text-black' htmlFor='input-field'>
        {fieldForm.label}
      </Form.Label>
      <Form.Select
        className='form-control form-control-solid custom-select'
        name={fieldForm.name}
        ref={selectRef}
        onChange={handleSelectChange}
      >
        <option value=''>--Select--</option>
        {fieldForm.options.map((option: any, indexOpt: number) => (
          <option key={indexOpt} value={option.value}>
            {option.label}
          </option>
        ))}
    </Form.Select>
    </Form.Group>
      <Form.Text className='text-muted'>{fieldForm.note}</Form.Text>
    </>
  )
}

export default Select2
