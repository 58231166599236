import {FC, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useLocation, useNavigate} from 'react-router-dom'
import {ErrorMessage} from '@hookform/error-message'
import {useApi} from '../../../../hooks/useApi'
import {useFileCheck} from '../../../../hooks/useModelCheck'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Form from 'react-bootstrap/Form'
import {Table, Tabs, Tab} from 'react-bootstrap'

import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Factory} from '../../../../models/factory'
import {areas} from '../../../../models/areas'
import ClipLoader from 'react-spinners/ClipLoader'

export const EditPage: FC = () => {
  interface TestType {
    [key: string]: any;
  }
  const API_URL = process.env.REACT_APP_API_BASE_URL
  const [collectionName, setCollectionName] = useState<string>('')
  const routeParams = useLocation()
  const routeUrl = routeParams.pathname.replace('/', '')
  const splitCollection = routeUrl.split('/')
  const route = splitCollection[0]
  const id = splitCollection[1]
  const {getModel} = useFileCheck()
  const {getOneById, update, getAll} = useApi()
  const MySwal = withReactContent(Swal)
  const collectionExist = getModel(`${route}`)
  const {layout} = collectionExist
  const [formUpdate, setFormUpdate]: any = useState({})
  const [currentImage, setCurrentImage] = useState<File>()
  const [previewImage, setPreviewImage] = useState<string>(
    toAbsoluteUrl('/image-danone/user-profile.jpg')
  )
  const [progress, setProgress] = useState<number>(0)
  const [message] = useState<string>('')
  const [factory, setFactory] = useState<Factory[]>([])
  const [kuisioner, setKuisioner] = useState<JSX.Element[]>([])
  const [postTest, setPostTest] = useState<JSX.Element[]>([])
  const [formAreaKhusus, setAreaKhusus] = useState<JSX.Element[]>([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<areas>()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFactory = async () => {
    const response = await getAll<Factory>('factory')
    const {data} = response.data
    setFactory(data)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rowQuestioner = (entry: TestType, lang: string | null) => {
    const tableRows = [];
    for (let i = 1; i <= entry.length; i++) {
      let color: string = ""
      if(!entry[i-1].true_answer){
        color = "red"
      }
      tableRows.push(
        <tr key={i} style={{color: color}}>
            <td style={{textAlign: 'center'}}>{i}</td>
            <td>{lang === 'en' ? entry[i-1].question_en : entry[i-1].question}</td>
            <td>{entry[i-1].answer}</td>
        </tr>
      );
    }
    setKuisioner(tableRows)
  }
  const rowPostTest = (entry: TestType, lang: string | null) => {
    const tableRows = [];
    for (let i = 1; i <= entry.length; i++) {
      tableRows.push(
        <tr key={i}>
            <td style={{textAlign: 'center'}}>{i}</td>
            <td>{lang === 'en' ? entry[i-1].question_en : entry[i-1].question}</td>
            <td>{entry[i-1].answer}</td>
        </tr>
      );
    }
    setPostTest(tableRows)
  }
  const rowAreaKhusus = (entry: TestType) => {
    const tableRows = [];
    for (let i = 1; i <= entry.length; i++) {
      tableRows.push(
        <tr key={i}>
            <td style={{textAlign: 'center'}}>{entry[i-1].area}</td>
            <td>{entry[i-1].start}</td>
            <td>{entry[i-1].end}</td>
        </tr>
      );
    }
    setAreaKhusus(tableRows)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getFactory()
    const getName = layout.map(function (layoutName: any) {
      const obj = {
        name: layoutName.name,
        type: layoutName.type,
      }
      return obj
    })
    getOneById(`${route}`, `${id}`).then((response) => {
      const {data}: any = response
      getName.forEach((field: any) => {
        if (field.type === 'date') {
          const date = new Date(data[field.name])
          let day = date.toLocaleString('en-US', {day: '2-digit'})
          let month = ('0' + (date.getMonth() + 1)).slice(-2)
          let year = date.getFullYear()
          const currentDate = `${year}-${month}-${day}`
          Object.assign(data, {
            [field.name]: currentDate,
          })
          setValue(field.name, currentDate)
        }
        setValue('factory_id', data['factory_id'])
      })
      setFormUpdate(data)
      const urlGetImage = API_URL +'/imagesVisitor/'+ data.image
      setPreviewImage(urlGetImage)
      rowQuestioner(JSON.parse(data.questioner),data['lang'])
      rowPostTest(JSON.parse(data.post), data['lang'])
      rowAreaKhusus(JSON.parse(data.form_area_khusus))
    })

    const renameCollection = () => {
      const words = route.split('_')
      const titles: string[] = []
      for (const key in words) {
        const word = words[key]
        titles.push(word.charAt(0).toUpperCase() + word.slice(1))
      }
      return titles.join(' ')
    }
    setCollectionName(renameCollection())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue])

  const onSubmit = async (data: areas, e: any) => {
    setLoading(true)
    Object.assign(data, {
      photo: currentImage,
    })
    const payload = data
    await update(`${route}`, id, payload)
      .then((response) => {
        MySwal.fire({
          title: <strong className='text-black'>Success</strong>,
          html: <i className='text-black'>Data Update!</i>,
          icon: 'success',
        })
        navigate(`/${route}`)
      })
      .catch((error) => {
        if (error.response) {
          const {data} = error.response
          MySwal.fire({
            title: <strong className='text-black'>Failed</strong>,
            html: <i className='text-black'>{data.errors[0].message}!</i>,
            icon: 'error',
          })
        }
      })
      .finally(() => setLoading(false))
  }
  const onError = (errors: any, e: any) => console.log(errors, e)
  const selectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList
    setCurrentImage(selectedFiles?.[0])
    setPreviewImage(URL.createObjectURL(selectedFiles?.[0]))
    setProgress(0)
  }
  const changeFactory = (newSelect: string): void => {
    setFormUpdate('factory_id', newSelect)
  }
  return (
    <div>
      {loading === true ? (
        <div className='loader'>
          <ClipLoader
            color={'#00008B'}
            loading={true}
            size={50}
            aria-label='Loading Spinner'
            data-testid='loader'
            // style={{}}
          />
        </div>
      ) : (
        ''
      )}
      <div className='card card-custom gutter-b'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>
              Detail {collectionName}
            </h3>
          </div>
        </div>
        <div className='card-body'>
          <Tabs defaultActiveKey='tab1' id='my-tabs'>
            <Tab eventKey='tab1' title='Detail Visitor'>
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div className='row'>
                  {layout.map((fields: any, index: number) => {
                    if (['text'].indexOf(fields.type) >= 0) {
                      return (
                        <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                          <label className='text-black' htmlFor='input-field'>
                            {fields.label}
                          </label>
                          <input
                            {...register(fields.name, {
                              required: fields.required,
                            })}
                            defaultValue={formUpdate[fields.name]}
                            type='text'
                            className='text-black form-control form-control-solid'
                            placeholder={fields.placeholder}
                            name={fields.name}
                            readOnly={fields.readonly}
                          />
                          <ErrorMessage errors={errors} name={fields.name} />
                          <ErrorMessage
                            errors={errors}
                            name={fields.name}
                            render={() => (
                              <p className='text-danger'>{fields.name} is required !</p>
                            )}
                          />
                        </div>
                      )
                    }
                    if (['textarea'].indexOf(fields.type) >= 0) {
                      return (
                        <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                          <label className='text-black' htmlFor='input-field'>
                            {fields.label}
                          </label>
                          <textarea
                            {...register(fields.name, {
                              required: fields.required,
                            })}
                            defaultValue={formUpdate[fields.name]}
                            className='text-black form-control form-control-solid'
                            placeholder={fields.placeholder}
                            name={fields.name}
                            readOnly={fields.readonly}
                          />
                          <ErrorMessage errors={errors} name={fields.name} />
                          <ErrorMessage
                            errors={errors}
                            name={fields.name}
                            render={() => (
                              <p className='text-danger'>{fields.name} is required !</p>
                            )}
                          />
                        </div>
                      )
                    }
                    if (['select'].indexOf(fields.type) >= 0) {
                      return (
                        <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                          <div className='form-group'>
                            <label className='text-black'>{fields.label}</label>
                            <select
                              className='form-control form-control-solid'
                              {...register(fields.name)}
                              defaultValue={formUpdate[fields.name]}
                              disabled={fields.readonly}
                            >
                              {fields.options.map((option: any, indexOpt: number) => (
                                <option key={indexOpt} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            <ErrorMessage errors={errors} name={fields.name} />
                            <ErrorMessage
                              errors={errors}
                              name={fields.name}
                              render={() => (
                                <p className='text-danger'>{fields.name} is required !</p>
                              )}
                            />
                          </div>
                        </div>
                      )
                    }
                    if (['date'].indexOf(fields.type) >= 0) {
                      return (
                        <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                          <label className='text-black' htmlFor='input-field'>
                            {fields.label}
                          </label>
                          <input
                            {...register(fields.name, {
                              required: fields.required,
                            })}
                            defaultValue={formUpdate[fields.name]}
                            type='date'
                            readOnly={fields.readonly}
                            className='text-black form-control form-control-solid'
                            placeholder={fields.placeholder}
                            name={fields.name}
                          />
                          <ErrorMessage errors={errors} name={fields.name} />
                          <ErrorMessage
                            errors={errors}
                            name={fields.name}
                            render={() => (
                              <p className='text-danger'>{fields.name} is required !</p>
                            )}
                          />
                        </div>
                      )
                    }
                    if (['email'].indexOf(fields.type) >= 0) {
                      return (
                        <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                          <label className='text-black' htmlFor='input-field'>
                            {fields.label}
                          </label>
                          <input
                            {...register(fields.name, {
                              required: fields.required,
                              pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                            })}
                            defaultValue={formUpdate[fields.name]}
                            type='email'
                            readOnly={fields.readonly}
                            className='text-black form-control form-control-solid'
                            placeholder={fields.placeholder}
                            name={fields.name}
                          />
                          <ErrorMessage errors={errors} name={fields.name} />
                          <ErrorMessage
                            errors={errors}
                            name={fields.name}
                            render={() => (
                              <p className='text-danger'>{fields.name} is required !</p>
                            )}
                          />
                        </div>
                      )
                    }
                    if (['image'].indexOf(fields.type) >= 0) {
                      return (
                        <div key={`field-${fields.name}`}>
                          {currentImage && progress > 0 && (
                            <div className='progress my-3'>
                              <div
                                className='progress-bar progress-bar-info'
                                role='progressbar'
                                aria-valuenow={progress}
                                aria-valuemin={0}
                                aria-valuemax={100}
                                style={{width: progress + '%'}}
                              >
                                {progress}%
                              </div>
                            </div>
                          )}

                          {previewImage && (
                            <div>
                              <img
                                className='preview'
                                src={previewImage}
                                alt='User blank logo'
                                width='30%'
                              />
                            </div>
                          )}

                          {message && (
                            <div className='alert alert-secondary mt-3' role='alert'>
                              {message}
                            </div>
                          )}
                        </div>
                      )
                    }
                    return layout
                  })}
                  <div className={`mb-4 col-6`}>
                    <Form.Group>
                      <Form.Label>Factory Id</Form.Label>
                      <Form.Select
                        {...register('factory_id', {
                          required: true,
                        })}
                        onChange={(event) => changeFactory(event.target.value)}
                        value={formUpdate['factory_id']}
                      >
                        <option value=''>Select Factory</option>
                        {factory.map((entry: Factory) => {
                          return (
                            <option key={entry.id} id='' value={entry.id}>
                              {entry.name}
                            </option>
                          )
                        })}
                      </Form.Select>
                    </Form.Group>
                    <ErrorMessage errors={errors} name='factory_id' />
                    <ErrorMessage
                      errors={errors}
                      name='factory_id'
                      render={() => <p className='text-danger'>Factory is required !</p>}
                    />
                  </div>
                </div>
              </form>
            </Tab>
            <Tab eventKey='tab2' title='Quizioner'>
              <Table bordered style={{border: '1px solid black'}} responsive>
                <thead>
                  <tr>
                    <th style={{backgroundColor: '#B5B5C3', textAlign: 'center'}} colSpan={3}>
                      <h4>Quizioner</h4>
                    </th>
                  </tr>
                  <tr>
                    <th style={{textAlign: 'center'}}>No</th>
                    <th style={{textAlign: 'center'}}>Question</th>
                    <th >Answer</th>
                  </tr>
                </thead>
                <tbody>
                {kuisioner}
                </tbody>
              </Table>
            </Tab>
            <Tab eventKey='tab3' title='Post Test'>
            <Table bordered style={{border: '1px solid black'}} responsive>
                <thead>
                  <tr>
                    <th style={{backgroundColor: '#B5B5C3', textAlign: 'center'}} colSpan={3}>
                      <h4>Post Test</h4>
                    </th>
                  </tr>
                  <tr>
                    <th style={{textAlign: 'center'}}>No</th>
                    <th style={{textAlign: 'center'}}>Question</th>
                    <th >Answer</th>
                  </tr>
                </thead>
                <tbody>
                {postTest}
                </tbody>
              </Table>
            </Tab>
            <Tab eventKey='tab4' title='Area Khusus'>
            <Table bordered style={{border: '1px solid black'}} responsive>
                <thead>
                  <tr>
                    <th style={{backgroundColor: '#B5B5C3', textAlign: 'center'}} colSpan={3}>
                      <h4>Area Khusus</h4>
                    </th>
                  </tr>
                  <tr>
                    <th style={{textAlign: 'center'}}>Area</th>
                    <th>Start</th>
                    <th>End</th>
                  </tr>
                </thead>
                <tbody>
                {formAreaKhusus}
                </tbody>
              </Table>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  )
}
