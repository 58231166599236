export interface Approvals {
    id:string
    nama: string
    no_identitas:string
    bertemu_dengan:string
    perusahaan:string
    tujuan_bertemu:string
    tgl_kedatangan:string
    factory_id:string
    type_visitor:string
    status_boking:string
    status_owner_approval: string
  }
  export const useCollection = () => {
    const collection = 'visitor'
    const forms: Approvals = {
      id:'',
      nama: '',
      no_identitas:'',
      bertemu_dengan:'',
      perusahaan:'',
      tujuan_bertemu:'',
      tgl_kedatangan:'',
      factory_id:'',
      type_visitor:'',
      status_boking:'',
      status_owner_approval: ''
    }
  
    const columns = [

      {
        name: 'nama',
        label: 'Name',
        align: 'left',
        field: 'nama',
        sortable: true
      },
      {
        name: 'no_identitas',
        label: 'ID Number',
        align: 'left',
        field: 'no_identitas',
        sortable: true
      },
      {
        name: 'type_visitor',
        label: 'Type Visitor',
        align: 'left',
        field: 'type_visitor',
        sortable: true
      },
      {
        name: 'tgl_kedatangan',
        label: 'Tanggal Kedatangan',
        align: 'left',
        field: 'tgl_kedatangan',
        sortable: true
      },
      {
        name: 'perusahaan',
        label: 'Perusahaan',
        align: 'left',
        field: 'perusahaan',
        sortable: true
      },
      {
        name: 'bertemu_dengan',
        label: 'Bertemu Dengan',
        align: 'left',
        field: 'bertemu_dengan',
        sortable: true
      },
      {
        name: 'factory_id',
        label: 'Factory Id',
        align: 'left',
        field: 'factory_id',
        sortable: true
      },
      {
        name: 'kode_boking',
        label: 'Kode Booking',
        align: 'left',
        field: 'kode_boking',
        sortable: true,
      },
      {
        name: 'status_boking',
        label: 'Status Booking',
        align: 'left',
        field: 'status_boking',
        sortable: true,
      },
      {
        name: 'status_owner_approval',
        label: 'Status Approval Owner',
        align: 'left',
        field: 'status_owner_approval',
        sortable: true,
      },
    ]
    const layout = [{}]
    const filter = [      {
      type: 'reference',
      advanceFilter: true,
      col: 'col-6',
      name: 'factory_id',
      placeholder: 'Factory',
      label: 'Select Factory',
      reference: 'factory',
      option : {
        value: 'id',
        label : 'name'
      }
    },]

    return {
      collection,
      forms,
      columns,
      layout,
      filter
    }
  }