import {FC, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useLocation, Link, useNavigate} from 'react-router-dom'
import {ErrorMessage} from '@hookform/error-message'
import {useApi} from '../../../../hooks/useApi'
import {useFileCheck} from '../../../../hooks/useModelCheck'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Factory} from '../../../../models/factory'
import {Videos} from '../../../../models/videos'
import ClipLoader from 'react-spinners/ClipLoader'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const EditPage: FC = () => {
  const navigate = useNavigate()

  const [collectionName, setCollectionName] = useState<string>('')
  const [url, setUrl] = useState<any | null>('')
  const [fileTes, setFileTes] = useState('')
  const routeParams = useLocation()
  const [oldImg, setOldImg] = useState('')
  const routeUrl = routeParams.pathname.replace('/', '')
  const splitCollection = routeUrl.split('/')
  const route = splitCollection[0]
  const id = splitCollection[1]
  const {getModel} = useFileCheck()
  const {getOneById, update, getAll, create} = useApi()
  const MySwal = withReactContent(Swal)
  const collectionExist = getModel(`${route}`)
  const {layout} = collectionExist
  const [videoValue, setVideoValue] = useState('')
  const [dataVideo, setDataVideo] = useState<Videos[]>([])
  const [exist, setExist] = useState('Required')
  const [changed, setChanged] = useState(false)
  const [propVideo, setPropVideo] = useState({
    size: 0,
    type: '',
  })
  const [loading, setLoading] = useState(false)
  const [factory, setFactory] = useState<Factory[]>([])
  const [factorySelector, setFactorySelector] = useState<string>('')
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFactory = async () => {
    const response = await getAll<Factory>('factory')
    const {data} = response.data
    setFactory(data)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const getName = layout.map(function (layoutName: any) {
      const obj = {
        name: layoutName.name,
        type: layoutName.type,
      }
      return obj
    })
    getOneById(`${route}`, `${id}`).then((response) => {
      const {data}: any = response
       const urlGetVideo = API_URL + `/${data.path}/` + data.name
        setUrl(urlGetVideo)
      getName.forEach((field: any) => {
        if (field.type === 'date') {
          const date = new Date(data[field.name])
          let day = date.getDate()
          let month = ('0' + (date.getMonth() + 1)).slice(-2)
          let year = date.getFullYear()
          const currentDate = `${year}-${month}-${day}`
          Object.assign(data, {
            [field.name]: currentDate,
          })
        }
        const nameImg = data.name
        setOldImg(nameImg)
       
        setFactorySelector(data['factory_id'])
        setValue(field.name, data[field.name])
        setValue('factory_id', data['factory_id'])
        setVideoValue(data['name'])
      })
    })
    const renameCollection = () => {
      const words = route.split('_')
      const titles: string[] = []
      for (const key in words) {
        const word = words[key]
        titles.push(word.charAt(0).toUpperCase() + word.slice(1))
      }
      return titles.join(' ')
    }
    const getCurrentMaps = async () => {
      const response = await getOneById<Videos>(`${route}`, `${id}`)
      const {data} = response
      return data
    }
    const getDataVideo = async () => {
      const response = await getAll<Videos>('videos')
      const {data} = response.data
      const currentMaps = await getCurrentMaps()
      const dataFilter = data.filter((entry: Videos) => {
        return entry.factory_id !== currentMaps?.factory_id
      })

      setDataVideo(dataFilter)
      return dataFilter
    }
    setCollectionName(renameCollection())
    getFactory()
    getDataVideo()

    if (changed) {
      register('video', {
        required: true,
        validate: {
          lessThan10MB: (files) => propVideo.size < 100000000 || 'Max 100 mb',
          // acceptedFormats: (files) => ['video/mp4'].includes(propVideo.type) || 'Only MP4',
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, loading])
  const upload = async (payload: any) => {
    const response = await create(`video/upload?bucket=videoFactory`, payload)

    return response
  }
  const onSubmit = async (data: any, e: any) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('file', fileTes)
    const response = await upload(formData)
    if (response.data) {
      setLoading(false)

      const payload = {...response.data, factory_id: data.factory_id, old: oldImg}
      // saveVideo(payload)
      await update(`${route}`, id, payload)
        .then((response) => {
          MySwal.fire({
            title: <strong className='text-black'>Success</strong>,
            html: <i className='text-black'>Data Update!</i>,
            icon: 'success',
          })

          navigate(`/${route}`)
        })
        .catch((error) => {
          if (error.response) {
            const {data} = error.response
            MySwal.fire({
              title: <strong className='text-black'>Failed</strong>,
              html: <i className='text-black'>{data.errors[0].message}!</i>,
              icon: 'error',
            })
          }
        })
    }
  }
  const onError = (errors: any, e: any) => console.log(errors, e)
  const saveFile = (e: any) => {
    const data = e.target.files[0]

    if (data) {
      const propVid = {
        size: data.size,
        type: data.type,
      }
      setPropVideo(propVid)
    }
    const oFReader = new FileReader()
    oFReader.readAsDataURL(data)
    oFReader.onload = () => {
      const result = oFReader.result
      setUrl(() => result)
    }

    setValue('video', data)
    setChanged(true)
    setFileTes(data)
  }
  return (
    <div>
      {loading === true ? (
        <div className='loader'>
          <ClipLoader
            color={'#00008B'}
            loading={true}
            size={50}
            aria-label='Loading Spinner'
            data-testid='loader'
            // style={{}}
          />
        </div>
      ) : (
        ''
      )}
      <div className='card card-custom gutter-b'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>
              Edit {collectionName}
              <small></small>
            </h3>
          </div>
        </div>
        <div className='card-body'>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className='row'>
              <div className={`mb-4 col-6`}>
                <div className='form-group'>
                  <label className='text-black'>Video</label>
                  <label
                    htmlFor='videoFactory'
                    className='form-control form-control-solid'
                    style={{cursor: 'pointer'}}
                  >
                    {videoValue}
                  </label>

                  <input
                    id='videoFactory'
                    type='file'
                    onChange={saveFile}
                    accept='video/*'
                    hidden
                  />

                  <ErrorMessage errors={errors} name='video' />
                  <ErrorMessage
                    errors={errors}
                    name='video'
                    render={() => <p className='text-danger'>Video is required !</p>}
                  />
                </div>
              </div>
              <div className={`mb-4 col-6`}>
                <div className='form-group'>
                  <label className='text-black'>Factory Id</label>
                  <select
                    className='form-control form-control-solid'
                    {...register('factory_id', {
                      required: true,
                      validate: async (value, formValues) => {
                        const existingFactory: any = dataVideo.filter((entry: Videos) => {
                          return entry.factory_id === parseInt(value)
                        })
                        if (existingFactory.length !== 0) {
                          setExist('Existing')
                        }
                        return existingFactory.length === 0
                      },
                    })}
                  >
                    <>
                      {factory.map((entry: Factory) => {
                        return (
                          <option
                            key={entry.id}
                            className='form-control form-control-solid text-black'
                            id=''
                            value={entry.id}
                            selected={factorySelector === entry.id}
                          >
                            {entry.name}
                          </option>
                        )
                      })}
                    </>
                  </select>
                  <ErrorMessage errors={errors} name='factory_id' />
                  <ErrorMessage
                    errors={errors}
                    name='factory_id'
                    render={() => <p className='text-danger'>Factory is {exist} !</p>}
                  />
                </div>
              </div>
              <div className='col-6 my-5 text-center'>
                <>
                  {url !== '' ? (
                    <>
                      <video key={url} width='320' height='240' controls>
                        <source key={url} src={url} type='video/mp4' />
                      </video>
                    </>
                  ) : (
                    ''
                  )}
                </>
              </div>
            </div>
            <Link to={`/${route}`}>
              <button className='btn btn-secondary cancel'>Cancel </button>
            </Link>
            <button type='submit' className='btn btn-success'>
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
