export interface Visitor {
  id: string
  nama: string
  no_identitas: string
  type_identitas: string
  form_register: string
  safety_rules: string
  vidio_induction: string
  area_khusus: string
  kuisioner: string
  post_test: string
  expirate: string
  check_in: string
  bertemu_dengan: string
  perusahaan: string
  tujuan_bertemu: string
  tgl_kedatangan: string
  no_hp: string
  email: string
  factory_id: string
  type_visitor: string
  image: string
  kode_boking: string
  status_boking: string
  status_owner_approval: string
  lang?: string
}
export const useCollection = () => {
  const collection = 'visitor'
  const forms: Visitor = {
    id: '',
    nama: '',
    no_identitas: '',
    type_identitas: '',
    form_register: '',
    safety_rules: '',
    vidio_induction: '',
    area_khusus: '',
    kuisioner: '',
    post_test: '',
    expirate: '',
    check_in: '',
    bertemu_dengan: '',
    perusahaan: '',
    tujuan_bertemu: '',
    tgl_kedatangan: '',
    no_hp: '',
    email: '',
    factory_id: '',
    type_visitor: '',
    image: '',
    kode_boking: '',
    status_boking: '',
    status_owner_approval: '',
    lang: ''
  }

  const columns = [
    {
      name: 'nama',
      label: 'Name',
      align: 'left',
      field: 'nama',
      sortable: true,
    },
    {
      name: 'no_identitas',
      label: 'ID Number',
      align: 'left',
      field: 'no_identitas',
      sortable: true,
    },
    {
      name: 'type_visitor',
      label: 'Type Visitor',
      align: 'left',
      field: 'type_visitor',
      sortable: true,
    },
    {
      name: 'tgl_kedatangan',
      label: 'Tanggal Kedatangan',
      align: 'left',
      field: 'tgl_kedatangan',
      sortable: true,
    },
    {
      name: 'perusahaan',
      label: 'Perusahaan',
      align: 'left',
      field: 'perusahaan',
      sortable: true,
    },
    {
      name: 'bertemu_dengan',
      label: 'Bertemu Dengan',
      align: 'left',
      field: 'bertemu_dengan',
      sortable: true,
    },
    {
      name: 'factory_id',
      label: 'Factory',
      align: 'left',
      field: 'factory_id',
      sortable: true,
    },
    {
      name: 'kode_boking',
      label: 'Kode Booking',
      align: 'left',
      field: 'kode_boking',
      sortable: true,
    },
    {
      name: 'form_register',
      label: 'Form Register',
      align: 'left',
      field: 'form_register',
      sortable: true,
    },
    {
      name: 'safety_rules',
      label: 'Safety Rules',
      align: 'left',
      field: 'safety_rules',
      sortable: true,
    },
    {
      name: 'vidio_induction',
      label: 'Video Induction',
      align: 'left',
      field: 'vidio_induction',
      sortable: true,
    },
    {
      name: 'area_khusus',
      label: 'Area Khusus',
      align: 'left',
      field: 'area_khusus',
      sortable: true,
    },
    {
      name: 'kuisioner',
      label: 'Quisioner',
      align: 'left',
      field: 'kuisioner',
      sortable: true,
    },
    {
      name: 'post_test',
      label: 'Post Test',
      align: 'left',
      field: 'post_test',
      sortable: true,
    },
    {
      name: 'status_boking',
      label: 'Status Booking',
      align: 'left',
      field: 'status_boking',
      sortable: true,
    },
    {
      name: 'status_owner_approval',
      label: 'Status Approval Owner',
      align: 'left',
      field: 'status_owner_approval',
      sortable: true,
    },
    {
      name: 'expirate',
      label: 'Expirate',
      align: 'left',
      field: 'expirate',
      sortable: true,
    },
    {
      name: 'check_in',
      label: 'Submited Date',
      align: 'left',
      field: 'check_in',
      sortable: true,
    },
    {
      name: 'approval_date',
      label: 'Approval Date',
      align: 'left',
      field: 'approval_date',
      sortable: true,
    },
    {
      name: 'approval_owner_date',
      label: 'Approval Owner Date',
      align: 'left',
      field: 'approval_owner_date',
      sortable: true,
    },
  ]
  const layout = [
    {
      type: 'image',
      col: 'col-6',
      name: 'Image',
      placeholder: 'Upload Photo',
      note: '',
      label: 'Image',
      required: false,
      readonly:true,
    },
    {
      type: 'text',
      col: 'col-6',
      name: 'nama',
      placeholder: 'Name',
      label: 'Name',
      required: false,
      readonly:true,
    },
    {
      type: 'text',
      col: 'col-6',
      name: 'no_identitas',
      placeholder: 'Number ID',
      label: 'Number ID',
      required: false,
      readonly:true,
    },
    {
      type: 'text',
      col: 'col-6',
      name: 'kode_boking',
      placeholder: '',
      label: 'Kode Booking',
      required: false,
      readonly:true,
    },
    {
      type: 'text',
      col: 'col-6',
      name: 'type_identitas',
      placeholder: 'Type ID',
      label: 'Type ID',
      required: false,
      readonly:true,
    },
    {
      type: 'text',
      col: 'col-6',
      name: 'form_register',
      placeholder: 'Form Register',
      label: 'Form Register',
      required: false,
      readonly:true,
    },
    {
      type: 'text',
      col: 'col-6',
      name: 'safety_rules',
      placeholder: 'Safety Rules',
      label: 'Safety Rules',
      required: false,
      readonly:true,
    },
    {
      type: 'text',
      col: 'col-6',
      name: 'vidio_induction',
      placeholder: 'Video Induction',
      label: 'Video Induction',
      required: false,
      readonly:true,
    },
    {
      type: 'text',
      col: 'col-6',
      name: 'area_khusus',
      placeholder: 'Area Khusus',
      label: 'Area Khusus',
      required: false,
      readonly:true,
    },
    {
      type: 'text',
      col: 'col-6',
      name: 'izin-gadget',
      placeholder: 'Izin Gadget',
      label: 'Izin Gadget',
      required: false,
      readonly:true,
    },
    {
      type: 'textarea',
      col: 'col-6',
      name: 'keperluan',
      placeholder: 'Keperluan',
      label: 'Keperluan',
      required: false,
      readonly:true,
    },
    {
      type: 'text',
      col: 'col-6',
      name: 'kuisioner',
      placeholder: 'Kuisioner',
      label: 'Kuisioner',
      required: false,
      readonly:true,
    },
    {
      type: 'text',
      col: 'col-6',
      name: 'post_test',
      placeholder: 'Post Test',
      label: 'Post Test',
      required: false,
      readonly:true,
    },
    {
      type: 'date',
      col: 'col-6',
      name: 'expirate',
      placeholder: 'Expirate',
      label: 'Expirate',
      required: false,
      readonly:true,
    },
    {
      type: 'date',
      col: 'col-6',
      name: 'check_in',
      placeholder: 'Submited Date',
      label: 'Submited Date',
      required: false,
      readonly:true,
    },
    {
      type: 'text',
      col: 'col-6',
      name: 'bertemu_dengan',
      placeholder: 'Bertemu Dengan',
      label: 'Bertemu Dengan',
      required: false,
      readonly:true,
    },
    {
      type: 'text',
      col: 'col-6',
      name: 'perusahaan',
      placeholder: 'Perusahaan',
      label: 'Perusahaan',
      required: false,
      readonly:true,
    },
    {
      type: 'textarea',
      col: 'col-6',
      name: 'tujuan_bertemu',
      placeholder: 'Tujuan Bertemu',
      label: 'Tujuan Bertemu',
      required: false,
      readonly:true,
    },
    {
      type: 'date',
      col: 'col-6',
      name: 'tgl_kedatangan',
      placeholder: 'Arrive Date',
      label: 'Arrive Date',
      required: false,
      readonly:true,
    },
    {
      type: 'text',
      col: 'col-6',
      name: 'no_hp',
      placeholder: 'Phone No.',
      label: 'Phone No.',
      required: false,
      readonly:true,
    },
    {
      type: 'email',
      col: 'col-6',
      name: 'email',
      placeholder: 'Email',
      label: 'Email',
      required: false,
      readonly:true,
    },
    {
      type: 'text',
      col: 'col-6',
      name: 'type_visitor',
      placeholder: 'Type Visitor',
      label: 'Type Visitor',
      required: false,
      readonly:true,
    },
  ]
  const filter = [
    {
      type: 'reference',
      advanceFilter: true,
      col: 'col-4',
      name: 'factory_id',
      placeholder: 'Factory',
      label: 'Select Factory',
      reference: 'factory',
      option: {
        value: 'id',
        label: 'name',
      },
    },
    {
      type: 'select',
      advanceFilter: true,
      col: 'col-4',
      name: 'type_visitor',
      label: 'Type Visitor',
      options: [
        {
          label: 'Visitor',
          value: 'visitor',
        },
        {
          label: 'Kontraktor Rutin',
          value: 'kontraktor_rutin',
        },
        {
          label: 'Kontraktor Non Rutin',
          value: 'kontraktor_non_rutin',
        },
        {
          label: 'Driver',
          value: 'driver',
        },
      ],
    },
    {
      type: 'select',
      advanceFilter: true,
      col: 'col-4',
      name: 'status_boking',
      label: 'Status Booking',
      options: [
        {
          label: 'Approve',
          value: 'approve',
        },
        {
          label: 'Waiting',
          value: 'waiting',
        },
        {
          label: 'Reject',
          value: 'reject',
        }
      ],
    },
    {
      type: 'date',
      advanceFilter: true,
      col: 'col-4',
      name: 'expirate',
      placeholder: 'Expirate',
      label: 'Expirate',
    },
    {
      type: 'date',
      col: 'col-4',
      advanceFilter: true,
      name: 'check_in',
      placeholder: 'Submited Date',
      label: 'Submited Date',
    },
    {
      type: 'date',
      advanceFilter: true,
      col: 'col-4',
      name: 'tgl_kedatangan',
      placeholder: 'Arrive Date',
      label: 'Arrive Date',
    }
  ]

  return {
    collection,
    forms,
    columns,
    layout,
    filter,
  }
}
