export interface Post {
  id: string
  question: string
  status_show: string
  answer: number[]
  option: string[]
  choice_type:string
  efective_date_id:number
  question_en?: string
  answer_en?: number[]
  option_en?: string[]
}
export const useCollection = () => {
  const collection = 'posts'
  const forms: Post = {
    id: '',
    question: '',
    status_show: '',
    answer: [],
    option: [],
    choice_type:'',
    efective_date_id:0,
    question_en: '',
    answer_en: [],
    option_en: [],
  }

  const columns = [
    {
      name: 'question',
      required: true,
      label: 'Question',
      align: 'left',
      field: 'question',
      sortable: true
    },
    // Always give this columns as default
    {
      name: 'answer',
      required: true,
      label: 'Answer',
      align: 'left',
      field: 'answer',
      sortable: true
    },
    {
      name: 'status_show',
      required: true,
      label: 'status_show',
      align: 'left',
      field: 'status_show',
      sortable: true
    },
    {
      name: 'factory_id',
      required: true,
      label: 'factory_id',
      align: 'left',
      field: 'factory_id',
      sortable: true
    },
  ]
  const layout = [

      {
        type: 'select',
        col: 'col-6',
        name: 'status_show',
        label: 'Status show',
        options: [
          {
            label: 'Active',
            value: 'active'
          },
          {
            label: 'Inactive',
            value: 'inactive'
          }
        ]
      }
  ]
  const filter = [{
    type: 'reference',
    advanceFilter: true,
    col: 'col-6',
    name: 'factory_id',
    placeholder: 'Factory',
    label: 'Select Factory',
    reference: 'factory',
    option : {
      value: 'id',
      label : 'name'
    }
  }]

  return {
    collection,
    forms,
    columns,
    layout,
    filter
  }
}