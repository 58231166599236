import {FC, useEffect, useState} from 'react'
import illustrasi4 from '../../layout/img/page-15/img-other/reject.png'
import topRight from '../../layout/img/page-14/img-other/top-right.png'
import danone from '../../layout/img/Danone-Logo.png'
import {Helmet} from 'react-helmet'
const API_URL = process.env.REACT_APP_API_BASE_URL

const Reject: FC = () => {
  const [kode, setKode] = useState('')
  const [iconFactory, setIconFactory] = useState('')

  const kodeBoking = () => {
    const data: any = localStorage.getItem('kode')
    setKode(data)
  }
  const [billingual, setBillingual] = useState({
    title : 'Anda Dilarang Memasuki Perusahaan',
    desc : `Dengan berat hati kami menyampaikan bahwa, dari proses review hasil induction, anda belum memenuhi persyaratan yang telah kami tetapkan. 
    Sehingga dilarang memasuki area kami.`,
    kodeBoking: 'KODE BOOKING'
  })
  useEffect(() => {
    const getLocalLabel = localStorage.getItem('factory')
    if (getLocalLabel) {
      const parse = JSON.parse(getLocalLabel)
      const icon = `${API_URL}/maps/${parse.icon}`
      setIconFactory(icon)
    }
    const getLang = localStorage.getItem('language')
    if(getLang === 'en'){
      setBillingual ({
        title : 'You are not Allowed to Enter The Company.',
       desc: `We regret to inform you that the induction results do not meet our specified requirements.
       You are not permitted to enter our area.`,
        kodeBoking: 'BOOKING CODE'
      })
    }
    kodeBoking()
  }, [])
  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section className='d-flex flex-column align-items-center justify-content-center'>
        <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
          <img src={danone} alt='' width={220} />
          <img src={iconFactory} alt='' width={100} />
        </div>
        <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
        <div className='row gx-10 align-items-center'>
          <div className='col-12 col-md-6 text-center-sm'>
            <h1 className='fw-bold text-black d-none d-md-block'>
              {billingual.title}
            </h1>
            <p className='mt-10 d-none d-md-block'>
               {billingual.desc}
            </p>
            <div className='mt-10 d-none d-md-block'>
              <a href='home' className='btn btn-primary rounded-100 fw-semibold'>
                Back To Home
              </a>
            </div>
          </div>
          <div className='col-12 col-md-6 text-center'>
            <img src={illustrasi4} className='mb-2 w-100' alt='' />
            <div className='d-block d-md-none'>
              <h2>{billingual.title}</h2>
              <p>
                {billingual.desc}
              </p>
              <div className='text-center'>
                <h5>{billingual.kodeBoking}</h5>
                <input type='text' value={kode} className='form-control text-center' disabled />
              </div>
            </div>
            <div className='mt-10 d-block d-md-none'>
              <a href='status-check' className='btn btn-primary rounded-100 fw-semibold'>
                Done
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export {Reject}
