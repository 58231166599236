import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
// import {HeaderWrapper} from './components/header'
// import {ScrollTop} from './components/scroll-top'
// import {Content} from './components/content'
// import {Sidebar} from './components/sidebar'
import {
  // DrawerMessenger,
  // ActivityDrawer,
  // InviteUsers,
  // UpgradePlan,
  ThemeModeProvider,
} from '../_metronic/partials'
import {PageDataProvider} from '../_metronic/layout/core'
import {reInitMenu} from '../_metronic/helpers'
// import {ToolbarWrapper} from './components/toolbar'
// import {Footer} from './components/footer'

import './css/custom.css'

const AppLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <Outlet />
        {/* <Footer /> */}
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {AppLayout}
