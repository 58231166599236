import {
  ClassAttributes,
  ReactChild,
  ReactFragment,
  ReactPortal,
  TdHTMLAttributes,
  useEffect,
  useMemo,
} from 'react'
import {useTable} from 'react-table'
import clsx from 'clsx'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {ListPage} from './questionColumns/_columns'
import {ListLoading} from '../components/loading/ListLoading'
import {ListPagination} from '../components/pagination/ListPagination'
import {KTCardBody, initialQueryState} from '../../../_metronic/helpers'
import {ResourceHeader} from '../../../components/table/header/ResourceHeader'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd'
import {useAuth} from '../../auth'
import {useApi} from '../../../hooks/useApi'

const Table = () => {
  const response = useQueryResponseData()
  const {updateState} = useQueryRequest()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => response, [response])
  const {usersColumns} = ListPage()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => usersColumns, [])
  const queryParameters = new URLSearchParams(window.location.search)
  const efective_date = queryParameters.get('efective_date')
  const {getTableProps, getTableBodyProps, headers, prepareRow} = useTable({
    columns,
    data,
  })
  const {currentUser} = useAuth()
  let {rows} = useTable({
    columns,
    data,
  })
  const {create} = useApi()
  const updateOrder = async (payload: any) => {
    const response = await create('quizioners/updateOrder/', payload)
    return response
  }
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const updatedData = [...rows]
    const [draggedItem] = updatedData.splice(result.source.index, 1)
    updatedData.splice(result.destination.index, 0, draggedItem)
    rows = updatedData
    const order = updatedData.map((entry: any, index) => {
      const {original} = entry
      original.order = index
      return original
    })
    const payload = {
      data: order,
    }
    updateOrder(payload)
  }
  useEffect(() => {
    updateState({
      filter: {efective_date_id: efective_date},
      sort: {order: 1},
      auth: {authId: currentUser?.id, factory_id: currentUser?.factory_id, role: currentUser?.role},
      ...initialQueryState,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <KTCardBody className='py-4'>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: any) => (
                  <ResourceHeader key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <Droppable droppableId='table'>
              {(provided) => (
                <tbody
                  className='text-gray-600 fw-bold'
                  {...getTableBodyProps()}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {rows.length > 0 ? (
                    rows.map((row: any, i) => {
                      prepareRow(row)
                      return (
                        <Draggable
                          key={`row-${i}-${row.original.id}`}
                          draggableId={row.id.toString()}
                          index={i}
                        >
                          {(provided) => (
                            <tr
                              {...row.getRowProps()}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {row.cells.map(
                                (cell: {
                                  getCellProps: () => JSX.IntrinsicAttributes &
                                    ClassAttributes<HTMLTableDataCellElement> &
                                    TdHTMLAttributes<HTMLTableDataCellElement>
                                  column: {id: string}
                                  render: (
                                    arg0: string
                                  ) =>
                                    | boolean
                                    | ReactChild
                                    | ReactFragment
                                    | ReactPortal
                                    | null
                                    | undefined
                                }) => {
                                  return (
                                    <td
                                      {...cell.getCellProps()}
                                      className={clsx({
                                        'text-end min-w-100px': cell.column.id === 'actions',
                                      })}
                                    >
                                      {cell.render('Cell')}
                                    </td>
                                  )
                                }
                              )}
                            </tr>
                          )}
                        </Draggable>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center text-muted w-100 align-content-center justify-content-center'>
                          No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </table>
        </div>
      </DragDropContext>

      <ListPagination />
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export {Table}
