export interface UserDanoners {
    id:string
    name: string
    employee_id: string
    role: string
    contact: string
    division: string
    pension_time: string
    email:string
    factory_id:string
    photo:string
    old:string
    area_ids:string[]
    zone:string
  }
  export const useCollection = () => {
    const collection = 'user_danoners'
    const forms: UserDanoners = {
      id:'',
      name: '',
      employee_id: '',
      role: '',
      contact: '',
      division: '',
      pension_time: '',
      email:'',
      factory_id:'',
      photo:'',
      old:'',
      area_ids:[],
      zone:''
    }
  
    const columns = [
      {
        name: 'name',
        required: true,
        label: 'Name',
        align: 'left',
        field: 'name',
        sortable: true
      },
      {
        name: 'email',
        required: true,
        label: 'Email',
        align: 'left',
        field: 'email',
        sortable: true
      },
      // Always give this columns as default
      {
        name: 'employee_id',
        required: true,
        label: 'Employee ID',
        align: 'left',
        field: 'employee_id',
        sortable: true
      },
      {
        name: 'role',
        required: true,
        label: 'Role',
        align: 'left',
        field: 'role',
        sortable: true
      },
      {
        name: 'contact',
        label: 'Contact',
        align: 'left',
        field: 'contact',
        sortable: true
      },
      {
        name: 'division',
        label: 'Division',
        align: 'left',
        field: 'division',
        sortable: true
      },
      {
        name: 'pension_time',
        label: 'Pension Time',
        align: 'left',
        field: 'pension_time',
        sortable: true
      },
      {
        name: 'factory_id',
        required: true,
        label: 'Factory',
        align: 'left',
        field: 'factory_id',
        sortable: true
      },
    ]
    const layout = [
        {
          type: 'image',
          col: 'col-6',
          name: 'photo',
          placeholder: 'Upload Photo',
          note:'',
          label: 'Photo',
          required: false
        },
        {
          type: 'text',
          col: 'col-6',
          name: 'name',
          placeholder: 'Name',
          note:'',
          label: 'Name',
          required: true
        },
        {
          type: 'text',
          col: 'col-6',
          name: 'email',
          placeholder: 'Email',
          note:'',
          label: 'Email',
          required: true
        },
        {
          type: 'text',
          col: 'col-6',
          name: 'employee_id',
          note:'',
          placeholder: 'Employee ID',
          label: 'Employee ID',
          required: true
  
        },
        {
          type: 'text',
          col: 'col-6',
          name: 'role',
          note:'',
          placeholder: 'Role',
          label: 'Role',
          required: true
        },
        {
          type: 'text',
          col: 'col-6',
          name: 'contact',
          placeholder: 'Contact',
          label: 'Contact',
          required: true
        },
        {
          type: 'text',
          col: 'col-6',
          name: 'division',
          placeholder: 'Division',
          label: 'Division',
          required: true
        },
        {
          type: 'date',
          col: 'col-6',
          name: 'pension_time',
          note:'',
          placeholder: 'Pension Time',
          label: 'Pension Time',
          required: true
        },

        
    ]
    const filter = [{
      type: 'reference',
      advanceFilter: true,
      col: 'col-6',
      name: 'factory_id',
      placeholder: 'Factory',
      label: 'Select Factory',
      reference: 'factory',
      option : {
        value: 'id',
        label : 'name'
      }
    },
    {
      type: 'date',
      advanceFilter: true,
      col: 'col-6',
      name: 'pension_time',
      placeholder: 'Pension Time',
      label: 'Pension Time',
      required: true
    },
  ]

    return {
      collection,
      forms,
      columns,
      layout,
      filter
    }
  }
