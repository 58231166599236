import {FC, useEffect, useState} from 'react'
import topRight from '../../layout/img/page-10/img-other/top-right.png'
import ilustrasi2 from '../../layout/img/newImages/safety cardinal rule (1).png'
import ilustrasi3 from '../../layout/img/newImages/safety cardinal rule (2).png'
import sideRight from '../../layout/img/page-10/img-other/side-right.png'
import danone from '../../layout/img/Danone-Logo.png'
import {Footer} from '../../components/footer/Footer'
import {Helmet} from 'react-helmet'
const API_URL = process.env.REACT_APP_API_BASE_URL

const SafetyCardinalRules: FC = () => {
  const [labelFactory, setLabelFactory] = useState('')
  const [iconFactory, setIconFactory] = useState('')
  const [billingual, setBillingual] = useState({
    device :`Pengamanan mesin yang berbahaya : Dicover atau dikunci`,
    loto : 'Lakukan Lock In Tag Out (LOTO) untuk mengamankan dari energi berbahaya'
  })
  useEffect(() => {
    const getLocalLabel = localStorage.getItem('factory')
    if (getLocalLabel) {
      const parse = JSON.parse(getLocalLabel)
      const icon = `${API_URL}/maps/${parse.icon}`
      setIconFactory(icon)
      setLabelFactory(parse.factoryName)
    }
    const getLang = localStorage.getItem('language')
    if(getLang === 'en'){
      setBillingual ({
        device : `Dangerous machine protection: Covered or locked`,
        loto: 'Do a Lock in Tag Out (LOTO) to protect yourself from hazardous energy'
      })
    }
  }, [])
  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section className='d-flex flex-wrap align-items-center justify-content-center flex-column overflow-hidden'>
        <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
          <img src={danone} alt='' width={220} />
          <img src={iconFactory} alt='' width={100} />
        </div>
        <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
        <div className='row gx-10 align-items-center w-100'>
          <div className='col-12 col-md-6 text-center-sm'>
            <h2>{labelFactory} Factory</h2>
            <h1>SAFETY CARDINAL RULES</h1>
            <div className='d-md-flex d-none gap-3 mt-10'>
              <a href='zone' className='btn btn-secondary btn-md-back'>
                Back
              </a>
              <a href='safety-rules' className='btn btn-primary rounded-100 fw-semibold'>
                Next
              </a>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='px-4 text-center'>
              <div className='my-5 position-relative'>
                <h5 className='position-absolute top-0 start-50 translate-middle-x text-danger'>
                  Safety Devices
                </h5>
                <img src={ilustrasi2} alt='' style={{width: 211}} />
              </div>
              <p className='text-justify'>{billingual.device}</p>
            </div>
            <div className='px-4 text-center'>
              <div className='my-5 position-relative'>
                <h5 className='position-absolute top-0 start-50 translate-middle-x text-danger'>
                  Loto
                </h5>
                <img src={ilustrasi3} alt='' style={{width: 211}} />
              </div>
              <p>{billingual.loto}</p>
              <div className='mt-10 d-md-none d-block'>
                <a href='safety-rules' className='btn btn-primary rounded-100 fw-semibold'>
                  Next
                </a>
              </div>
            </div>
          </div>
        </div>
        <img src={sideRight} className='img-illustrasi-side-right' alt='' />
        <div className='button-back'>
          <a href='zone'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.87866 12L13.9393 3.93934L16.0606 6.06066L10.1213 12L16.0606 17.9393L13.9393 20.0607L5.87866 12Z'
                fill='#585858'
              />
            </svg>
          </a>
        </div>
      </section>
      <Footer />
    </>
  )
}

export {SafetyCardinalRules}
