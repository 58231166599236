import {KTSVG} from '../../../../_metronic/helpers'
import {ListFilter} from './ListFilter'
import {Link} from 'react-router-dom'

const ListToolbar = () => {
  const queryParameters = new URLSearchParams(window.location.search)
  const efective_date = queryParameters.get('efective_date')
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <ListFilter />

      <Link className='btn btn-primary' to={`create?efective_date=${efective_date}`}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        New
      </Link>

      {/* <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
      </button> */}
    </div>
  )
}

export {ListToolbar}
