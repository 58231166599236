import {Outlet, useLocation} from 'react-router-dom'
import {useFileCheck} from '../../hooks/useModelCheck'
import {Error404} from '../../modules/errors/components/Error404'
import {FC} from 'react'

export const ResourceWrapper: FC = () => {
  const location = useLocation();
  const routeParams = location.pathname.replace('/','')
  const {isModelExist, getModel} = useFileCheck()
  try {
    const routeUrl = routeParams.split("/");
    const collection = getModel(routeUrl[0]).collection
    if (collection) {
      const collectionExist = isModelExist(collection)

      if (collectionExist) {
        return (
          <>
            <Outlet />
          </>
        )
      }
    }
    return <Error404 />
  } catch (e) {
    return <Error404 />
  }
}
