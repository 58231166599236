// @ts-nocheck
import {Column} from 'react-table'
import {SelectionHeader} from './SelectionHeader'
import {SelectionCell} from './SelectionCell'
import {CustomHeader} from './CustomHeader'
import {ColumnString} from '../../../../components/table/column/ColumnString'
import {ActionsCell} from './ActionsCell'
import {useFileCheck} from '../../../../hooks/useModelCheck'
import {useLocation} from 'react-router-dom'
export const ListPage: FC = () => {
  const location = useLocation()
  const route = location.pathname.replace('/', '')
  const {getModel} = useFileCheck()
  const collectionExist = getModel(`${route}`)
  const {columns} = collectionExist
  const columntable = [
    {
      Header: (props) => <SelectionHeader tableProps={props} />,
      id: 'selection',
      Cell: ({...props}) => <SelectionCell id={props.data[props.row.index].id} />,
    },
  ]
  for (const key in columns) {
    columntable.push({
      Header: (props) => (
        <CustomHeader tableProps={props} title={columns[key].label} className='min-w-125px' />
      ),
      id: columns[key].name,
      Cell: ({...props}) => {
        const entry = props.data[props.row.index]
        let fill = <ColumnString value={entry[columns[key].name]} />
        if (columns[key].name === 'factory_id') {
          fill = <ColumnString value={entry.factoryName} />
        }
        return fill
      },
    })
  }
  columntable.push({
    Header: (props) => (
      <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell id={props.data[props.row.index].id} />,
  })
  const usersColumns: ReadonlyArray<Column<any>> = columntable
  return {
    usersColumns,
  }
}
