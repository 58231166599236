import {FC, useEffect, useState} from 'react'
import topRight from '../../layout/img/page-7/img-other/top-right.png'
import illustrasi from '../../layout/img/newImages/icon 3 - hygene rule 1.png'
import sideRight from '../../layout/img/page-9/img-other/side-right.png'
import danone from '../../layout/img/Danone-Logo.png'
import {Footer} from '../../components/footer/Footer'
import {Helmet} from 'react-helmet'
const API_URL = process.env.REACT_APP_API_BASE_URL

const Zone: FC = () => {
  const [labelFactory, setLabelFactory] = useState('')
  const [iconFactory, setIconFactory] = useState('')
  const [billingual, setBillingual] = useState({
    desc :`Zona didalam factory terbagi menjadi 3 area. High care,Medium Care dan Low Care.`,
   })
  useEffect(() => {
    const getLocalLabel = localStorage.getItem('factory')
    if (getLocalLabel) {
      const parse = JSON.parse(getLocalLabel)
      const icon = `${API_URL}/maps/${parse.icon}`
      setIconFactory(icon)
      setLabelFactory(parse.factoryName)
    }
    const getLang = localStorage.getItem('language')
    if(getLang === 'en'){
      setBillingual ({
        desc : `The zone inside the factory is divided into 3 areas. High care, Medium Care and Low Care`,
      })
    }
  }, [])
  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section className='d-flex flex-wrap align-items-center justify-content-center flex-column overflow-hidden'>
        <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
          <img src={danone} alt='' width={220} />
          <img src={iconFactory} alt='' width={100} />
        </div>
        <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
        <div className='row gx-10 align-items-center w-100'>
          <div className='col-12 col-md-6 text-center-sm'>
            <h2>{labelFactory} Factory</h2>
            <h1 className='text-uppercase'>ZONA</h1>
            <p>{billingual.desc}</p>
            <div className='d-md-flex d-none gap-3 mt-10'>
              <a href='hygiene-rules' className='btn btn-secondary btn-md-back'>
                Back
              </a>
              <a href='safety-cardinal-rules' className='btn btn-primary rounded-100 fw-semibold'>
                Next
              </a>
            </div>
          </div>
          <div className='col-12 col-md-6 text-center'>
            <img src={illustrasi} alt='' width='100%' />
            <p className='px-5'>
              {billingual.desc}
            </p>
            <div className='mt-10 d-md-none d-block'>
              <a href='safety-cardinal-rules' className='btn btn-primary rounded-100 fw-semibold'>
                Next
              </a>
            </div>
          </div>
        </div>
        <img src={sideRight} className='img-illustrasi-side-right d-block d-md-none' alt='' />
        <div className='button-back'>
          <a href='hygiene-rules'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.87866 12L13.9393 3.93934L16.0606 6.06066L10.1213 12L16.0606 17.9393L13.9393 20.0607L5.87866 12Z'
                fill='#585858'
              />
            </svg>
          </a>
        </div>
        <Footer />
      </section>
    </>
  )
}

export {Zone}
