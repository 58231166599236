import {ListFilter} from './ListFilter'

const ListToolbar = () => {
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <ListFilter />
      {/* <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
      </button> */}
    </div>
  )
}

export {ListToolbar}
