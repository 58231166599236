import {FC, useEffect, useState} from 'react'
import topRight from '../../layout/img/page-14/img-other/top-right.png'
import ilustrasi from '../../layout/img/page-14/img-other/illustrasi.png'
import sideRight from '../../layout/img/page-14/img-other/side-right.png'
import danone from '../../layout/img/Danone-Logo.png'
import {Footer} from '../../components/footer/Footer'
import {useNavigate} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {Helmet} from 'react-helmet'
import { useApi } from '../../hooks/useApi'
import { Visitor } from '../../models/visitor'
import { PublicUser } from '../../models/public_user'

const HOST = process.env.REACT_APP_HOST

const API_URL = process.env.REACT_APP_API_BASE_URL

const Area1: FC = () => {
  const navigate = useNavigate()
  let existingStorage = false
  const data = localStorage.getItem('area_khusus')
  if (data) {
    const lutfi = JSON.parse(data)
    existingStorage = JSON.parse(lutfi.area_khusus)
  }
  const [selected, setSelected] = useState(existingStorage)
  const getValue = (e: any) => {
    setSelected(e.target.value)
  }
  const [labelFactory, setLabelFactory] = useState('')
  const [iconFactory, setIconFactory] = useState('')
  const { create, getOneById } = useApi()
  const [email, setEmail] = useState({})
  const randomstring = require('randomstring')
  const [kode, setKode] = useState('')
  const kodeBoking = () => {
    const data = randomstring.generate({
      charset: 'alphanumeric',
      length: 5,
    })
     setKode(data)
    return data
  }
  const [billingual, setBillingual] = useState({
    title : 'AREA KHUSUS',
    subtitle: 'Apakah anda akan mengunjungi area khusus?',
    desc : 'Area khusus yang dimaksud adalah area produksi High Care Area/Medium Care Area/Low Care Area.',
    yesAnswer : `Ya ('Saya Ke Area Produksi')`,
    noAnswer : `Tidak ('Saya Ke Area Non-Produksi')`
  })
  useEffect(() => {
    const getLocalLabel = localStorage.getItem('factory')
    let visitor= localStorage.getItem('payloadVisitor')
     if(visitor){
       const parseVisitor = JSON.parse(visitor)
      getEmail(parseVisitor.bertemu_dengan)
     }
    if (getLocalLabel) {
      const parse = JSON.parse(getLocalLabel)
      const icon = `${API_URL}/maps/${parse.icon}`
      setIconFactory(icon)
      setLabelFactory(parse.factoryName)
    }
    const getLang = localStorage.getItem('language')
    if(getLang === 'en'){
      setBillingual ({
        title : 'SPECIFIC AREA',
        subtitle : 'Do you want to visit specific area?',
        desc : 'The Specific Area is production area which is divided into 3 areas, such as High Care Area / Medium Care Area/ Low Care Area.',
        yesAnswer : 'Yes (I will visit Production Area)',
        noAnswer : 'No (I will visit Non-Production Area'
      })
    }
    kodeBoking()
  }, [])
  const {handleSubmit} = useForm({})
  const onSubmit = async (data: any, e: any) => {
    const formArea = e.target['area'].value
    const result = {
      area_khusus: selected,
    }

    localStorage.setItem('area_khusus', JSON.stringify(result))
    if (formArea === 'true') {
      navigate('/area-khusus')
    }else{
      let checkIn
      let expirate
      let visIdBefore
      const localCheckIn = localStorage.getItem('check_in')
      const localExpirate = localStorage.getItem('expirate')
      const localVisIdBefore = localStorage.getItem('visitorIdBefore')
      if (localExpirate) {
        expirate = JSON.parse(localExpirate)
      }
      if (localCheckIn) {
        checkIn = JSON.parse(localCheckIn)
      }
      if (localVisIdBefore) {
        visIdBefore = JSON.parse(localVisIdBefore)
      }
      localStorage.setItem('kode', kode)
      let visitor= localStorage.getItem('payloadVisitor')
      let imageVisitor= localStorage.getItem('payloadImageVisitor')
     if(visitor){
       const parseVisitor = JSON.parse(visitor)
       parseVisitor.kode_boking = kode
       parseVisitor.check_in = checkIn
       parseVisitor.expirate = expirate
       parseVisitor.safety_rules = 1
       parseVisitor.visitorIdBefore = visIdBefore
      const response = await submitVisitor(parseVisitor)
      const saveQuestionGeneral = localStorage.getItem('kuisioner')
      const postTestLocal = localStorage.getItem('post_test')
      
      if (saveQuestionGeneral) {
        const general = JSON.parse(saveQuestionGeneral)
        general.data.map((entry: { user_id: number }) => {
          entry.user_id = response.id
          return entry
        })
        submitQuestion(general)
      }
      if (postTestLocal) {
        const postTestResult = JSON.parse(postTestLocal)
        postTestResult.visitor_answer.map((entry: { visitor_id: number }) => {
          entry.visitor_id = response.id
          return entry
        })
        submitPostVisitor(postTestResult)
      }
      const paramForPostExist = {
        visitorIdBefore : visIdBefore,
        visitorIdNew : response.id,

      }
      if(visIdBefore){
        savePostBaseIdVisitor(paramForPostExist)
      }

     }
      if(imageVisitor){
        saveImgae(JSON.parse(imageVisitor))
      }
      navigate('/hasil')
    }
  }
  const back = ()=> {
  localStorage.removeItem('area_khusus')
  navigate('/video')
  }
  const saveImgae = async (payload: {}) => {
    await create('quizioners/identity-image/', payload)
  }
  const getEmail = async (payload: any) => {
    const response = await getOneById<PublicUser>('public-user', payload)
    const data = response.data
    const payloadEmail = {
      userDanoners: data.email,
    }
    setEmail(payloadEmail)
  }
  const submitVisitor = async (payload: Visitor) => {
    return await create<Visitor>('visitor', payload).then((response) => {
      
      const { data } = response     
       if (data) {
        const result = {
          ...email,
          ...data,
          visitorId: data.id,
          emailVisitor: data.email,
          hostName: HOST,
        }
     
        const payloadWaiting = {
          kodeBooking: data.kode_boking,
          userVisitor: data.email,
          type: data.type_identitas,
          noIdentitas: data.no_identitas,
          hostFrontEnd: HOST,
          visitorName: data.nama,
        }
        try {
          if (data.status_boking !== 'reject') {
            mailToVisitor(payloadWaiting)
            mailTo(result)
          }
        } catch (error) {
          console.log(error)
        }
      }
      return data
    })
  }
  const submitQuestion = async (payload: any) => {
    await create('quizioners', payload)
  }
  const submitPostVisitor = async (formValue: {}) => {
    await create('post_visitors', formValue)
  }
  const mailTo = async (payload: {}) => {
    await create('visitor/mailTo/', payload)
  }
  const mailToVisitor = async (payload: {}) => {
    await create('visitor/mail_to_visitor/', payload)
  }
  const savePostBaseIdVisitor = async (IdVisitor: {}) => {
    const response = await create('post_visitors/detailVisitorPost/', IdVisitor)
    const {data} = response
    return data
  }
  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section>
        <div className='d-flex align-items-center justify-content-center flex-column h-100'>
          <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
            <img src={danone} alt='' width={220} />
            <img src={iconFactory} alt='' width={100} />
          </div>
          <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
          <div className='row gx-10 align-items-center w-100'>
            <div className='col-12 col-md-6 text-center-sm'>
              <h2>{labelFactory} Factory</h2>
              <h1 className='text-uppercase'>{billingual.title}</h1>
              <div className='d-md-flex d-none gap-3 mt-10'>
                <button onClick={back} className='btn btn-secondary btn-md-back'>
                  Back
                </button>
              </div>
            </div>
            <div className='col-12 col-md-6'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='text-center mb-4'>
                  <img src={ilustrasi} className='my-3 w-100' alt='' />
                  <p className='text-subtitle text-center'>
                    {billingual.subtitle}
                  </p>
                  <p className='px-5 my-10'>
                    {billingual.desc}
                  </p>
                  <div className='px-5'>
                    <div className='form-group'>
                      <select
                        onChange={getValue}
                        className='form-select'
                        aria-label='default select example'
                        name='area'
                      >
                        <option value='true' selected={selected === true}>
                          {billingual.yesAnswer}
                        </option>
                        <option value='false' selected={selected === false}>
                          {billingual.noAnswer}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='mt-10 text-center'>
                  <button type='submit' className='btn btn-primary rounded-100 fw-semibold'>
                    Next
                  </button>
                </div>

                <img src={sideRight} className='img-illustrasi-side-right' alt='' />
              </form>
            </div>
          </div>
        </div>
        <div className='button-back'>
          <a href='video'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.87866 12L13.9393 3.93934L16.0606 6.06066L10.1213 12L16.0606 17.9393L13.9393 20.0607L5.87866 12Z'
                fill='#585858'
              />
            </svg>
          </a>
        </div>
        <Footer />
      </section>
    </>
  )
}

export {Area1}
