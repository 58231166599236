import {FC, useEffect, useState} from 'react'
import shapePage4 from '../../layout/img/page-4/img-other/Shape-page4-1.png'
import pengunjung from '../../layout/img/newImages/169.png'
import danone from '../../layout/img/Danone-Logo.png'
import {Footer} from '../../components/footer/Footer'
import {Helmet} from 'react-helmet'
const API_URL = process.env.REACT_APP_API_BASE_URL

const VisitorNote: FC = () => {
  const [iconFactory, setIconFactory] = useState('')
  const [billingual, setBillingual] = useState({
    salam: 'Pengunjung Yang Terhormat',
    desc : `Melalui aplikasi ini kami bermaksud untuk menginformasikan terkait aturan safety dan
    hygiene perusahaan, demi menjaga keselamatan semua orang dan keamanan pangan produk
    kami.`
   })
  useEffect(() => {
    const getLocalLabel = localStorage.getItem('factory')
    if (getLocalLabel) {
      const parse = JSON.parse(getLocalLabel)
      const icon = `${API_URL}/maps/${parse.icon}`
      setIconFactory(icon)
    }
    const getLang = localStorage.getItem('language')
    if(getLang === 'en'){
      setBillingual ({
        salam : 'Dear Visitor',
        desc : `Through this application, we would like to inform you about company safety and hygiene regulations, to maintain
        everyone's safety and the food safety of our products.`
      })
    }
  }, [])
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="/visitor.css" />
      </Helmet>
      <section
        id='vms-page-4'
        className='d-flex flex-wrap align-items-center justify-content-center flex-column overflow-hidden'
      >
        <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
          <img src={danone} alt='' width={220} />
          <img src={iconFactory} alt='' width={100} />
        </div>
        <img src={shapePage4} className='w-100 shape-page-4 d-block d-md-none' alt='' />
        <div className='row gx-10 align-items-center'>
          <div className='col-12 col-md-6 text-center-sm'>
            <h2>Greeting</h2>
            <h1>{billingual.salam}</h1>
            <p className='d-none d-md-block'>
              {billingual.desc}
            </p>
            <div className='d-md-flex d-none gap-3'>
              <a href='welcome' className='btn btn-secondary btn-md-back'>
                Back
              </a>
              <a
                href='maps'
                className='btn btn-primary rounded-100 fw-semibold d-none d-md-inline-block'
              >
                Next
              </a>
            </div>
          </div>
          <div className='col-12 col-md-6 text-center'>
            <div className='text-center px-2 mb-2 mt-5'>
              <img src={pengunjung} className='my-4 w-100' alt='' />
            </div>
            <p className='text-center mb-5 px-5 d-block d-md-none'>
              {billingual.desc}
            </p>
            <div className='text-center mb-2 mt-10 d-inline-block d-md-none'>
              <a href='maps' className='btn btn-primary rounded-100 fw-semibold'>
                Next
              </a>
            </div>
          </div>
        </div>
        <div className='button-back d-block d-md-none'>
          <a href='welcome'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.87866 12L13.9393 3.93934L16.0606 6.06066L10.1213 12L16.0606 17.9393L13.9393 20.0607L5.87866 12Z'
                fill='#585858'
              />
            </svg>
          </a>
        </div>
        <Footer />
      </section>
    </>
  )
}

export {VisitorNote}
