import {useMemo,useEffect, useState} from 'react'
import {useTable} from 'react-table'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {ListPage} from './approvalsColumns/_columns'
import {ListLoading} from '../components/loading/ListLoading'
import {ListPagination} from '../components/pagination/ListPagination'
import {KTCardBody} from '../../../_metronic/helpers'
import {ResourceHeader} from '../../../components/table/header/ResourceHeader'
import {ResourceRow} from '../../../components/table/row/ResourceRow'
import {useQueryRequest} from '../../resources/core/QueryRequestProvider'
import {initialQueryState} from '../../../_metronic/helpers'
import { useAuth } from '../../auth'
import { useApi } from '../../../hooks/useApi'

const Table = () => {
  const {currentUser} = useAuth()
  const { getOneByParams } = useApi()
  const response = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const data = useMemo(() => response, [response])
  const {usersColumns} = ListPage()
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const params:any = {
    'email': currentUser?.email
  }
  async function fetchData() {
    const userDanone = getOneByParams<any>('user_danoners', params);
    const [result] = await Promise.all([userDanone])
    if(result.data.data.length > 0) {
      const resultId = result.data.data[0].id;
      updateState({
        filter: {status_boking: 'waiting', bertemu_dengan: resultId},
        auth: {authId: currentUser?.id,
                factory_id: currentUser?.factory_id,
                role: currentUser?.role,
              },
        sort: {
          id: -1,
        },
        ...initialQueryState,
      })
    }else{
      updateState({
        filter: {status_boking: 'waiting'},
        auth: {authId: currentUser?.id,
                factory_id: currentUser?.factory_id,
                role: currentUser?.role,
              },
        sort: {
          id: -1,
        },
        ...initialQueryState,
      })
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: any) => (
                <ResourceHeader key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: any, i) => {
                prepareRow(row)
                return <ResourceRow row={row} key={`row-${i}-${row.original.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-muted text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ListPagination />
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export {Table}
