import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useResourceContext} from '../../context/ResourceContext'
import {useFileCheck} from '../../../../hooks/useModelCheck'
import Form from 'react-bootstrap/Form'
import Select2 from '../fields/select2'
import Select from '../fields/select'
import DateInput from '../fields/date'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
const ListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const {collection} = useResourceContext()
  const {getModel} = useFileCheck()
  const collectionExist = getModel(collection)
  const {filter} = collectionExist
  const [form, setForm] = useState({})
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const handleForm = (name: string, value: string | number | boolean, type: string) => {
    if (type === 'date') {
      Object.assign(form, {
        [`${name}!gte`]: `${value} 00:00:00`,
        [`${name}!lte`]: `${value} 23:59:59`,
      })
      value = ''
    }
    setForm({...form, [name]: value})
  }

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
  }
  const filterData = () => {
    updateState({
      filter: {...form},
      ...initialQueryState,
    })
  }
  const MySwal = withReactContent(Swal)
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
  const apiVersion = process.env.REACT_APP_API_VERSION
  const url = `${apiBaseUrl}/${apiVersion}/visitor/null/export`
  const exportD = () => {
    // const apidocumentation = process.env.API_EXCEL_DOCUMENTATION_URL
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: `${url}`,
    }).click()
    MySwal.fire({
      title: <strong className='text-black'>Success</strong>,
      html: <i className='text-black'>Export Is Success</i>,
      icon: 'success',
    })
  }
  // const exportD = useMutation(() => exportData('visitor'), {
  //   onSuccess: () => {
  //     MySwal.fire({
  //       title: <strong className='text-black'>Success</strong>,
  //       html: <i className='text-black'>Export Is Success</i>,
  //       icon: 'success',
  //     })
  //   },
  // })
  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* <button className='btn btn-success me-3' onClick={async () => await exportD.mutateAsync()}> */}
      <button className='btn btn-success me-3' onClick={exportD}>
        Export
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-500px w-md-400px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Form Group */}

          <form>
            <div className='row'>
              {filter.map((fields: any, index: number) => {
                if (fields.advanceFilter) {
                  let typeForm = fields.type
                  switch (typeForm) {
                    case 'text':
                      return (
                        <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                          <Form.Label className='text-black' htmlFor='input-field'>
                            {fields.label}
                          </Form.Label>
                          <input
                            onChange={(e) => handleForm(fields.name, e.target.value, fields.type)}
                            type='text'
                            className='text-black form-control form-control-solid'
                            placeholder={fields.placeholder}
                          />
                          <Form.Text className='text-muted'>{fields.note}</Form.Text>
                        </div>
                      )
                    case 'textarea':
                      return (
                        <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                          <Form.Label className='text-black' htmlFor='input-field'>
                            {fields.label}
                          </Form.Label>
                          <textarea
                            onChange={(e) => handleForm(fields.name, e.target.value, fields.type)}
                            className='text-black form-control form-control-solid'
                            placeholder={fields.placeholder}
                          />
                          <Form.Text className='text-muted'>{fields.note}</Form.Text>
                        </div>
                      )
                    case 'select':
                      return (
                        <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                          <Select
                            fieldForm={fields}
                            onChange={(e) => handleForm(fields.name, e, fields.type)}
                          />
                        </div>
                      )
                    case 'date':
                      return (
                        <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                          <DateInput
                            fieldForm={fields}
                            onChange={(e) => handleForm(fields.name, e, fields.type)}
                          />
                        </div>
                      )
                    case 'email':
                      return (
                        <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                          <Form.Label className='text-black' htmlFor='input-field'>
                            {fields.label}
                          </Form.Label>
                          <input
                            onChange={(e) => handleForm(fields.name, e.target.value, fields.type)}
                            type='email'
                            className='text-black form-control form-control-solid'
                            placeholder={fields.placeholder}
                          />
                          <Form.Text className='text-muted'>{fields.note}</Form.Text>
                        </div>
                      )
                    case 'reference':
                      return (
                        <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                          <Select2
                            fieldForm={fields}
                            onChange={(e) => handleForm(fields.name, e, fields.type)}
                          />
                        </div>
                      )
                    default:
                      return filter
                  }
                }
              })}
            </div>
            {/* end::Form Group */}
          </form>
          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {ListFilter}
