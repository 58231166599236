/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import isometric from '../../layout/img/page-2/img-background/Map-isometric-2.png'
import chooseFactory from '../../layout/img/page-2/img-other/choose-factory.svg'
import danone from '../../layout/img/Danone-Logo.png'
import sentulIcon from '../../layout/img/page-2/img-other/sentul-icon.svg'
import ciracasIcon from '../../layout/img/page-2/img-other/ciracas-icon.svg'
import jogjaIcon from '../../layout/img/page-2/img-other/jogja-icon.svg'
import prambananIcon from '../../layout/img/page-2/img-other/prambanan-icon.svg'
import {useNavigate} from 'react-router-dom'
import {useApi} from '../../hooks/useApi'
import {MasterMaps} from '../../models/master_maps'
import {Helmet} from 'react-helmet'
import Alert from 'react-bootstrap/Alert'

const ChooseFactoryWrapper: FC = () => {
  const {getAll} = useApi()
  const navigate = useNavigate()
  const [alertFiil, setAlertFiil] = useState({
    show: false,
    text: '',
    color: '',
  })
  const redirect = async (e: any) => {
    const factoryId = {
      factory_id: e.target.dataset.value,
    }
    console.log(factoryId)
    const response = await getAll<MasterMaps>('master_maps', factoryId)
    const {data} = response.data
    // console.log('ini data', data)
    if (data.length !== 0) {
      localStorage.setItem('factory', JSON.stringify(data[0]))
      navigate('/identity')
    } else {
      setAlertFiil({
        show: true,
        text: 'Factory Maps Is Not Found',
        color: 'danger',
      })
    }
  }
  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section id='vms-page-2' className='full-section page overflow-hidden'>
        <img src={isometric} className='bg-img-page-2' alt='' />
        <Alert
          dismissible
          onClose={() => setAlertFiil({...alertFiil, show: false})}
          show={alertFiil.show}
          variant={alertFiil.color}
        >
          {alertFiil.text}
        </Alert>
        <div className='d-flex'>
          <div className='sponsor-wrapper-page-2 pb-sm-3'>
            <img src={danone} className='img-sponsor-page-1' alt='' />
          </div>
          <img src={chooseFactory} className='d-lg-none img-tap' alt='' />
          <div className='pin-factory'>
            <a onClick={redirect}>
              <img src={sentulIcon} className='img-sentul' data-value={2} alt='4' />
            </a>
            <a onClick={redirect}>
              <img src={ciracasIcon} className='img-ciracas' data-value={1} alt='3' />
            </a>
            <a onClick={redirect}>
              <img src={jogjaIcon} className='img-jogja' data-value={3} alt='2' />
            </a>
            <a onClick={redirect}>
              <img src={prambananIcon} className='img-prambanan usage' data-value={4} alt='1' />
            </a>
          </div>
          <div className='d-flex flex-column align-items-center d-none text-page-2 d-lg-block'>
            <div className=''>
              <h3 className='text-uppercase fw-bold text-sm-center mb-0 text-dark-gray'>
                Choose Your Factory
              </h3>
              <p className='text-uppercase fw-bolder text-dark-gray'>
                PILIH LOKASI PABRIK YANG ANDA KUNJUNGI
              </p>
            </div>
          </div>
        </div>
        <div className='button-back'>
          <a href='home'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.87866 12L13.9393 3.93934L16.0606 6.06066L10.1213 12L16.0606 17.9393L13.9393 20.0607L5.87866 12Z'
                fill='#585858'
              />
            </svg>
          </a>
        </div>
      </section>
    </>
  )
}

export {ChooseFactoryWrapper}
