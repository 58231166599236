import {FC, useEffect, useState} from 'react'
import illustrasi4 from '../../layout/img/page-15/img-other/illustrasi4.png'
import topRight from '../../layout/img/page-14/img-other/top-right.png'
import danone from '../../layout/img/Danone-Logo.png'
import prambanan from '../../layout/img/page-4/img-other/prambanan.png'
import {Helmet} from 'react-helmet'

const Hasil2: FC = () => {
  const [kode, setKode] = useState('')

  const kodeBoking = () => {
    const data: any = localStorage.getItem('kode')
    setKode(data)
  }
  useEffect(() => {
    kodeBoking()
  }, [])
  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section className='d-flex flex-column align-items-center '>
        <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
          <img src={danone} alt='' width={220} />
          <img src={prambanan} alt='' width={100} />
        </div>
        <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
        <div className='row gx-10 align-items-center'>
          <div className='col-12 col-md-6 text-center-sm d-none d-md-block'>
            <h1 className='fw-bold text-black'>Hasil Induction Telah Ditinjau</h1>
            <p className='mt-10 d-none d-md-block'>
              Silahkan ambil ID Card dan rompi di post security tunjukkan hasil induction ini ke
              post security Mohon patuhi aturan yang berlaku diperusahaan
            </p>
          </div>
          <div className='col-12 col-md-6 text-center-sm d-md-none'>
            <img src={illustrasi4} className='mb-2 w-100' alt='' />
            <h1 className='fw-bold text-blue text-uppercase'>Hasil Induction Telah Ditinjau</h1>
            <p className='mb-10'>
              Silahkan ambil ID Card dan rompi di post security tunjukkan hasil induction ini ke
              post security Mohon patuhi aturan yang berlaku diperusahaan
            </p>
          </div>
          <div className='col-12 col-md-6 text-center'>
            <img src={illustrasi4} className='mb-2 w-100 d-none d-md-block' alt='' />
            <div className='text-center'>
              <h5>KODE BOKING</h5>
              <input type='text' value={kode} className='form-control text-center' disabled />
            </div>
            <div className='mt-10'>
              <a href='status-check' className='btn btn-primary rounded-100 fw-semibold'>
                Back To Check
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export {Hasil2}
