import {FC, useEffect, useState} from 'react'
import topRight from '../../layout/img/page-7/img-other/top-right.png'
import buttonRight from '../../layout/img/page-7/img-other/botton-right.png'
import illustrasi4 from '../../layout/img/newImages/hygene rule 4.png'
import illustrasi from '../../layout/img/newImages/hygene rule 1.png'
import illustrasi2 from '../../layout/img/newImages/hygene rule 2.png'
import illustrasi3 from '../../layout/img/newImages/hygene rule 3.png'
import illustrasi5 from '../../layout/img/newImages/hygene rule 5.png'
import illustrasi6 from '../../layout/img/newImages/hygene rule 6.png'
import danone from '../../layout/img/Danone-Logo.png'
import {Footer} from '../../components/footer/Footer'
import {Helmet} from 'react-helmet'
const API_URL = process.env.REACT_APP_API_BASE_URL

const HygieneRules: FC = () => {
  const [labelFactory, setLabelFactory] = useState('')
  const [iconFactory, setIconFactory] = useState('')
  const [billingual, setBillingual] = useState({
    desc: `Melalui aplikasi ini kami bermaksud untuk menginformasikan terkait aturan safety dan
    hygiene perusahaan, demi menjaga keselamatan semua orang dan keamanan pangan produk
    kami.`,
    geserSlide: 'Tekan Untuk Geser',
    noFood: 'Visitor dilarang membawa bahan makanan dan minuman yang mengandung alergen.',
    noSpit:
      'Visitor dilarang untuk meludah sembarangan diseluruh area pabrik kecuali didalam fasilitas toilet.',
    noTrash: 'Visitor wajib membuang sampah pada tempatnya.',
    noJewel:
      'Visitor dilarang menggunakan perhiasan maupun aksesoris sejenis diseluruh area produksi.',
    hygiene:
      'Visitor wajib mematuhi safety dan hygiene rules selama berada didalam area perusahaan.',
  })
  useEffect(() => {
    const getLocalLabel = localStorage.getItem('factory')
    if (getLocalLabel) {
      const parse = JSON.parse(getLocalLabel)
      const icon = `${API_URL}/maps/${parse.icon}`
      setIconFactory(icon)
      setLabelFactory(parse.factoryName)
    }
    const getLang = localStorage.getItem('language')
    if (getLang === 'en') {
      setBillingual({
        desc: `Through this application, we would like to inform you regarding our company safety and hygiene rules, 
        to maintain the safety of everyone and the food safety of our products.`,
        geserSlide: 'Press to slide',
        noFood:
          'Visitors are prohibited from bringing food and drink ingredients that contain allergents.',
        noSpit:
          'Visitors are prohibited from spitting anywhere in the factory area except in the toilet facilities.',
        noTrash: 'Visitors must dispose of trash in its place.',
        noJewel:
          'Visitors area prohibited from using jewelry or similar accessories in all production areas.',
        hygiene:
          'Visitors are required to adhere to all the safety and hygiene regulations while in the company area.',
      })
    }
  }, [])
  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section
        className='d-flex flex-wrap align-items-center justify-content-center flex-column'
        style={{overflow: 'inherit'}}
      >
        <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
          <img src={danone} alt='' width={220} />
          <img src={iconFactory} alt='' width={100} />
        </div>
        <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
        <div className='row gx-10 align-items-center w-100'>
          <div className='col-12 col-md-6 text-center-sm'>
            <h2>{labelFactory} Factory</h2>
            <h1>HYGIENE RULES</h1>
            <p className='d-none d-md-block'>{billingual.desc}</p>
            <a href='maps' className='btn btn-secondary btn-md-back mt-10'>
              Back
            </a>
          </div>
          <div className='col-12 col-md-6'>
            <div
              id='carouselExampleIndicators'
              className='carousel slide carousel-hygiene'
              data-bs-ride='true'
              data-bs-wrap='false'
            >
              {/* <div className='carousel-indicators'>
                <button
                  type='button'
                  data-bs-target='#carouselExampleIndicators'
                  data-bs-slide-to='0'
                  className='active'
                  aria-current='true'
                  aria-label='Slide 1'
                ></button>
                <button
                  type='button'
                  data-bs-target='#carouselExampleIndicators'
                  data-bs-slide-to='1'
                  aria-label='Slide 2'
                ></button>
                <button
                  type='button'
                  data-bs-target='#carouselExampleIndicators'
                  data-bs-slide-to='2'
                  aria-label='Slide 3'
                ></button>
                <button
                  type='button'
                  data-bs-target='#carouselExampleIndicators'
                  data-bs-slide-to='3'
                  aria-label='Slide 4'
                ></button>
                <button
                  type='button'
                  data-bs-target='#carouselExampleIndicators'
                  data-bs-slide-to='4'
                  aria-label='Slide 5'
                ></button>
                <button
                  type='button'
                  data-bs-target='#carouselExampleIndicators'
                  data-bs-slide-to='5'
                  aria-label='Slide 6'
                ></button>
              </div> */}
              <div className='carousel-inner'>
                <div className='carousel-item text-center active'>
                  <img src={illustrasi} alt='' width='100%' />
                  <p className='px-4 mb-5 min-h-80px'>&nbsp;</p>
                </div>
                <div className='carousel-item text-center'>
                  <img src={illustrasi6} alt='' width='100%' />
                  <p className='px-4 mb-5 min-h-80px'>{billingual.noFood}</p>
                </div>
                <div className='carousel-item text-center'>
                  <img src={illustrasi2} alt='' width='100%' />
                  <p className='px-4 mb-5 min-h-80px'>{billingual.noSpit}</p>
                </div>
                <div className='carousel-item text-center'>
                  <img src={illustrasi3} alt='' width='100%' />
                  <p className='px-4 mb-5 min-h-80px'>{billingual.noTrash}</p>
                </div>
                <div className='carousel-item text-center'>
                  <img src={illustrasi4} alt='' width='100%' />
                  <p className='px-4 mb-5 min-h-80px'>{billingual.noJewel}</p>
                </div>
                <div className='carousel-item text-center'>
                  <img src={illustrasi5} alt='' width='100%' />
                  <p className='px-4 mb-5 min-h-80px'>{billingual.hygiene}</p>
                  <div className='mt-10'>
                    <a href='zone' className='btn btn-primary rounded-100 fw-semibold'>
                      Next
                    </a>
                  </div>
                </div>
              </div>
              <button
                className='carousel-control-prev'
                type='button'
                data-bs-target='#'
                data-bs-slide='#'
              >
                <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                <span className='visually-hidden'>Previous</span>
              </button>
              <button
                className='carousel-control-next ms-1'
                type='button'
                data-bs-target='#carouselExampleIndicators'
                data-bs-slide='next'
              >
                <div className='d-flex bg-white nav-carousel p-4 border'>
                  <div className='text-nowrap d-md-none'>Geser</div>
                  <div className='text-nowrap d-none d-md-block'>{billingual.geserSlide}</div>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z'
                      fill='#000000'
                      transform='translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) '
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
        <img src={buttonRight} className='img-illustrasi-side-right d-block d-md-none' alt='' />
        <div className='button-back'>
          <a href='maps'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.87866 12L13.9393 3.93934L16.0606 6.06066L10.1213 12L16.0606 17.9393L13.9393 20.0607L5.87866 12Z'
                fill='#585858'
              />
            </svg>
          </a>
        </div>
        <Footer />
      </section>
    </>
  )
}

export {HygieneRules}
