import {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../../_metronic/helpers'
import { useApi } from '../../../hooks/useApi'
import { Approvals } from '../../../models/approvals'
import { useAuth } from '../../auth';
export interface ApprovalCountContextModel {
  count: number
  updateCount: (count: number) => void
  children?: React.ReactNode
}

export const state: ApprovalCountContextModel = {
  count: 0,
  updateCount: () => {},
}

const ApprovalCountContext = createContext<ApprovalCountContextModel>(state)

const ApprovalCountProvider: FC<WithChildren> = ({children}) => {
  const {getAll} = useApi()
  const {currentUser}= useAuth()
  const [count, setApprovalCount] = useState<number>(0)
  const visitor = async () => {
    const payload: {} = {
      status_boking: 'waiting',
      [`!auth[authId]`]: currentUser?.id,
      [`!auth[factory_id]`]: currentUser?.factory_id,
      [`!auth[role]`]: currentUser?.role,
    }
    const response = await getAll<Approvals>('visitor',payload)
    const {count} = response.data
      setApprovalCount(count);
  }
  const updateCount = (newCount: number) => {
    setApprovalCount(newCount)
  }
  useEffect(() => {
    visitor()
  }, [])
  return (
    <ApprovalCountContext.Provider
      value={{
        count,
        updateCount,
      }}
    >
      {children}
    </ApprovalCountContext.Provider>
  )
}

const useApprovalCountContext = () => useContext(ApprovalCountContext)

export {ApprovalCountProvider, useApprovalCountContext}
