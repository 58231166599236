import {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../../_metronic/helpers'
import { useApi } from '../../../hooks/useApi'
import { Approvals } from '../../../models/approvals'
import { useAuth } from '../../auth';
export interface ApprovalOwnerCountContextModel {
  countOwner: number
  updateCountOwner: (count: number) => void
  children?: React.ReactNode
}

export const state: ApprovalOwnerCountContextModel = {
  countOwner: 0,
  updateCountOwner: () => {},
}

const ApprovalOwnerCountContext = createContext<ApprovalOwnerCountContextModel>(state)

const ApprovalOwnerCountProvider: FC<WithChildren> = ({children}) => {
  const {getAll} = useApi()
  const {currentUser}= useAuth()
  const [countOwner, setApprovalOwnerCount] = useState<number>(0)
  const visitor = async () => {
    const payload: {} = {
      status_owner_approval: 'waiting',
      [`!auth[authId]`]: currentUser?.id,
      [`!auth[factory_id]`]: currentUser?.factory_id,
      [`!auth[role]`]: currentUser?.role,
    }
    const response = await getAll<Approvals>('visitor',payload)
    const {count} = response.data
      setApprovalOwnerCount(count);
  }
  const updateCountOwner = (newCount: number) => {
    setApprovalOwnerCount(newCount)
  }
  useEffect(() => {
    visitor()
  }, [])
  return (
    <ApprovalOwnerCountContext.Provider
      value={{
        countOwner,
        updateCountOwner,
      }}
    >
      {children}
    </ApprovalOwnerCountContext.Provider>
  )
}

const useApprovalOwnerCountContext = () => useContext(ApprovalOwnerCountContext)

export {ApprovalOwnerCountProvider, useApprovalOwnerCountContext}
