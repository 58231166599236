/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100 page-login bg-login-auth'>
    {/* begin::Body */}
    <div className='d-flex flex-column align-items-center justify-content-between flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
      {/* begin::Form */}
      <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
        {/* begin::Wrapper */}
        <div className='w-400px px-15 py-15 bg-white' style={{borderRadius: "60px"}}>
         <img src={toAbsoluteUrl('/image-danone/Danone-Emblem.png')} alt="" className="logo-login w-300px" />
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Form */}

      {/* begin::Footer */}
      <div className='d-flex flex-column flex-center flex-wrap px-5'>
        <small className="text-white text-center">Copyright © 2023 VMS, All Right Reserved.</small>
      </div>
      {/* end::Footer */}
    </div>
    {/* end::Body */}
  </div>

  )
}

export {AuthLayout}
