import {KTSVG} from '../../../../_metronic/helpers'
import {ListFilter} from './ListFilter'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const ListToolbar = () => {
  // const MySwal = withReactContent(Swal)
  // const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
  // const apiVersion = process.env.REACT_APP_API_VERSION
  // const url = `${apiBaseUrl}/${apiVersion}/user_danoners/null/export`

  // const exportD = () => {
  //   // const apidocumentation = process.env.API_EXCEL_DOCUMENTATION_URL
  //   Object.assign(document.createElement('a'), {
  //     target: '_blank',
  //     href: `${url}`,
  //   }).click()
  //   MySwal.fire({
  //     title: <strong className='text-black'>Success</strong>,
  //     html: <i className='text-black'>Export Is Success</i>,
  //     icon: 'success',
  //   })
  // }
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <ListFilter />

      <Link className='btn btn-primary' to={`create`}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        New
      </Link>
      {/* <h4 className='btn btn-success mx-2' onClick={exportD}>
        export
      </h4> */}

      {/* <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
      </button> */}
    </div>
  )
}

export {ListToolbar}
