import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../_metronic/layout/MasterLayout'
import {AppLayout} from '../layout/AppLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {HomeWrapper} from '../pages/web/HomeWrapper'
import {ChooseFactoryWrapper} from '../pages/web/ChooseFactoryWrapper'
import {IdentityWrapper} from '../pages/web/IdentityWrapper'
import {PostTest} from '../pages/web/PostTest'
import {FormRegister} from '../pages/web/formRegister'
import {FormArea} from '../pages/web/formArea'
import {AreaKhusus} from '../pages/web/areaKhusus'
import {StatusCheck} from '../pages/web/statusCheck'
import {Welcome} from '../pages/web/welcome'
import {VisitorNote} from '../pages/web/visitor-note'
import {Maps} from '../pages/web/maps'
import {HygieneRules} from '../pages/web/hygiene-rules'
import {Zone} from '../pages/web/zone'
import {SafetyCardinalRules} from '../pages/web/safetyCardinalRules'
import {SafetyRules} from '../pages/web/safetyRules'
import {VisitorAnnounctment} from '../pages/web/vistorAnnouctment'
import {Emergency} from '../pages/web/emergency'
import {Vidio} from '../pages/web/vidio'
import {Area1} from '../pages/web/area-1'
import {Hasil} from '../pages/web/hasil'
import {Hasil2} from '../pages/web/hasil-2'
import {Quesioner} from '../pages/web/quesioner'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../_metronic/assets/ts/_utils'
import {WithChildren} from '../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {IzinGadget} from '../pages/web/izinGadget'
import {Reject} from '../pages/web/reject'

import {ResourceWrapper} from '../pages/resources/ResourceWrapper'
import LiveDashboard from '../pages/resources/liveDashboard/index'
import {ListWrapper as QuestionListWrapper} from '../pages/resources/master_question/list/ListWrapper'
import {ListWrapper as EfectiveDateQuestionsWrapper} from '../pages/efectiveDate/pages/list/ListWrapper'
import {ListWrapper as AreasListWrapper} from '../pages/areas/pages/list/ListWrapper'
import {ListWrapper as FactoryListWrapper} from '../pages/factory/pages/list/ListWrapper'
import {ListWrapper as VideosListWrapper} from '../pages/videos/pages/list/ListWrapper'
import {ListWrapper as MapsListWrapper} from '../pages/master_maps/pages/list/ListWrapper'
import {ListWrapper as VisitorListWrapper} from '../pages/visitor/pages/list/ListWrapper'
import {EditWrapper as VisitorEditWrapper} from '../pages/visitor/pages/edit/EditWrapper'

import {ListWrapper as ApprovalListsWrapper} from '../pages/resources/approvals/list/ListWrapper'
import {ListWrapper as ApprovalOwnerListsWrapper} from '../pages/resources/approvalOwner/list/ListWrapper'
import ReadWrapper from '../pages/resources/pages/read/ReadWrapper'
//Posts
import {ListWrapper as PostListWrapper} from '../pages/resources/posts/list/ListWrapper'
import {EditWrapper as PostEditWrapper} from '../pages/resources/posts/edit/EditWrapper'
import {PostCreateWrapper} from '../pages/resources/posts/create/CreateWrapper'
//Master Question
import {QuestionCreateWrapper} from '../pages/resources/master_question/create/CreateWrapper'
import {QuestionEditWrapper} from '../pages/resources/master_question/edit/EditWrapper'
//Master EfectiveDateWrapper
import {EfectiveDateWrapper} from '../pages/efectiveDate/pages/create/CreateWrapper'
import {EfectiveDateEditWrapper} from '../pages/efectiveDate/pages/edit/EditWrapper'

import {AreasCreate} from '../pages/areas/pages/create/CreateWrapper'
import {AreasEdit} from '../pages/areas/pages/edit/EditWrapper'

import {FactoryCreate} from '../pages/factory/pages/create/CreateWrapper'
import {FactoryEdit} from '../pages/factory/pages/edit/EditWrapper'
//User Danoners
//Videos
import {VideosUpload} from '../pages/videos/pages/create/CreateWrapper'
import {VideoEdit} from '../pages/videos/pages/edit/EditWrapper'

// Maps
import {MapsCreate} from '../pages/master_maps/pages/create/CreateWrapper'
import {MapsEdit} from '../pages/master_maps/pages/edit/EditWrapper'

import {ListWrapper as UserDanonersListWrapper} from '../pages/user_danoners/pages/list/ListWrapper'
import {CreateWrapper as UserDanonersCreateWrapper} from '../pages/user_danoners/pages/create/CreateWrapper'
import {EditWrapper as UserDanonersEditWrapper} from '../pages/user_danoners/pages/edit/EditWrapper'

//Master Range
import {ListWrapper as MasterRangeListWrapper} from '../pages/master_range/pages/list/ListWrapper'
import {CreateWrapper as MasterRangeCreateWrapper} from '../pages/master_range/pages/create/CreateWrapper'
import {EditWrapper as MasterRangeEditWrapper} from '../pages/master_range/pages/edit/EditWrapper'
const PrivateRoutes = () => {
  const UserPage = lazy(() => import('../pages/user/router/IndexRoutes'))
  const SysparamPage = lazy(() => import('../pages/sysparam/router/IndexRoutes'))
  const RolePage = lazy(() => import('../pages/role/router/IndexRoutes'))
  const PrivilegePage = lazy(() => import('../pages/privileges/router/IndexRoutes'))

  return (
    <Routes>
      <>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route element={<MasterLayout />}>
          <Route path='dashboard' element={<LiveDashboard />} />
          <Route path='builder' element={<BuilderPageWrapper />} />
          <Route path='menu-test' element={<MenuTestPage />} />
          {/* Lazy Modules */}
          <Route
            path='user/*'
            element={
              <SuspensedView>
                <UserPage />
              </SuspensedView>
            }
          />
          <Route
            path='sysparam/*'
            element={
              <SuspensedView>
                <SysparamPage />
              </SuspensedView>
            }
          />
          <Route
            path='role/*'
            element={
              <SuspensedView>
                <RolePage />
              </SuspensedView>
            }
          />
          <Route
            path='privileges/*'
            element={
              <SuspensedView>
                <PrivilegePage />
              </SuspensedView>
            }
          />
          <Route path='posts' element={<ResourceWrapper />}>
            <Route index element={<PostListWrapper />} />
            <Route path='create' element={<PostCreateWrapper />} />
            <Route path=':id'>
              <Route index element={<ReadWrapper />} />
              <Route path='edit' element={<PostEditWrapper />} />
            </Route>
          </Route>
          <Route path='master_range' element={<ResourceWrapper />}>
            <Route index element={<MasterRangeListWrapper />} />
            <Route path='create' element={<MasterRangeCreateWrapper />} />
            <Route path=':id'>
              <Route index element={<ReadWrapper />} />
              <Route path='edit' element={<MasterRangeEditWrapper />} />
            </Route>
          </Route>
          <Route path='efectiveDate' element={<ResourceWrapper />}>
            <Route index element={<EfectiveDateQuestionsWrapper />} />
            <Route path='create' element={<EfectiveDateWrapper />} />
            <Route path=':id'>
              <Route index element={<ReadWrapper />} />
              <Route path='edit' element={<EfectiveDateEditWrapper />} />
            </Route>
          </Route>
          <Route path='master_question' element={<ResourceWrapper />}>
            <Route index element={<QuestionListWrapper />} />
            <Route path='create' element={<QuestionCreateWrapper />} />
            <Route path=':id'>
              <Route path='edit' element={<QuestionEditWrapper />} />
            </Route>
          </Route>
          <Route path='areas' element={<ResourceWrapper />}>
            <Route index element={<AreasListWrapper />} />{' '}
            <Route path='create' element={<AreasCreate />} />
            <Route path=':id'>
              <Route path='edit' element={<AreasEdit />} />
            </Route>
          </Route>
          <Route path='factory' element={<ResourceWrapper />}>
            <Route index element={<FactoryListWrapper />} />{' '}
            <Route path='create' element={<FactoryCreate />} />
            <Route path=':id'>
              <Route path='edit' element={<FactoryEdit />} />
            </Route>
          </Route>
          <Route path='videos' element={<ResourceWrapper />}>
            <Route index element={<VideosListWrapper />} />
            <Route path='create' element={<VideosUpload />} />
            <Route path=':id'>
              <Route path='edit' element={<VideoEdit />} />
            </Route>
          </Route>
          <Route path='master_maps' element={<ResourceWrapper />}>
            <Route index element={<MapsListWrapper />} />
            <Route path='create' element={<MapsCreate />} />
            <Route path=':id'>
              <Route path='edit' element={<MapsEdit />} />
            </Route>
          </Route>
          <Route path='user_danoners' element={<ResourceWrapper />}>
            <Route index element={<UserDanonersListWrapper />} />
            <Route path='create' element={<UserDanonersCreateWrapper />} />
            <Route path=':id'>
              <Route path='edit' element={<UserDanonersEditWrapper />} />
            </Route>
          </Route>
          <Route path='visitor' element={<ResourceWrapper />}>
            <Route index element={<VisitorListWrapper />} />
            <Route path=':id'>
              <Route path='edit' element={<VisitorEditWrapper />} />
            </Route>
          </Route>
          <Route path='approvals' element={<ResourceWrapper />}>
            <Route index element={<ApprovalListsWrapper />} />
          </Route>
          <Route path='approvalOwner' element={<ResourceWrapper />}>
            <Route index element={<ApprovalOwnerListsWrapper />} />
          </Route>
          <Route path='videos' element={<ResourceWrapper />}>
            <Route index element={<VideosListWrapper />} />
          </Route>
        </Route>
      </>

      <Route element={<AppLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {/* Pages */}
        <Route path='home' element={<HomeWrapper />} />
        <Route path='choose-factory' element={<ChooseFactoryWrapper />} />
        <Route path='identity' element={<IdentityWrapper />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='post-test' element={<PostTest />} />
        <Route path='form-register' element={<FormRegister />} />
        <Route path='hygiene-rules' element={<HygieneRules />} />
        <Route path='zone' element={<Zone />} />
        <Route path='safety-cardinal-rules' element={<SafetyCardinalRules />} />
        <Route path='safety-rules' element={<SafetyRules />} />
        <Route path='vistor-annouctment' element={<VisitorAnnounctment />} />
        <Route path='emergency' element={<Emergency />} />
        <Route path='video' element={<Vidio />} />
        <Route path='area-1' element={<Area1 />} />
        <Route path='quesioner' element={<Quesioner />} />
        <Route path='welcome' element={<Welcome />} />
        <Route path='visitor-note' element={<VisitorNote />} />
        <Route path='maps' element={<Maps />} />
        <Route path='hasil' element={<Hasil />} />
        <Route path='hasil-2' element={<Hasil2 />} />
        <Route path='form-area' element={<FormArea />} />
        <Route path='area-khusus' element={<AreaKhusus />} />
        <Route path='status-check' element={<StatusCheck />} />
        <Route path='izin-gadget' element={<IzinGadget />} />
        <Route path='reject' element={<Reject />} />
        {/* Lazy Modules */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
