import {FC, useEffect, useState} from 'react'
import topRight from '../../layout/img/page-14/img-other/top-right.png'
import danone from '../../layout/img/Danone-Logo.png'
import {Footer} from '../../components/footer/Footer'
import {useApi} from '../../hooks/useApi'
import {MasterQuestion} from '../../models/master_question'
import {useNavigate} from 'react-router-dom'
import Alert from 'react-bootstrap/Alert'
import {Helmet} from 'react-helmet'
import {efectiveDate} from '../../models/efectiveDate'
import {Visitor} from '../../models/visitor'
import { PublicUser } from '../../models/public_user'

const API_URL = process.env.REACT_APP_API_BASE_URL
const HOST = process.env.REACT_APP_HOST

const appendToStorage = (name: any) => {
  let old = localStorage.getItem(name)
  if (old) {
    old = JSON.parse(old)
  }
  return old
}
const Quesioner: FC = () => {
  const navigate = useNavigate()
  const {getAll, create, getOneById} = useApi()
  const [question, setQuestion] = useState<MasterQuestion[]>([])
  const [area, setArea] = useState(false)
  const [factory, setFactory] = useState('')
  const [lang, setLang] = useState('id')
  const [email, setEmail] = useState({})
  const [emailOwner, setEmailOwner] = useState({})
  const [params, setParams] = useState({})
  const [izinGadget, setIzinGadget] = useState({})
  const [expirate, setExpirate] = useState(true)
  const [answers, setAnswers] = useState([])
  const [formArea, setFormArea] = useState({})
  const [alertFiil, setAlertFiil] = useState({
    show: false,
    text: '',
    color: '',
  })
  const [identityImage, setidentityImage] = useState<{
    name: string
    base64: string
    ext: string
  }>({
    name: '',
    base64: '',
    ext: '',
  })
  const [billingual, setBillingual] = useState({
    title: 'Kuisioner',
    desc: 'Harap isi keseluruhan kuisioner yang telah kami sediakan',
    titleAreaKhusus: 'Kuisioner Area Khusus',
  })
  const getEmail = async (payload: any) => {
    const response = await getOneById<PublicUser>('public-user', payload)
    const data = response.data
    const payloadEmail = {
      userDanoners: data.email,
    }
    setEmail(payloadEmail)
  }
  const getEmailOwner = async (payload: any) => {
    const parseFormArea = JSON.parse(payload)
    const payloadEmail = {
      ownerDanone: parseFormArea.ownerEmail,
    }
    setEmailOwner(payloadEmail)
  }
  async function fetchData() {
    let factory: any = localStorage.getItem('factory')
    if (factory) {
      factory = JSON.parse(factory)
    }
    const eparams = {
      date_owner: 'quesioners',
      factory_id: factory['factory_id'],
      '!sort[efective_date]': -1,
      // '!sort[order]': -1,
    }
    getAll<efectiveDate>('efectiveDate', eparams).then((response) => {
      const {data} = response.data
      const result = data.filter((entries) => {
        const current = new Date().toISOString().split('T')[0]
        const eDate = new Date(entries.efective_date).toISOString().split('T')[0]
        return eDate <= current
      })

      const number = Number(area)
      const params = {
        area_khusus: number,
        question_status: 'active',
        '!limit': 5,
        factory_id: factory['factory_id'],
        efective_date_id: result[0].id,
        '!sort[order]': 1,
      }
      setFactory(factory['factory_id'])
      getAll<MasterQuestion>('master_question', params).then((response) => {
        const {data} = response.data
        setQuestion(data)
      })
    })
  }
  const getArea = () => {
    const data: any = localStorage.getItem('area_khusus')
    if (data) {
      const parse = JSON.parse(data)
      setArea(() => JSON.parse(parse.area_khusus))
    }
  }
  const formRegistrasi = localStorage.getItem('form-register')
  let dataPrse: any
  if (formRegistrasi) {
    dataPrse = JSON.parse(formRegistrasi)
  }
  const [labelFactory, setLabelFactory] = useState('')
  const [iconFactory, setIconFactory] = useState('')
  //new arsitektur
  const payload = () => {
    const keyObj = [
      'vidio_induction',
      'type_visitor',
      'factory',
      'form-register',
      'safety_rules',
      'area_khusus',
      'kuisioner',
    ]
    const data = keyObj.map((entry: any) => {
      return appendToStorage(entry)
    })
    const codeImage: any = localStorage.getItem('image')
    const result: any = data.reduce((r, c) => Object.assign(r, c), {})
    const type = codeImage.split(';')[0].split('/')[1]
    const nameImage = `${result['type_identitas']}-${result['no_identitas']}-${result['tgl_kedatangan']}`
    const emailDanoners = result['bertemu_dengan']
    getEmail(emailDanoners)
    if (area) {
      getEmailOwner(localStorage.getItem('form-area'))
    }
    const payImage: any = {
      image: nameImage + '.' + type,
    }
    const payload = {...result, ...payImage}
    setidentityImage({
      name: nameImage,
      base64: codeImage,
      ext: type,
    })
    setParams(payload)
    return payload
  }
  //end arsitektur
  useEffect(() => {
    const localFormArea = localStorage.getItem('form-area')
    if (localFormArea) {
      const parse = JSON.parse(localFormArea)
      setFormArea(parse)
    }
    const getLocalLabel = localStorage.getItem('factory')
    if (getLocalLabel) {
      const parse = JSON.parse(getLocalLabel)
      const icon = `${API_URL}/maps/${parse.icon}`
      setIconFactory(icon)
      setLabelFactory(parse.factoryName)
    }
    const getLang = localStorage.getItem('language')
    if (getLang === 'en') {
      setLang(getLang)
      setBillingual({
        title: 'Questionnaire Form',
        desc: 'Please complete the fields below',
        titleAreaKhusus: 'Designated Area Questionnaire Form',
      })
    }
    getArea()
    fetchData()
    payload()
  }, [area, answers])

  let results = {
    quezioner: {},
    status: '',
  }
  let answerResults = []
  for (let i = 0; i < Object.keys(answers).length; i++) {
    answerResults.push({
      question_id: parseInt(Object.keys(answers)[i]),
      answer: Object.values(answers)[i],
      arrival_date: dataPrse.tgl_kedatangan,
      factory_id: parseInt(factory),
      lang,
      area_khusus: area ? 'yes' : 'no',
    })
    results.quezioner = answerResults
  }
  const checkAnswer = async (payload: any) => {
    const response = await create('quizioners/checkAnswer/', payload)
    const {data} = response
    return data
  }
  const submitQuestion = async (payload: any) => {
    await create('quizioners', payload)
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    const resultCreate = await checkAnswer(results)
    let payload: any = {...params, ...izinGadget}
    payload.factory_id = parseInt(payload.factory_id)
    payload.status_boking = 'reject'
    payload.status_owner_approval = 'reject'
    payload.post_test = 1
    payload.kuisioner = true
    payload.kuisioner_khusus = false
    payload.area_khusus = area
    payload.lang = lang

    if (resultCreate.status === 'success') {
      payload.status_boking = 'waiting'
      payload.status_owner_approval = 'waiting'
      let newPayload = {
        kuisioner: true,
        data: resultCreate.data,
      }
      switch (area) {
        case true:
          localStorage.setItem('kuisioner_area', JSON.stringify(newPayload))
          break
        default:
          localStorage.setItem('kuisioner', JSON.stringify(newPayload))
          break
      }

      setAlertFiil({
        show: true,
        text: 'Great job! Please proceed to the next step.',
        color: 'success',
      })
      setTimeout(() => {
        if (area) {
          navigate('/izin-gadget')
          return false
        }
        navigate('/area-1')
      }, 2000)
    } else if (resultCreate.status === 'failed') {
      saveImgae(identityImage)
      const response = await submitVisitor(payload)
      if (area) {
        payload.kuisioner_khusus = true
        const saveQuestionGeneral = localStorage.getItem('kuisioner')
        if (saveQuestionGeneral) {
          const general = JSON.parse(saveQuestionGeneral)
          general.data.map((entry: {user_id: number}) => {
            entry.user_id = response.id
            return entry
          })
          submitQuestion(general)
        }
        const saveQuestionKhusus = resultCreate.data.map((entry) => {
          entry.user_id = response.id
          return entry
        })
        const paramsQuestioner = {
          data: saveQuestionKhusus,
        }
        submitQuestion(paramsQuestioner)
      } else {
        const saveQuestion = resultCreate.data.map((entry) => {
          entry.user_id = response.id
          return entry
        })
        const paramsQuestioner = {
          data: saveQuestion,
        }
        submitQuestion(paramsQuestioner)
      }
      const postTestLocal = localStorage.getItem('post_test')
      if (postTestLocal) {
        const postTestResult = JSON.parse(postTestLocal)
        postTestResult.visitor_answer.map((entry: {visitor_id: number}) => {
          entry.visitor_id = response.id
          return entry
        })
        submitPostVisitor(postTestResult)
      }

      setAlertFiil({
        show: true,
        text: 'Sorry, but you are not permitted to proceed to the next page.',
        color: 'danger',
      })
      setTimeout(() => {
        navigate('/reject')
      }, 3000)
    }
    localStorage.setItem('payloadVisitor', JSON.stringify(payload))
    localStorage.setItem('payloadImageVisitor', JSON.stringify(identityImage))
  }
  const saveImgae = async (payload: {}) => {
    await create('quizioners/identity-image/', payload)
  }
  const submitVisitor = async (payload: Visitor) => {
    return await create<Visitor>('visitor', payload).then((response) => {
      const {data} = response
      if (data) {
        const result = {
          ...email,
          ...data,
          visitorId: data.id,
          emailVisitor: data.email,
          hostName: HOST,
        }
        const payloadMailOwner = {
          ...emailOwner,
          ...data,
          duration: {...formArea},
          visitorId: data.id,
          emailVisitor: data.email,
          hostName: HOST,
        }
        const payloadWaiting = {
          userVisitor: data.email,
          type: data.type_identitas,
          noIdentitas: data.no_identitas,
          hostFrontEnd: HOST,
          visitorName: data.nama,
        }
        try {
          if (data.status_boking !== 'reject') {
            mailToVisitor(payloadWaiting)
            mailTo(result)
            mailToOwner(payloadMailOwner)
          }
        } catch (error) {
          console.log(error)
        }
      }
      return data
    })
  }
  const mailTo = async (payload: {}) => {
    await create('visitor/mailTo/', payload)
  }
  const mailToOwner = async (payload: {}) => {
    await create('visitor/mailToOwner/', payload)
  }
  const mailToVisitor = async (payload: {}) => {
    await create('visitor/mail_to_visitor/', payload)
  }
  const submitPostVisitor = async (formValue: {}) => {
    await create('post_visitors', formValue)
  }
  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section className='d-flex align-items-center justify-content-center flex-column'>
        <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
          <img src={danone} alt='' width={220} />
          <img src={iconFactory} alt='' width={100} />
        </div>
        <div className='row gx-10 align-items-center w-100'>
          <div className='col-12 col-md-6 text-center-sm'>
            {area !== true ? (
              <div className='mb-10'>
                <h2>{labelFactory} Factory</h2>
                <h1>{billingual.title}</h1>
                <p className='d-md-block d-none'>{billingual.desc}</p>
                <a href='area-1' className='btn btn-secondary btn-md-back mt-3'>
                  Back
                </a>
              </div>
            ) : (
              <div className='mb-10'>
                <h2>{labelFactory} Factory</h2>
                <h1>{billingual.titleAreaKhusus}</h1>
                <p className='d-md-block d-none'>{billingual.desc}</p>
                <a href='area-1' className='btn btn-secondary btn-md-back mt-3'>
                  Back
                </a>
              </div>
            )}
          </div>
          <div className='col-12 col-md-6'>
            <form onSubmit={handleSubmit}>
              <Alert
                dismissible
                onClose={() => setAlertFiil({...alertFiil, show: false})}
                show={alertFiil.show}
                variant={alertFiil.color}
              >
                {alertFiil.text}
              </Alert>
              <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
              <div className='p-6 border-md overflow-auto h-500px'>
                <div className='d-flex flex-column flex-wrap align-content-center gap-2 justify-content-center mb-4 scroll-style-4'>
                  <ol>
                    {question.map((entry: MasterQuestion) => {
                      return (
                        <li key={entry.id} className='text-black'>
                          {lang === 'en' ? entry.question_en : entry.question}
                          <div>
                            {JSON.parse(`${lang === 'en' ? entry.answer_en : entry.answer}`).map(
                              (opt: string, indexOpt: number) => {
                                return (
                                  <div className='form-check form-check-small' key={indexOpt}>
                                    <input
                                      className='form-check-input pb-5'
                                      type='radio'
                                      data-question={entry.id}
                                      name={`answer${entry.id}`}
                                      id={`flexRadioDefault${entry.id}${opt}`}
                                      value={opt}
                                      onChange={() =>
                                        setAnswers((answer) => ({
                                          ...answer,
                                          [entry.id]: opt,
                                        }))
                                      }
                                      required
                                    />
                                    <label
                                      className='form-check-label text-black'
                                      htmlFor={`flexRadioDefault${entry.id}${opt}`}
                                    >
                                      {opt}
                                    </label>
                                  </div>
                                )
                              }
                            )}
                          </div>
                        </li>
                      )
                    })}
                  </ol>
                </div>
              </div>
              <button
                className='btn btn-primary rounded-100 fw-semibold mx-auto d-flex mt-10'
                type='submit'
              >
                Submit
              </button>
            </form>
          </div>
        </div>
        <div className='button-back'>
          <a href='area-1'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.87866 12L13.9393 3.93934L16.0606 6.06066L10.1213 12L16.0606 17.9393L13.9393 20.0607L5.87866 12Z'
                fill='#585858'
              />
            </svg>
          </a>
        </div>
        <Footer />
      </section>
    </>
  )
}

export {Quesioner}
