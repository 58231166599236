import { FC, useEffect, useState } from 'react'
import topRight from '../../layout/img/page-14/img-other/top-right.png'
import illustrasi5 from '../../layout/img/page-15/img-other/icon 3 - hygene rule 1 (11).png'
import sideRight from '../../layout/img/page-14/img-other/side-right.png'
import danone from '../../layout/img/Danone-Logo.png'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ErrorMessage } from '@hookform/error-message'
import Alert from 'react-bootstrap/Alert'
import { Helmet } from 'react-helmet'
import { useApi } from '../../hooks/useApi'
import { Visitor } from '../../models/visitor'
import { PublicUser } from '../../models/public_user'

const randomstring = require('randomstring')
const HOST = process.env.REACT_APP_HOST
const API_URL = process.env.REACT_APP_API_BASE_URL

interface izinGadgetForm {
  'izin-gadget': string;
  keperluan: string;
}
const IzinGadget: FC = () => {

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<izinGadgetForm>()
  const [alertFiil, setAlertFiil] = useState({
    show: false,
    text: '',
    color: '',
  })
  const [billingual, setBillingual] = useState({
    title: 'IZIN GADGET',
    desc: `Mohon untuk mengisi kolom isian disamping.`,
    gadget: '(Telepon Genggam/Kamera/Tablet/Laptop/Handy Talky)',
    question: 'Apakah anda akan membawa gadget ke area khusus ?',
    whatNeed: 'Keperluan Membawa Gadget ?',
    yesAnswer: 'Ya',
    noAnswer: 'Tidak',
  })
  const { create, getOneById } = useApi()
  const [params, setParams] = useState({})
  const [email, setEmail] = useState({})
  const [emailOwner, setEmailOwner] = useState({})
  const [formArea, setFormArea] = useState({})
  const [lang, setLang] = useState('id')
  const [area, setArea] = useState(false)

  const kodeBoking = () => {
    const data = randomstring.generate({
      charset: 'alphanumeric',
      length: 5,
    })
    return data
  }
  const [kode] = useState(kodeBoking)
  const [identityImage, setidentityImage] = useState<{
    name: string
    base64: string
    ext: string
  }>({
    name: '',
    base64: '',
    ext: '',
  })
  const onError = (errors: any, e: any) => console.log(errors, e)

  const navigate = useNavigate()

  const onSubmit = async (data: izinGadgetForm) => {
    if (data['izin-gadget'] === 'ya' && data['keperluan'] === '') {
      setAlertFiil({
        show: true,
        text: `Keperluan Membawa Gadget Is Required`,
        color: 'danger',
      })
      return false
    }
    const kodeBook = kodeBoking()
    localStorage.setItem('kode', JSON.stringify(kodeBook))
    saveImgae(identityImage)
    let checkIn
    let expirate
    let visIdBefore
    const localCheckIn = localStorage.getItem('check_in')
    const localExpirate = localStorage.getItem('expirate')
    const localVisIdBefore = localStorage.getItem('visitorIdBefore')
    if (localExpirate) {
      expirate = JSON.parse(localExpirate)
    }
    if (localCheckIn) {
      checkIn = JSON.parse(localCheckIn)
    }
    if (localVisIdBefore) {
      visIdBefore = JSON.parse(localVisIdBefore)
    }
    let payload: any = { ...params, ...data }
    payload.factory_id = parseInt(payload.factory_id)
    payload.status_boking = 'waiting'
    payload.status_owner_approval = 'waiting'
    payload.post_test = 1
    payload.kuisioner = true
    payload.safety_rules = 1
    payload.kuisioner_khusus = true
    payload.area_khusus = area
    payload.lang = lang
    payload.kode_boking = kodeBook
    payload.check_in = checkIn
    payload.expirate = expirate
    const saveQuestionGeneral = localStorage.getItem('kuisioner')
    const saveQuestionKhusus = localStorage.getItem('kuisioner_area')
    const response = await submitVisitor(payload)
    if (area) {
      payload.kuisioner_khusus = true
      payload.visitorIdBefore = visIdBefore
      if (saveQuestionGeneral) {
        const general = JSON.parse(saveQuestionGeneral)
        general.data.map((entry: { user_id: number }) => {
          entry.user_id = response.id
          return entry
        })
        submitQuestion(general)
      }
      if (saveQuestionKhusus) {
        const saveQuestionArea = JSON.parse(saveQuestionKhusus)
        saveQuestionArea.data.map((entry: { user_id: number }) => {
          entry.user_id = response.id
          return entry
        })
        const paramForArea = {...formArea, visitor_id: response.id}
        if(response.area_khusus){
          createAreaKhusus(paramForArea)
        }
        submitQuestion(saveQuestionArea)
      }
      const paramForPostExist = {
        visitorIdBefore : visIdBefore,
        visitorIdNew : response.id,

      }
      if(visIdBefore){
        savePostBaseIdVisitor(paramForPostExist)
      }
    } else {
      if (saveQuestionGeneral) {
        const general = JSON.parse(saveQuestionGeneral)
        general.data.map((entry: { user_id: number }) => {
          entry.user_id = response.id
          return entry
        })
        submitQuestion(general)
      }
    }
    const postTestLocal = localStorage.getItem('post_test')
    if (postTestLocal) {
      const postTestResult = JSON.parse(postTestLocal)
      postTestResult.visitor_answer.map((entry: { visitor_id: number }) => {
        entry.visitor_id = response.id
        return entry
      })
      submitPostVisitor(postTestResult)
    }
    navigate('/hasil')
  }

  const getArea = () => {
    const data: any = localStorage.getItem('area_khusus')
    if (data) {
      const parse = JSON.parse(data)
      setArea(() => JSON.parse(parse.area_khusus))
    }
  }
  const getFormArea = () => {
    const data = localStorage.getItem('izin-gadget')
    if (data) {
      const parse = JSON.parse(data)
      setValue('izin-gadget', parse['izin-gadget'])
      setValue('keperluan', parse['keperluan'])
    }
  }
  const [labelFactory, setLabelFactory] = useState('')
  const [iconFactory, setIconFactory] = useState('')

  useEffect(() => {
    const getLocalLabel = localStorage.getItem('factory')
    if (getLocalLabel) {
      const parse = JSON.parse(getLocalLabel)
      const icon = `${API_URL}/maps/${parse.icon}`
      setIconFactory(icon)
      setLabelFactory(parse.factoryName)
    }
    const getLang = localStorage.getItem('language')
    if (getLang === 'en') {
      setBillingual({
        title: 'GADGET PERMISSION',
        desc: `Please complete the fields below.`,
        gadget: '(Mobile Phone/Camera/Tablet/Laptop/Handy Talky)',
        question: 'Will you bring a gadget to the designated area?',
        whatNeed: 'Purpose of bringing the gadget ?',
        yesAnswer: 'Yes',
        noAnswer: 'No',
      })
    }
    const localFormArea = localStorage.getItem('form-area')
    if (localFormArea) {
      const parse = JSON.parse(localFormArea)
      setFormArea(parse)
    }
    getArea()
    getFormArea()
    payload()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const payload = () => {
    const keyObj = [
      'vidio_induction',
      'type_visitor',
      'factory',
      'form-register',
      'safety_rules',
      'area_khusus',
      'kuisioner',
      'kuisioner_area',
      'post_test',
    ]
    const data = keyObj.map((entry: any) => {
      return appendToStorage(entry)
    })
    const codeImage: any = localStorage.getItem('image')
    const result: any = data.reduce((r, c) => Object.assign(r, c), {})
    const type = codeImage.split(';')[0].split('/')[1]
    const nameImage = `${result['type_identitas']}-${result['no_identitas']}-${result['tgl_kedatangan']}`
    const emailDanoners = result['bertemu_dengan']
    getEmail(emailDanoners)
    getEmailOwner(localStorage.getItem('form-area'))
    const payImage: any = {
      image: nameImage + '.' + type,
    }
    const payload = { ...result, ...payImage }
    setidentityImage({
      name: nameImage,
      base64: codeImage,
      ext: type,
    })
    setParams(payload)
    return payload
  }

  const saveImgae = async (payload: {}) => {
    await create('quizioners/identity-image/', payload)
  }
  const appendToStorage = (name: any) => {
    let old = localStorage.getItem(name)
    if (old) {
      old = JSON.parse(old)
    }
    return old
  }
  const getEmail = async (payload: any) => {
    const response = await getOneById<PublicUser>('public-user', payload)
    const data = response.data
    const payloadEmail = {
      userDanoners: data.email,
    }
    setEmail(payloadEmail)
  }
  const getEmailOwner = async (payload: any) => {
    const parseFormArea = JSON.parse(payload)
    const response = await getOneById<PublicUser>('public-user', parseFormArea['owner-area'])
    const data = response.data
    const payloadEmail = {
      ownerDanone: data.email,
    }
    setEmailOwner(payloadEmail)
  }
  const savePostBaseIdVisitor = async (IdVisitor: {}) => {
    const response = await create('post_visitors/detailVisitorPost/', IdVisitor)
    const {data} = response
    return data
  }
  const submitVisitor = async (payload: Visitor) => {
    return await create<Visitor>('visitor', payload).then((response) => {
      const { data } = response
      if (data) {
        const result = {
          ...email,
          ...data,
          visitorId: data.id,
          emailVisitor: data.email,
          hostName: HOST,
        }
        const payloadMailOwner = {
          ...emailOwner,
          ...data,
          duration: { ...formArea },
          visitorId: data.id,
          emailVisitor: data.email,
          hostName: HOST,
        }
        const payloadWaiting = {
          userVisitor: data.email,
          type: data.type_identitas,
          noIdentitas: data.no_identitas,
          hostFrontEnd: HOST,
          visitorName: data.nama,
        }
        try {
          if (data.status_boking !== 'reject') {
            mailToVisitor(payloadWaiting)
            mailTo(result)
            mailToOwner(payloadMailOwner)
          }
        } catch (error) {
          console.log(error)
        }
      }
      return data
    })
  }
  const mailTo = async (payload: {}) => {
    await create('visitor/mailTo/', payload)
  }
  const mailToOwner = async (payload: {}) => {
    await create('visitor/mailToOwner/', payload)
  }
  const mailToVisitor = async (payload: {}) => {
    await create('visitor/mail_to_visitor/', payload)
  }
  const submitPostVisitor = async (formValue: {}) => {
    await create('post_visitors', formValue)
  }
  const submitQuestion = async (payload: any) => {
    await create('quizioners', payload)
  }
  const createAreaKhusus = async (payload: {}) => {
    await create('form-area', payload)
  }
  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section className='d-flex flex-column align-items-center '>
        <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
          <img src={danone} alt='' width={220} />
          <img src={iconFactory} alt='' width={100} />
        </div>
        <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
        <div className='row gx-10 align-items-center'>
          <div className='col-12 col-md-6 text-center-sm'>
            <h1 className='fw-bold text-black'>{billingual.title}</h1>
            <h2 className='text-subtitle'>{labelFactory} Factory</h2>
            <p className='mt-10 d-none d-md-block'>{billingual.desc}</p>
            <a href='identity' className='btn btn-secondary btn-md-back mt-3'>
              Back
            </a>
          </div>
          <div className='col-12 col-md-6 text-center'>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <Alert
                dismissible
                onClose={() => setAlertFiil({ ...alertFiil, show: false })}
                show={alertFiil.show}
                variant={alertFiil.color}
              >
                {alertFiil.text}
              </Alert>
              <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
              <div className='d-flex flex-column flex-wrap align-content-center justify-content-start'>
                <div className='text-center mb-4'>
                  <img src={illustrasi5} className='mb-2 img-mobile-responsive' alt='' />
                  <div className='d-flex flex-column gap-4'>
                    <div>
                      <div className='text-subtitle px-5'>
                        {billingual.question}
                      </div>
                      <small className='px-5'>
                        {billingual.gadget}
                      </small>
                    </div>
                    <div className='px-4'>
                      <div className='form-group border-1'>
                        <select
                          className='form-select border-0'
                          aria-label='default select example'
                          // name='zona'
                          {...register('izin-gadget', {
                            required: true,
                          })}
                        >
                          <option value='ya'>{billingual.yesAnswer}</option>
                          <option value='tidak'>{billingual.noAnswer}</option>
                        </select>
                        <ErrorMessage errors={errors} name='izin-gadget' />
                        <ErrorMessage
                          errors={errors}
                          name={'izin-gadget'}
                          render={() => <p className='text-danger'>Izin Gadget is required !</p>}
                        />
                      </div>
                    </div>
                    <div className='px-4'>
                      <div className='form-group border-1'>
                        <input
                          className='form-control'
                          {...register('keperluan', {})}
                          aria-label='default select example'
                          placeholder={billingual.whatNeed}
                        // name='area'
                        />

                        <ErrorMessage errors={errors} name='keperluan' />
                        <ErrorMessage
                          errors={errors}
                          name={'keperluan'}
                          render={() => <p className='text-danger'>Keperluan is required !</p>}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-10 text-center'>
                <button type='submit' className='btn btn-primary rounded-100 fw-semibold'>
                  Next
                </button>
              </div>
              <img src={sideRight} className='img-illustrasi-side-right' alt='' />
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export { IzinGadget }
