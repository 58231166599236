import {FC} from 'react'
import {CreatePage} from './CreatePage'

export const AreasCreate: FC = () => {
  return (
    <>
      <CreatePage></CreatePage>
    </>
  )
}
