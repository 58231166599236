import {FC, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useLocation, Link, useNavigate} from 'react-router-dom'
import {ErrorMessage} from '@hookform/error-message'
import {useApi} from '../../../../hooks/useApi'
import {useFileCheck} from '../../../../hooks/useModelCheck'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Form from 'react-bootstrap/Form'
import {Factory} from '../../../../models/factory'
import {MasterRange} from '../../../../models/master_range'
import ClipLoader from 'react-spinners/ClipLoader'

export const EditPage: FC = () => {
  const [collectionName, setCollectionName] = useState<string>('')
  const routeParams = useLocation()
  const routeUrl = routeParams.pathname.replace('/', '')
  const splitCollection = routeUrl.split('/')
  const route = splitCollection[0]
  const id = splitCollection[1]
  const {getModel} = useFileCheck()
  const {getOneById, update, getAll} = useApi()
  const MySwal = withReactContent(Swal)
  const collectionExist = getModel(`${route}`)
  const {layout} = collectionExist
  const [formUpdate, setFormUpdate]: any = useState({})

  const [progress, setProgress] = useState<number>(0)
  const [message] = useState<string>('')
  const [factory, setFactory] = useState<Factory[]>([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<MasterRange>()

  const getFactory = async () => {
    const response = await getAll<Factory>('factory')
    const {data} = response.data
    setFactory(data)
  }

  useEffect(() => {
    getFactory()
    const getName = layout.map(function (layoutName: any) {
      const obj = {
        name: layoutName.name,
        type: layoutName.type,
      }
      return obj
    })
    getOneById(`${route}`, `${id}`).then((response) => {
      const {data}: any = response
      getName.forEach((field: any) => {
        if (field.type === 'date') {
          const date = new Date(data[field.name])
          let day = date.getDate()
          let month = ('0' + (date.getMonth() + 1)).slice(-2)
          let year = date.getFullYear()
          const currentDate = `${year}-${month}-${day}`
          Object.assign(data, {
            [field.name]: currentDate,
          })
        }
        setValue(field.name, data[field.name])
        setValue('factory_id', data['factory_id'])
      })
      setFormUpdate(data)
    })

    const renameCollection = () => {
      const words = route.split('_')
      const titles: string[] = []
      for (const key in words) {
        const word = words[key]
        titles.push(word.charAt(0).toUpperCase() + word.slice(1))
      }
      return titles.join(' ')
    }
    setCollectionName(renameCollection())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue])

  const onSubmit = async (data: MasterRange, e: any) => {
    setLoading(true)
    const payload = data
    await update(`${route}`, id, payload)
      .then((response) => {
        MySwal.fire({
          title: <strong className='text-black'>Success</strong>,
          html: <i className='text-black'>Data Update!</i>,
          icon: 'success',
        })
        navigate(`/${route}`)
      })
      .catch((error) => {
        if (error.response) {
          const {data} = error.response
          MySwal.fire({
            title: <strong className='text-black'>Failed</strong>,
            html: <i className='text-black'>{data.errors[0].message}!</i>,
            icon: 'error',
          })
        }
      })
      .finally(() => setLoading(false))
  }
  const onError = (errors: any, e: any) => console.log(errors, e)
  const changeFactory = (newSelect: string): void => {
    setFormUpdate('factory_id', newSelect)
  }
  return (
    <div>
      {loading === true ? (
        <div className='loader'>
          <ClipLoader
            color={'#00008B'}
            loading={true}
            size={50}
            aria-label='Loading Spinner'
            data-testid='loader'
            // style={{}}
          />
        </div>
      ) : (
        ''
      )}
      <div className='card card-custom gutter-b'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>
              Edit {collectionName}
              <small></small>
            </h3>
          </div>
        </div>
        <div className='card-body'>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className='row'>
              {layout.map((fields: any, index: number) => {
                if (['number'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <Form.Label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </Form.Label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        type='number'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <Form.Text className='text-muted'>{fields.note}</Form.Text>
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['text'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        defaultValue={formUpdate[fields.name]}
                        type='text'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['textarea'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </label>
                      <textarea
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        defaultValue={formUpdate[fields.name]}
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['select'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <div className='form-group'>
                        <label className='text-black'>{fields.label}</label>
                        <select
                          className='form-control form-control-solid'
                          {...register(fields.name)}
                          defaultValue={formUpdate[fields.name]}
                        >
                          {fields.options.map((option: any, indexOpt: number) => (
                            <option key={indexOpt} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <ErrorMessage errors={errors} name={fields.name} />
                        <ErrorMessage
                          errors={errors}
                          name={fields.name}
                          render={() => <p className='text-danger'>{fields.name} is required !</p>}
                        />
                      </div>
                    </div>
                  )
                }
                if (['date'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        defaultValue={formUpdate[fields.name]}
                        type='date'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['email'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                          pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        })}
                        defaultValue={formUpdate[fields.name]}
                        type='email'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                return layout
              })}
              <div className={`mb-4 col-6`}>
                <Form.Group>
                  <Form.Label>Factory Id</Form.Label>
                  <Form.Select
                    {...register('factory_id', {
                      required: true,
                    })}
                    onChange={(event) => changeFactory(event.target.value)}
                    value={formUpdate['factory_id']}
                  >
                    <option value=''>Select Factory</option>
                    {factory.map((entry: Factory) => {
                      return (
                        <option key={entry.id} id='' value={entry.id}>
                          {entry.name}
                        </option>
                      )
                    })}
                  </Form.Select>
                </Form.Group>
                <ErrorMessage errors={errors} name='factory_id' />
                <ErrorMessage
                  errors={errors}
                  name='factory_id'
                  render={() => <p className='text-danger'>Factory is required !</p>}
                />
              </div>
            </div>
            <Link to={`/${route}`}>
              <button className='btn btn-secondary cancel'>Cancel </button>
            </Link>
            <button type='submit' className='btn btn-success'>
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
