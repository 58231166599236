import { ID } from "../_metronic/helpers";

export interface User {
  id?: ID;
  username:string;
  first_name: string;
  last_name?: string;
  email:string;
  password?:string;
  confirm_password?:string;
  role?: string;
  is_admin: boolean;
  factory_id:string
  groups: string;
  photo?: string;
  status: string

}

export interface UserResponse {
  id: ID;
  username: string;
  first_name: string;
  last_name?: string;
  email:string;
  role?: Array<string>;
  password: string,
  confirm_password?: string,
  is_admin: boolean,
  groups: string,
  photo?: string,
  status: string,
  factory_id:string
}

export const InitialValue = {
  username: '',
  first_name: '',
  last_name: '',
  email:'',
  password:'',
  confirm_password:'',
  is_admin: false,
  groups: '',
  photo: '',
  status: '',
  factory_id:''
}

