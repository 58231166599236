export interface Videos {
    name: string
    type:string
    size:string
    path:string
    factory_id:number
  }
  export const useCollection = () => {
    const collection = 'videos'
  
    const columns = [
      {
        name: 'name',
        required: true,
        label: 'Name',
        align: 'left',
        field: 'name',
        sortable: true
      },
      // {
      //   name: 'type',
      //   required: true,
      //   label: 'Type',
      //   align: 'left',
      //   field: 'type',
      //   sortable: true
      // },
      // {
      //   name: 'size',
      //   required: true,
      //   label: 'Size',
      //   align: 'left',
      //   field: 'size',
      //   sortable: true
      // },
      // {
      //   name: 'path',
      //   required: true,
      //   label: 'Path',
      //   align: 'left',
      //   field: 'path',
      //   sortable: true
      // },
      {
        name: 'factory_id',
        required: true,
        label: 'factory_id',
        align: 'left',
        field: 'factory_id',
        sortable: true
      },
    ]
    const layout = [
  
        {
          type: 'text',
          col: 'col-6',
          name: 'name',
          placeholder: 'Name',
          label: 'Name',
          required: true
        },
        {
          type: 'text',
          col: 'col-6',
          name: 'type',
          placeholder: 'Type',
          label: 'type',
          required: false
        },
        {
          type: 'text',
          col: 'col-6',
          name: 'size',
          placeholder: 'Size',
          label: 'size',
          required: false
        },
        {
          type: 'text',
          col: 'col-6',
          name: 'path',
          placeholder: 'Path',
          label: 'path',
          required: false
        }
    ]
    const filter = [{
      type: 'reference',
      advanceFilter: true,
      col: 'col-6',
      name: 'factory_id',
      placeholder: 'Factory',
      label: 'Select Factory',
      reference: 'factory',
      option : {
        value: 'id',
        label : 'name'
      }
    }]

    return {
      collection,
      columns,
      layout,
      filter
    }
  }