import {FC, useEffect, useState} from 'react'
import topRight from '../../layout/img/page-7/img-other/top-right.png'
import buttonRight from '../../layout/img/page-7/img-other/botton-right.png'
import danone from '../../layout/img/Danone-Logo.png'
import {Footer} from '../../components/footer'
import {useNavigate} from 'react-router-dom'
import {useApi} from '../../hooks/useApi'
import Alert from 'react-bootstrap/Alert'
import Select from 'react-select'
import {useForm} from 'react-hook-form'
import {Helmet} from 'react-helmet'
import { PublicUser } from '../../models/public_user'
import { PublicVisitor } from '../../models/publicVisitor'

const API_URL = process.env.REACT_APP_API_BASE_URL

const FormRegister: FC = () => {
  const {register, handleSubmit, setValue, setError} = useForm()
  const {getAll, getOneByParams, getOneById} = useApi()
  const [isLoading, setIsLoading] = useState(false)
  
  const [user, setUser] = useState<PublicUser[]>([])
  const [userSelected, setUserSelected] = useState<PublicUser[]>([])
  const [labelFactory, setLabelFactory] = useState('')
  const [factoryId, setFactoryId] = useState('')
  const [range, setRange] = useState(0)
  const [iconFactory, setIconFactory] = useState('')
  const [disabledDate, setDisabledDate] = useState('')
  const [billingual, setBillingual] = useState({
    desc: `Visitor di wajibkan mengambil photo atau melakukan upload dari galeri untuk
    kelengkapan data kami`,
    fullName: 'Nama Lengkap',
    phone: 'No Handphone',
    instansi: 'Asal Instansi',
    meet: 'Bertemu Dengan ..',
    purpose: 'Tujuan Bertemu',
    noteFill: 'Mohon untuk mengisi semua kolom isian dibawah.',
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = async (nameUser='', idUser='') => {
    const payload = {
      factory_id: factoryId,
      'user_danoners.name!like':nameUser,
      '!limit': 10
    }
    const response = await getAll<PublicUser>('public-user', payload)
    const {data} = response.data
    const option = data.map((v) => ({...v, value: v.id, label: v.name}))
    setUser(option)
  }
  const getOneUserDanoners = async(idUsers:any)=>{
    const response = await getAll<PublicUser>('public-user', {'user_danoners.id':idUsers})
      const {data} = response.data
      const option = data.map((v) => ({...v, value: v.id, label: v.name}))
      const userSelect = option.filter((x) => x.id === idUsers)

      setUser(option)
      setUserSelected(userSelect)
    
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const data = localStorage.getItem('form-register')
    if (data) {
      const dataPrse = JSON.parse(data)
      getOneUserDanoners(dataPrse.bertemu_dengan)
      Object.keys(dataPrse).forEach(function (key, index) {
        setValue(key, dataPrse[key])
      })
    }
    const getLang = localStorage.getItem('language')
    if (getLang === 'en') {
      setBillingual({
        desc: `Visitors must take a self-picture or do self-picture upload from your gallery as our completion data`,
        fullName: 'Full Name',
        phone: 'Mobile Phone Number',
        instansi: 'Company Origin',
        meet: 'Meet with ..',
        purpose: 'Purpose of visit',
        noteFill: 'Please fill in all the fields below.',
      })
    }
    const getLocalLabel = localStorage.getItem('factory')
    if (getLocalLabel) {
      const parse = JSON.parse(getLocalLabel)
      const icon = `${API_URL}/maps/${parse.icon}`
      setIconFactory(icon)
      setLabelFactory(parse.factoryName)
      setFactoryId(parse.factory_id)
    }
    const disabledDate = () => {
      let today = new Date()
      today.setDate(today.getDate() - 3)

      return today.toISOString().split('T')[0]
    }
    setDisabledDate(disabledDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const navigate = useNavigate()
  let initialFormData = Object.freeze({
    no_identitas: '',
    nama: '',
    no_hp: '',
    email: '',
    perusahaan: '',
    bertemu_dengan: null,
    tujuan_bertemu: '',
    tgl_kedatangan: '',
    form_register: false,
    type_identitas: '',
  })
  const [alertFiil, setAlertFiil] = useState({
    show: false,
    text: '',
    color: '',
  })
  const [newPayload, setNewPayload] = useState(initialFormData)
  const onError = (errors: any, e: any) => {
    if (errors) {
      setAlertFiil({
        show: true,
        text: 'Please make sure to fill out all the required fields.',
        color: 'danger',
      })
    }
  }

  const getValue = (e: any) => {
    setNewPayload({
      ...newPayload,
      [e.target.name]: e.target.value.trim(),
    })
    localStorage.setItem('form-register', JSON.stringify(newPayload))
  }

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true)

    const form_register = {
      form_register: true,
    }
    const result = {
      ...data,
      ...form_register,
    }

    const params = {
      type_identitas: data.type_identitas,
      no_identitas: data.no_identitas,
      factory_id: factoryId,
      '!sort[id]': -1,
    }
    await getOneByParams<any>('master_range', {factory_id: factoryId}).then((response) => {
      const {data} = response.data
      let rangeTime = 0
      if (data.length > 0) {
        rangeTime = data[0].range
      }
      setRange(rangeTime)
    })

    await getAll<PublicVisitor>('public-visitor', params).then((response) => {
      localStorage.setItem('form-register', JSON.stringify(result))
      const {data} = response.data
      const currentDate = new Date().toISOString().split('T')[0]
      const expDate = new Date()
      expDate.setDate(expDate.getDate() + range)
      let newExp = expDate.toISOString().split('T')[0]

      if (
        data.length !== 0 &&
        new Date(data[0].expirate).toISOString().split('T')[0] > currentDate
      ) {
        newExp = new Date(data[0].expirate).toISOString().split('T')[0]
        const vidio = {
          vidio_induction: true,
        }
        localStorage.setItem('visitorIdBefore', JSON.stringify(data[0].id))
        localStorage.setItem('vidio_induction', JSON.stringify(vidio))
        localStorage.setItem('check_in', JSON.stringify(currentDate))
        localStorage.setItem('expirate', JSON.stringify(newExp))
        navigate('/quesioner')
      } else {
        localStorage.setItem('check_in', JSON.stringify(currentDate))
        localStorage.setItem('expirate', JSON.stringify(newExp))
        setIsLoading(false)
        navigate('/welcome')
      }
      // }
    })
  }
  const userDanon = (e: any) => {
    const userSelect = user.filter((x) => x.id === e.value)
    setUserSelected(userSelect)
    setNewPayload({
      ...newPayload,

      bertemu_dengan: e.value,
    })
    setValue('bertemu_dengan', e.value)
  }
  const datePictures = (e: any) => {
    let datetrans = new Date()
    let datethis = new Date(e.target.value)
    let inv1 = datethis.setDate(datethis.getDate())
    let inv2 = datetrans.setDate(datetrans.getDate())
    let inv = (inv1 - inv2) / (3600 * 1000 * 24)
    const data = Math.round(inv)
    if (data < -3) {
      setAlertFiil({
        show: true,
        text: 'Date Forbidden',
        color: 'danger',
      })
      setError('tgl_kedatangan', {type: 'custom', message: 'Date Forbiden'})
    } else {
      setNewPayload({
        ...newPayload,

        [e.target.name]: e.target.value.trim(),
      })
      localStorage.setItem('form-register', JSON.stringify(newPayload))
    }
  }
  const handleInputChange = (nameUser:string) => {
    fetchData(nameUser)
  };

  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section id='vms-page-7A' className='d-flex flex-column align-items-center '>
        <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
          <img src={danone} alt='' width={220} />
          <img src={iconFactory} alt='' width={100} />
        </div>
        <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
        <div className='row gx-10 align-items-center'>
          <div className='col-12 col-md-6 text-center-sm'>
            <h1 className='fw-bold text-black'>REGISTER VISITOR</h1>
            <h2 className='text-subtitle'>{labelFactory} Factory</h2>
            <p className='mt-10 d-none d-md-block'>{billingual.desc}</p>
            <a href='identity' className='btn btn-secondary btn-md-back mt-3'>
              Back
            </a>
          </div>
          <div className='col-12 col-md-6 text-center'>
            <p className='text-black fw-bold'>{billingual.noteFill}</p>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <div className=''>
                <Alert
                  dismissible
                  onClose={() => setAlertFiil({...alertFiil, show: false})}
                  show={alertFiil.show}
                  variant={alertFiil.color}
                >
                  {alertFiil.text}
                </Alert>
                <div className='row'>
                  <div className='col-12 col-md-12 m-b-1'>
                    <div className='input-group border-1 rounded-1 mb-3'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text p-0'>
                          <select
                            id=''
                            className='form-control select-custom pe-3'
                            {...register('type_identitas', {
                              required: true,
                            })}
                            onChange={getValue}
                          >
                            <option value='' selected disabled>
                              Type
                            </option>
                            <option value='ktp'>KTP</option>
                            <option value='sim'>SIM</option>
                            <option value='pasport'>Pasport</option>
                            <option value='kitas'>KITAS</option>
                          </select>
                        </span>
                      </div>

                      <input
                        type='text'
                        // value={newPayload.no_identitas}
                        className='form-control'
                        placeholder='ID Number'
                        // name='no_identitas'
                        {...register('no_identitas', {
                          required: true,
                        })}
                        onInput={getValue}
                        aria-label='Username'
                        aria-describedby='basic-addon1'
                      />
                      {/* <ErrorMessage errors={errors} name='no_identitas' /> */}
                      {/* <ErrorMessage
                        errors={errors}
                        name='no_identitas'
                        render={() => <p className='text-danger'>'no_identitas' is required !</p>}
                      /> */}
                    </div>
                  </div>
                  <div className='col-12 col-md-12 m-b-1'>
                    <div className='input-group border-1 rounded-1 mb-3'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>
                          <span className='svg-icon svg-icon-1x'>
                            <svg
                              width='15'
                              height='16'
                              viewBox='0 0 15 16'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M11.5 15.0001H2.94445C1.87056 15.0001 1 14.1295 1 13.0557C1 9.88178 5.66667 9.94455 7.22223 9.94455C8.77779 9.94455 13.4445 9.88178 13.4445 13.0557C13.4445 14.1295 12.5739 15.0001 11.5 15.0001Z'
                                stroke='#87898E'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.22226 7.22223C8.94045 7.22223 10.3334 5.82934 10.3334 4.11112C10.3334 2.39289 8.94045 1 7.22226 1C5.50404 1 4.11115 2.39289 4.11115 4.11112C4.11115 5.82934 5.50404 7.22223 7.22226 7.22223Z'
                                stroke='#87898E'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </span>
                        </span>
                      </div>
                      <input
                        {...register('nama', {
                          required: true,
                        })}
                        onInput={getValue}
                        type='text'
                        className='form-control'
                        placeholder={billingual.fullName}
                        is-invalid='true'
                        // value={newPayload.nama}
                        // name='nama'
                        // aria-label='Username'
                        // aria-describedby='basic-addon1'
                      />
                      {/* <ErrorMessage errors={errors} name='nama' /> */}
                      {/* <ErrorMessage
                        errors={errors}
                        name='nama'
                        render={() => <p className='text-danger'>'nama' is required !</p>}
                      /> */}
                    </div>
                  </div>
                  <div className='col-12 col-md-12 m-b-1'>
                    <div className='input-group border-1 rounded-1 mb-3'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>
                          <span className='svg-icon svg-icon-1x'>
                            <svg
                              width='37'
                              height='37'
                              viewBox='0 0 37 37'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M36 29.5775C36 30.2075 35.8598 30.855 35.5618 31.485C35.2639 32.115 34.8783 32.71 34.3701 33.27C33.5113 34.215 32.5648 34.8975 31.4957 35.335C30.4442 35.7725 29.305 36 28.0781 36C26.2904 36 24.3801 35.58 22.3645 34.7225C20.349 33.865 18.3335 32.71 16.3355 31.2575C14.2991 29.7702 12.3775 28.1322 10.5869 26.3575C8.81457 24.576 7.17994 22.663 5.69705 20.635C4.25989 18.64 3.10315 16.645 2.26189 14.6675C1.42063 12.6725 1 10.765 1 8.945C1 7.755 1.21032 6.6175 1.63095 5.5675C2.05158 4.5 2.71758 3.52 3.64647 2.645C4.76815 1.5425 5.99499 1 7.29194 1C7.78267 1 8.27341 1.105 8.71157 1.315C9.16725 1.525 9.57036 1.84 9.88583 2.295L13.9519 8.0175C14.2674 8.455 14.4952 8.8575 14.653 9.2425C14.8107 9.61 14.8983 9.9775 14.8983 10.31C14.8983 10.73 14.7757 11.15 14.5303 11.5525C14.3025 11.955 13.9695 12.375 13.5488 12.795L12.2168 14.1775C12.024 14.37 11.9364 14.5975 11.9364 14.8775C11.9364 15.0175 11.9539 15.14 11.989 15.28C12.0416 15.42 12.0941 15.525 12.1292 15.63C12.4447 16.2075 12.988 16.96 13.7591 17.87C14.5478 18.78 15.3891 19.7075 16.3005 20.635C17.2469 21.5625 18.1582 22.42 19.0871 23.2075C19.9985 23.9775 20.7521 24.5025 21.348 24.8175C21.4357 24.8525 21.5408 24.905 21.6635 24.9575C21.8037 25.01 21.9439 25.0275 22.1017 25.0275C22.3996 25.0275 22.6274 24.9225 22.8202 24.73L24.1522 23.4175C24.5904 22.98 25.011 22.6475 25.4141 22.4375C25.8172 22.1925 26.2203 22.07 26.6585 22.07C26.9915 22.07 27.342 22.14 27.7276 22.2975C28.1132 22.455 28.5163 22.6825 28.9544 22.98L34.7556 27.0925C35.2113 27.4075 35.5268 27.775 35.7196 28.2125C35.8948 28.65 36 29.0875 36 29.5775Z'
                                stroke='#87898E'
                                strokeWidth='1.5'
                                strokeMiterlimit='10'
                              />
                            </svg>
                          </span>
                        </span>
                      </div>
                      <input
                        {...register('no_hp', {
                          required: true,
                        })}
                        onInput={getValue}
                        type='text'
                        className='form-control'
                        placeholder={billingual.phone}
                        // name='no_hp'
                        // value={newPayload.no_hp}
                        aria-label='Username'
                        aria-describedby='basic-addon1'
                      />
                      {/* <ErrorMessage errors={errors} name='no_hp' /> */}
                      {/* <ErrorMessage
                        errors={errors}
                        name='no_hp'
                        render={() => <p className='text-danger'>'no_hp' is required !</p>}
                      /> */}
                    </div>
                  </div>
                  <div className='col-12 col-md-12 m-b-1'>
                    <div className='input-group border-1 rounded-1 mb-3'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>
                          <span className='svg-icon svg-icon-1x'>
                            <svg
                              width='17'
                              height='17'
                              viewBox='0 0 17 17'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M5.875 13.7119H5.5C2.5 13.7119 1 12.9641 1 9.22532V5.48654C1 2.49551 2.5 1 5.5 1H11.5C14.5 1 16 2.49551 16 5.48654V9.22532C16 12.2164 14.5 13.7119 11.5 13.7119H11.125C10.8925 13.7119 10.6675 13.824 10.525 14.011L9.4 15.5065C8.905 16.1645 8.095 16.1645 7.6 15.5065L6.475 14.011C6.355 13.8465 6.0775 13.7119 5.875 13.7119Z'
                                stroke='#87898E'
                                strokeWidth='1.5'
                                strokeMiterlimit='10'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </span>
                        </span>
                      </div>
                      <input
                        {...register('email', {
                          required: true,
                        })}
                        onInput={getValue}
                        type='email'
                        className='form-control'
                        placeholder='Email'
                        name='email'
                        // value={newPayload.email}
                        aria-label='Email'
                        aria-describedby='basic-addon1'
                      />
                      {/* <ErrorMessage errors={errors} name='email' /> */}
                      {/* <ErrorMessage
                        errors={errors}
                        name='email'
                        render={() => <p className='text-danger'>'email' is required !</p>}
                      /> */}
                    </div>
                  </div>
                  <div className='col-12 col-md-12 m-b-1'>
                    <div className='input-group border-1 rounded-1 mb-3'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>
                          <span className='svg-icon svg-icon-1x'>
                            <svg
                              width='18'
                              height='16'
                              viewBox='0 0 18 16'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M1 14.9926H17M14.6582 15V11.7137M1.8 14.9926V3.22526C1.8 1.74421 2.52727 1 3.97455 1H8.50545C9.95273 1 10.6727 1.74421 10.6727 3.22526V14.9926M4.49091 4.86105H8.09091M4.49091 7.62421H8.09091M6.27273 14.9926V12.2295M14.6727 6.80632C13.7855 6.80632 13.0727 7.52842 13.0727 8.42737V10.1C13.0727 10.9989 13.7855 11.7211 14.6727 11.7211C15.56 11.7211 16.2727 10.9989 16.2727 10.1V8.42737C16.2727 7.52842 15.56 6.80632 14.6727 6.80632Z'
                                stroke='#87898E'
                                strokeWidth='1.5'
                                strokeMiterlimit='10'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </span>
                        </span>
                      </div>
                      <input
                        {...register('perusahaan', {
                          required: true,
                        })}
                        onInput={getValue}
                        type='text'
                        className='form-control'
                        placeholder={billingual.instansi}
                        name='perusahaan'
                        // value={newPayload.perusahaan}
                        aria-label='perusahaan'
                        aria-describedby='basic-addon1'
                      />
                      {/* <ErrorMessage errors={errors} name='perusahaan' /> */}
                      {/* <ErrorMessage
                        errors={errors}
                        name='perusahaan'
                        render={() => <p className='text-danger'>'perusahaan' is required !</p>}
                      /> */}
                    </div>
                  </div>
                  <div className='col-12 col-md-12 m-b-1'>
                    <div className='input-group border-1 rounded-1 mb-3'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>
                          <span className='svg-icon svg-icon-1x'>
                            <svg
                              width='15'
                              height='16'
                              viewBox='0 0 15 16'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M11.5 15.0001H2.94445C1.87056 15.0001 1 14.1295 1 13.0557C1 9.88178 5.66667 9.94455 7.22223 9.94455C8.77779 9.94455 13.4445 9.88178 13.4445 13.0557C13.4445 14.1295 12.5739 15.0001 11.5 15.0001Z'
                                stroke='#87898E'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M7.22226 7.22223C8.94045 7.22223 10.3334 5.82934 10.3334 4.11112C10.3334 2.39289 8.94045 1 7.22226 1C5.50404 1 4.11115 2.39289 4.11115 4.11112C4.11115 5.82934 5.50404 7.22223 7.22226 7.22223Z'
                                stroke='#87898E'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </span>
                        </span>
                      </div>
                      <Select
                        value={userSelected}
                        placeholder={billingual.meet}
                        options={user}
                        onChange={userDanon}
                        className='form-control text-start border-none py-3'
                        onInputChange={handleInputChange}
                      />
                      {/* <ErrorMessage errors={errors} name='bertemu_dengan' /> */}
                      {/* <ErrorMessage
                        errors={errors}
                        name='bertemu_dengan'
                        render={() => <p className='text-danger'>'bertemu_dengan' is required !</p>}
                      /> */}
                    </div>
                  </div>
                  <div className='col-12 col-md-12 m-b-1'>
                    <div className='input-group border-1 rounded-1 mb-3'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>
                          <span className='svg-icon svg-icon-1x'>
                            <svg
                              width='18'
                              height='18'
                              viewBox='0 0 18 18'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M1.49701 6.375H8.62201M4.49701 12.375H5.99701M7.87201 12.375H10.872'
                                stroke='#87898E'
                                strokeWidth='1.5'
                                strokeMiterlimit='10'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M16.497 9.0225V12.0825C16.497 14.715 15.8295 15.375 13.167 15.375H4.82701C2.16451 15.375 1.49701 14.715 1.49701 12.0825V5.9175C1.49701 3.285 2.16451 2.625 4.82701 2.625H10.872'
                                stroke='#87898E'
                                strokeWidth='1.5'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M14.307 3.0975L11.5245 5.88C11.4195 5.985 11.3145 6.195 11.292 6.345L11.142 7.41C11.0895 7.7925 11.3595 8.0625 11.742 8.01L12.807 7.86C12.957 7.8375 13.167 7.7325 13.272 7.6275L16.0545 4.845C16.5345 4.365 16.7595 3.81 16.0545 3.105C15.342 2.3925 14.787 2.6175 14.307 3.0975Z'
                                stroke='#87898E'
                                strokeWidth='1.5'
                                strokeMiterlimit='10'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M13.9095 3.495C14.1495 4.3425 14.8095 5.0025 15.6495 5.235'
                                stroke='#87898E'
                                strokeWidth='1.5'
                                strokeMiterlimit='10'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </span>
                        </span>
                      </div>
                      <input
                        {...register('tujuan_bertemu', {
                          required: true,
                        })}
                        onInput={getValue}
                        type='text'
                        className='form-control'
                        placeholder={billingual.purpose}
                        // value={newPayload.tujuan_bertemu}
                        name='tujuan_bertemu'
                        aria-label='tujuan_bertemu'
                        aria-describedby='basic-addon1'
                      />
                      {/* <ErrorMessage errors={errors} name='tujuan_bertemu' /> */}
                      {/* <ErrorMessage
                        errors={errors}
                        name='tujuan_bertemu'
                        render={() => <p className='text-danger'>'tujuan_bertemu' is required !</p>}
                      /> */}
                    </div>
                  </div>
                  <div className='col-12 col-md-12 m-b-1'>
                    <div className='input-group border-1 rounded-1 mb-3'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>
                          <span className='svg-icon svg-icon-1x'>
                            <svg
                              width='20'
                              height='20'
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g clipPath='url(#clip0_194_4067)'>
                                <path
                                  d='M8.24169 4.85833V6.525M11.7584 4.85833V6.525M6.27502 8.45H13.725M10 18.3333C14.6025 18.3333 18.3334 14.6025 18.3334 10C18.3334 5.3975 14.6025 1.66667 10 1.66667C5.39752 1.66667 1.66669 5.3975 1.66669 10C1.66669 14.6025 5.39752 18.3333 10 18.3333Z'
                                  stroke='#87898E'
                                  strokeWidth='1.5'
                                  strokeMiterlimit='10'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <path
                                  d='M11.25 14.725H8.75002C6.66669 14.725 5.41669 13.225 5.41669 11.3917V8.89167C5.41669 7.05833 6.66669 5.55833 8.75002 5.55833H11.25C13.3334 5.55833 14.5834 7.05833 14.5834 8.89167V11.3917C14.5834 13.225 13.3334 14.725 11.25 14.725Z'
                                  stroke='#87898E'
                                  strokeWidth='1.5'
                                  strokeMiterlimit='10'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                              <defs>
                                <clipPath id='clip0_194_4067'>
                                  <rect width='20' height='20' fill='white' />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                        </span>
                      </div>
                      <input
                        {...register('tgl_kedatangan', {
                          required: true,
                          validate: (value, formValues) => {
                            let datetrans = new Date()
                            let datethis = new Date(value)
                            let inv1 = datethis.setDate(datethis.getDate())
                            let inv2 = datetrans.setDate(datetrans.getDate())
                            let inv = (inv1 - inv2) / (3600 * 1000 * 24)
                            const data = Math.round(inv)
                            return data! >= -3 || `Date Forbidden`
                          },
                        })}
                        onInput={datePictures}
                        type='date'
                        // min='2023-05-14'
                        min={disabledDate}
                        className='form-control'
                        placeholder='Tanggal Kedatangan'
                        // value={newPayload.tgl_kedatangan}
                        // name='tgl_kedatangan'
                        aria-label='tgl_kedatangan'
                        aria-describedby='basic-addon1'
                      />
                      {/* <ErrorMessage errors={errors} name='tgl_kedatangan' /> */}
                      {/* <ErrorMessage
                        errors={errors}
                        name='tgl_kedatangan'
                        render={() => <p className='text-danger'>'tgl_kedatangan' is Existing</p>}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-2'>
                <button
                  type='submit'
                  disabled={isLoading}
                  className='btn btn-primary rounded-100 fw-semibold'
                >
                  {isLoading ? 'Loading...' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </div>
        <img src={buttonRight} className='img-illustrasi-side-right d-block d-md-none' alt='' />
        <div className='button-back d-block d-md-none'>
          <a href='identity'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.87866 12L13.9393 3.93934L16.0606 6.06066L10.1213 12L16.0606 17.9393L13.9393 20.0607L5.87866 12Z'
                fill='#585858'
              />
            </svg>
          </a>
        </div>
      </section>
      <Footer />
    </>
  )
}

export {FormRegister}
