import {FC, useState} from 'react'
import clsx from 'clsx'
import shape1 from '../../layout/img/page-1/other-img/Shape-page1-1.png'
import shape2 from '../../layout/img/page-1/other-img/Shape-page1-2.png'
import danone from '../../layout/img/Danone-Logo.png'
import langId from '../../layout/img/page-1/other-img/lang-id.png'
import langUk from '../../layout/img/page-1/other-img/lang-uk.png'
import nutricia from '../../layout/img/page-1/other-img/nutricia.png'
import sarihusada from '../../layout/img/page-1/other-img/sarihusada.png'
import {Helmet} from 'react-helmet'

const HomeWrapper: FC = () => {
  const urls = [
    'type_visitor',
    'factory',
    'form-register',
    'kuisioner',
    'area_khusus',
    'safety_rules',
    'vidio_induction',
    'image',
    'form-area',
    'kode',
    'vidio',
    'visitor',
    'type',
    'noIdentitas',
    'payload',
    'izin-gadget',
    'area-zona',
    'check_in',
    'expirate',
    'post_test',
    'kuisioner_area'
  ]
  urls.forEach(element => {
    localStorage.removeItem(element)
  });

  const [indoActive, setIndoActive] = useState(true)
  const [enActive, setEnActive] = useState(false)

  const langIndo = (e: any) => {
    e.preventDefault()
    setEnActive(!setEnActive)
    setIndoActive(true)
    localStorage.setItem('language', 'id')

  }
  const langEn = (e: any) => {
    e.preventDefault()
    setEnActive(true)
    setIndoActive(!setIndoActive)
    localStorage.setItem('language', 'en')
  }

  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section id='vms-Page-1' className='full-section bg-img-page-1 overflow-hidden'>
        <img src={shape1} className='shape-page-1' alt='' />
        <div className='container'>
          <div className='text-center'>
            <img src={danone} className='img-danone' alt='' />
          </div>
          <div className='text-center my-5'>
            <p className='text-uppercase font-1 fade-in-text text-dark-gray'>welcome to</p>
            <h1 className='text-uppercase font-2 fade-in-text text-dark-gray mb-0'>
              Visitor management system
            </h1>
            <p className='font-3 text-dark-gray'>Danone SN Indonesia</p>
          </div>
          <div className='text-center'>
            <a
              href='choose-factory'
              className='btn btn-primary rounded-0 text-uppercase fw-semibold'
              style={{width: '230px'}}
            >
              get started
            </a>
          </div>
          <div className='text-center mt-4'>
            <a
              href='status-check'
              className='btn btn-success rounded-0 text-uppercase fw-semibold'
              style={{width: '230px'}}
            >
              check status
            </a>
          </div>
        </div>
        <img src={shape2} className='shape-page-2' alt='' />
        <div className='lang-sponsor-wrapper d-flex flex-wrap align-items-center justify-content-center px-4'>
          <div className='p-2 lang-wrapper-page-1'>
            <a href="#indonesian"  onClick={langIndo}>
            {indoActive && <span><i className="text-success fa-solid fa fa-circle-check"></i></span>}
            <img src={langId} className='border-1 me-lg-4' alt='' />
            </a>
            <a href="#english" onClick={langEn}>
            {enActive && <span><i className="text-success fa-solid fa fa-circle-check"></i></span>}
            <img src={langUk} className='border-1' alt='' />
            </a>
          </div>
          <div className='sponsor-wrapper-page-1 ms-lg-auto pb-sm-3'>
            <img src={nutricia} className='img-sponsor-page-1' alt='' />
            <img src={sarihusada} className='img-sponsor-page-1' alt='' />
          </div>
        </div>
      </section>
    </>
  )
}

export {HomeWrapper}
