import {FC, useEffect, useState} from 'react'
import topRight from '../../layout/img/page-14/img-other/top-right.png'
import danone from '../../layout/img/Danone-Logo.png'
import {useApi} from '../../hooks/useApi'
import {Post} from '../../models/posts'
import Alert from 'react-bootstrap/Alert'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useNavigate} from 'react-router-dom'

import {Helmet} from 'react-helmet'
import {efectiveDate} from '../../models/efectiveDate'

const API_URL = process.env.REACT_APP_API_BASE_URL

// window.location.protocol + '//' + window.location.hostname + ':' + process.env.REACT_APP_PORT

const givenResult = (questions: any, answers: any, lang: string) => {
  let complete = true
  let remed: any = []
  let addIndex = 1
  questions.forEach((question: any, index: number) => {
    let answerParse = JSON.parse(`${question.answer}`)
    if (lang === 'en') {
      answerParse = JSON.parse(`${question.answer_en}`)
    }
    answerParse.find(function (answerCorrect: string) {
      if (answers[question.id] === undefined) {
        complete = false
      }
      if (answers[question.id] !== answerCorrect) {
        remed.push({
          wrongPost: addIndex + index,
        })
      }
      return question.answer
    })
  })
  return {
    complete,
    remed,
  }
}
const PostTest: FC = () => {
  const navigate = useNavigate()
  const {getAll} = useApi()
  const [postTest, setPostTest] = useState<Post[]>([])
  const [answers, setAnswers] = useState([])
  const [lang, setLang] = useState('id')
  const [billingual, setBillingual] = useState({
    subtitle: 'Terkait Safety',
    desc: 'Harap isi keseluruhan kuisioner yang telah kami sediakan',
    fillQuestions: 'Pertanyaan wajib Diisi !!',
    wrongAnswer: 'Jawaban anda salah di nomor :',
  })

  const [alertFiil, setAlertFiil] = useState({
    show: false,
    text: '',
    color: '',
  })
  const loginSchema = Yup.object().shape({
    // answer1: Yup.string()
    // .required('Harus diisi !'),
  })
  const initialValues = {
    // answer1: '',
  }
  const getMultipleRandom = (arr: Post[], num: number) => {
    const shuffled = [...arr].sort(() => 0.5 - Math.random())

    return shuffled.slice(0, num)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function fetchData() {
    let factory: any = localStorage.getItem('factory')
    if (factory) {
      factory = JSON.parse(factory)
    }
    const eparams = {
      date_owner: 'post_test',
      factory_id: factory['factory_id'],
      '!sort[efective_date]': -1,
    }
    getAll<efectiveDate>('efectiveDate', eparams).then((response) => {
      const {data} = response.data
      const result = data.filter((entries) => {
        const current = new Date().toISOString().split('T')[0]
        const eDate = new Date(entries.efective_date).toISOString().split('T')[0]
        return eDate <= current
      })
      const params = {
        status_show: 'active',
        factory_id: factory['factory_id'],
        efective_date_id: result[0].id,
      }
      getAll<Post>('posts', params).then((response) => {
        const {data} = response.data
        const randomQuestion = getMultipleRandom(data, 5)
        setPostTest(randomQuestion)
      })
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [iconFactory, setIconFactory] = useState('')
  const [labelFactory, setLabelFactory] = useState('')

  useEffect(() => {
    const getLocalLabel = localStorage.getItem('factory')

    if (getLocalLabel) {
      const parse = JSON.parse(getLocalLabel)
      const icon = `${API_URL}/maps/${parse.icon}`
      setIconFactory(icon)
      setLabelFactory(parse.factoryName)
    }
    const getLang = localStorage.getItem('language')
    if (getLang === 'en') {
      setLang(getLang)
      setBillingual({
        subtitle: 'Related to Safety',
        desc: 'Please complete the fields below',
        fillQuestions: 'Please ensure all questions are answered.',
        wrongAnswer: 'You provided an incorrect response for question #',
      })
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async () => {
      try {
        const {complete, remed} = givenResult(postTest, answers, lang)
        if (!complete) {
          setAlertFiil({
            show: true,
            text: billingual.fillQuestions,
            color: 'danger',
          })
          return
        }
        if (remed.length > 0) {
          let question_id = remed.map((x: any) => x.wrongPost)
          setAlertFiil({
            show: true,
            text: billingual.wrongAnswer + question_id,
            color: 'danger',
          })
          return
        }
        let results = {
          post_test: true,
          visitor_answer: {},
        }
        let answerResults = []
        for (let idQuestion = 0; idQuestion < Object.keys(answers).length; idQuestion++) {
          answerResults.push({
            post_id: parseInt(Object.keys(answers)[idQuestion]),
            visitor_answer: Object.values(answers)[idQuestion],
            status_answer: true,
          })
          results.visitor_answer = answerResults
        }
        localStorage.setItem('post_test', JSON.stringify(results))
        setAlertFiil({
          show: true,
          text: 'Congratulations! You have successfully passed the post-test.',
          color: 'success',
        })
        setTimeout(() => {
          navigate('/quesioner')
        }, 2000)
      } catch (error) {
        console.error(error)
      }
    },
  })

  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section className='d-flex flex-column align-items-center '>
        <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
          <img src={danone} alt='' width={220} />
          <img src={iconFactory} alt='' width={100} />
        </div>
        <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
        <div className='row gx-10 align-items-center w-100'>
          <div className='col-12 col-md-6 text-center-sm'>
            <h2 className='d-none d-md-block'>{labelFactory} Factory</h2>
            <h1>Post Test</h1>
            <h3 className='d-none d-md-block'>{billingual.subtitle}</h3>
            <p className='d-md-block d-none'>{billingual.desc}</p>
          </div>
          <div className='col-12 col-md-6'>
            <form onSubmit={formik.handleSubmit}>
              <Alert
                dismissible
                onClose={() => setAlertFiil({...alertFiil, show: false})}
                show={alertFiil.show}
                variant={alertFiil.color}
              >
                {alertFiil.text}
              </Alert>
              <div className='p-6 border-md overflow-auto h-500px scroll-style-4'>
                <ol>
                  {postTest.map((post: Post) => {
                    return (
                      <li key={post.id} className='my-5 text-wrap'>
                        {lang === 'en' ? post.question_en : post.question}
                        <div className='mt-3'>
                          {JSON.parse(`${lang === 'en' ? post.option_en : post.option}`)?.map(
                            (opt: string, indexOpt: number) => {
                              return (
                                <div className='form-check form-check-small' key={indexOpt}>
                                  <input
                                    className='form-check-input'
                                    type='radio'
                                    data-question={post.id}
                                    name={`answer${post.id}`}
                                    id={`flexRadioDefault${post.id}${indexOpt}`}
                                    value={opt}
                                    onChange={() =>
                                      setAnswers((answer) => ({
                                        ...answer,
                                        [post.id]: opt,
                                      }))
                                    }
                                  />
                                  <label
                                    className='form-check-label text-black mb-2'
                                    htmlFor={`flexRadioDefault${post.id}${indexOpt}`}
                                  >
                                    {opt}
                                  </label>
                                </div>
                              )
                            }
                          )}
                        </div>
                      </li>
                    )
                  })}
                </ol>
              </div>
              <div className='text-center mt-10'>
                <button className='btn btn-primary rounded-100 fw-semibold' type='submit'>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}
export {PostTest}
