/* eslint-disable jsx-a11y/alt-text */
import {FC, useRef, useState, useEffect} from 'react'
import topRight from '../../layout/img/page-7/img-other/top-right.png'
import illustration from '../../layout/img/page-7/img-other/illustrationpng.png'
import sideRight from '../../layout/img/page-7/img-other/side-right.png'
import danone from '../../layout/img/Danone-Logo.png'
import {Footer} from '../../components/footer/Footer'
import Webcam from 'react-webcam'
import {useNavigate} from 'react-router-dom'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Alert from 'react-bootstrap/Alert'
import {Helmet} from 'react-helmet'

const API_URL = process.env.REACT_APP_API_BASE_URL

const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: 'user',
}
const IdentityWrapper: FC = () => {
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)
  const [camera, setCamera] = useState(false)
  const [alertFiil, setAlertFiil] = useState({
    show: false,
    text: '',
    color: '',
  })
  const [labelFactory, setLabelFactory] = useState('')
  const [iconFactory, setIconFactory] = useState('')
  const [billingual, setBillingual] = useState({
    title :'IDENTITAS',
    desc : `Visitor di wajibkan mengambil photo atau melakukan upload dari galeri untuk
    kelengkapan data kami`,
    iam : 'SAYA ADALAH',
    contractor_non :'KONTRAKTOR-NON RUTIN'
  })

  useEffect(() => {
    const getLocalLabel = localStorage.getItem('factory')
    if (getLocalLabel) {
      const parse = JSON.parse(getLocalLabel)
      const icon = `${API_URL}/maps/${parse.icon}`
      setIconFactory(icon)
      setLabelFactory(parse.factoryName)
    }
    const getLang = localStorage.getItem('language')
    if(getLang === 'en'){
      setBillingual ({
        title :'IDENTITY',
        desc : `Visitors must take a self-picture or do self-picture upload from your gallery as our completion data`,
        iam : 'I AM A :',
        contractor_non :'CONTRACTOR NON-ROUTINE'
      })
    }
  }, [])
  const fileGaleri = (e: any) => {
    const img = e.target.files
    const oFReader = new FileReader()
    oFReader.readAsDataURL(img[0])
    oFReader.onload = () => {
      const result = oFReader.result
      setPicture(result)
      setShow(false)
    }
  }
  const OpenCamera = () => {
    // navigate ('/camera')
    setCamera(true)
    setShow(false)
  }
  const initialRef: any = null
  const webcamRef = useRef(initialRef)
  const capture = () => {
    const imgsrc = webcamRef.current.getScreenshot()
    setPicture(imgsrc)
  }
  const approve = () => {
    setCamera(false)
  }
  let existingStorage = ''
  let storageImg = ''
  const data = localStorage.getItem('type_visitor')
  const localImg = localStorage.getItem('image')
  if (localImg) {
    storageImg = localImg
  }
  if (data) {
    const parse = JSON.parse(data)
    existingStorage = parse.type_visitor
  }
  const [picture, setPicture] = useState<any | null>(storageImg)
  const [selected, setSelected] = useState(existingStorage)
  const [typeVisitor, setTypeVisitor] = useState(existingStorage)
  const valueVisitor = (e: any) => {
    setTypeVisitor(e.target.value)
    setSelected(e.target.value)
  }

  const handlerSubmit = async (e: any) => {
    e.preventDefault()
    if (typeVisitor !== '' && picture !== '') {
      const newPayload = {
        type_visitor: typeVisitor,
      }
      localStorage.setItem('type_visitor', JSON.stringify(newPayload))
      localStorage.setItem('image', picture)

      navigate('/form-register')
    } else {
      setAlertFiil({
        show: true,
        text: 'All Field Is Required',
        color: 'danger',
      })
    }
  }

  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      {camera === true ? (
        <>
          <div className='text-center d-flex align-items-center flex-column justify-content-center h-100'>
            {picture !== '' ? (
              <>
                <div style={{height: 650, width: 400, paddingTop: 125}}>
                  <img src={picture} />
                </div>
                <div>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      setPicture('')
                    }}
                    className='btn btn-primary'
                  >
                    Retake
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      approve()
                    }}
                    className='btn btn-success ms-1'
                  >
                    OKE
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className='text-center d-flex align-items-center flex-column justify-content-center h-100'>
                  <Webcam
                    audio={false}
                    height={650}
                    ref={webcamRef}
                    width={400}
                    screenshotFormat='image/jpeg'
                    videoConstraints={videoConstraints}
                  />
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      capture()
                    }}
                    className='btn btn-danger'
                  >
                    Capture
                  </button>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <section
          id='vms-page-7'
          className='d-flex flex-column align-items-center justify-content-center'
        >
          <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
            <img src={danone} alt='' width={220} />
            <img src={iconFactory} alt='' width={100} />
          </div>
          <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
          <div className='container pt-0'>
            <form onSubmit={handlerSubmit}>
              <Alert
                dismissible
                onClose={() => setAlertFiil({...alertFiil, show: false})}
                show={alertFiil.show}
                variant={alertFiil.color}
              >
                {alertFiil.text}
              </Alert>
              <div className='row row align-items-center'>
                <div className='col-12 col-md-6 text-center-sm'>
                  <h1 className='fw-bold'>{billingual.title}</h1>
                  <h2 className='text-subtitle'>{labelFactory} Factory</h2>
                  <p className='py-3'>
                    {billingual.desc}
                  </p>
                  <a href='choose-factory' className='btn btn-secondary btn-md-back mt-3'>
                    Back
                  </a>
                </div>
                <div className='col-12 col-md-3 text-center'>
                  {picture === '' ? (
                    <img
                      src={illustration}
                      className='my-4 img-mobile-responsive img-contain'
                      onClick={handleShow}
                      alt=''
                    />
                  ) : (
                    <img
                      src={picture}
                      className='my-4 rounded-circle img-mobile-responsive img-cover'
                      onClick={handleShow}
                      alt=''
                    />
                  )}
                  <Offcanvas show={show} onHide={handleClose} placement={'bottom'} name={'bottom'}>
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>Choice Or Take Photo</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className='row align-items-center text-black py-0 px-2 text-center'>
                      <div className='col-6 text-center align-items-center'>
                        <label onClick={OpenCamera}>
                          <i
                            className='bi bi-camera-fill fs-1 text-black'
                            style={{cursor: 'pointer'}}
                          ></i>
                        </label>
                      </div>
                      <div className='col-6 text-center align-items-center'>
                        <label htmlFor='galeri'>
                          <i
                            className='bi bi-card-image text-black fs-1'
                            style={{cursor: 'pointer'}}
                          ></i>
                        </label>
                        <input type='file' id='galeri' hidden onChange={fileGaleri} />
                      </div>
                    </Offcanvas.Body>
                  </Offcanvas>
                  {/* <div className='text-subtitle text-black'>Silahkan ambil foto</div> */}
                </div>
                <div className='col-12 col-md-3 text-center'>
                  <h3 className='mb-2 text-black'>{billingual.iam}</h3>
                  <div className='d-flex flex-column text-start gap-2 my-4'>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='visitor_type'
                        value='visitor'
                        onChange={valueVisitor}
                        id='defaultCheck1'
                        checked={selected === 'visitor'}
                      />
                      <label
                        className='form-check-label text-black text-uppercase'
                        htmlFor='defaultCheck1'
                      >
                        VISITOR
                      </label>
                    </div>
                    {/* <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='visitor_type'
                        value='kontraktor_rutin'
                        onChange={valueVisitor}
                        id='defaultCheck2'
                        checked={selected === 'kontraktor_rutin'}
                      />
                      <label
                        className='form-check-label text-black text-uppercase'
                        htmlFor='defaultCheck2'
                      >
                        KONTRAKTOR RUTIN
                      </label>
                    </div> */}
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='visitor_type'
                        onChange={valueVisitor}
                        value='kontraktor_non_rutin'
                        id='defaultCheck3'
                        checked={selected === 'kontraktor_non_rutin'}
                      />
                      <label
                        className='form-check-label text-black text-uppercase'
                        htmlFor='defaultCheck3'
                      >
                        {billingual.contractor_non}
                      </label>
                    </div>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='visitor_type'
                        onChange={valueVisitor}
                        value='driver'
                        id='defaultCheck4'
                        checked={selected === 'driver'}
                      />
                      <label
                        className='form-check-label text-black text-uppercase'
                        htmlFor='defaultCheck4'
                      >
                        DRIVER
                      </label>
                    </div>
                  </div>
                  <div className='text-center mt-2 mb-5'>
                    <button type='submit' className='btn btn-primary rounded-100 fw-semibold'>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <img src={sideRight} className='d-block d-md-none img-illustrasi-side-right' alt='' />
          <div className='button-back d-block d-md-none'>
            <a href='choose-factory'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M5.87866 12L13.9393 3.93934L16.0606 6.06066L10.1213 12L16.0606 17.9393L13.9393 20.0607L5.87866 12Z'
                  fill='#585858'
                />
              </svg>
            </a>
          </div>

          <Footer />
        </section>
      )}
    </>
  )
}

export {IdentityWrapper}
