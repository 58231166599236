import {FC, useEffect, useState} from 'react'
import shapePage4 from '../../layout/img/page-4/img-other/Shape-page4-1.png'
import shapePage2 from '../../layout/img/page-4/img-other/Shape-page4-2.png'
import welcome from '../../layout/img/newImages/icon 1 1welcome.png'
import danone from '../../layout/img/Danone-Logo.png'
import {Footer} from '../../components/footer/Footer'
import {Helmet} from 'react-helmet'
import {useApi} from '../../hooks/useApi'
const API_URL = process.env.REACT_APP_API_BASE_URL

const Welcome: FC = () => {
  const {getOneById} = useApi()

  const [labelFactory, setLabelFactory] = useState('')
  const [iconFactory, setIconFactory] = useState('')
  const [billingual, setBillingual] = useState({
    welcome: 'Selamat Datang Di',
  })
  const [longName, setLoneName] = useState('')
  useEffect(() => {
    const getLocalLabel = localStorage.getItem('factory')
    if (getLocalLabel) {
      const parse = JSON.parse(getLocalLabel)
      console.log('ini parse', parse)
      getOneById('factory', parse.factory_id).then((response) => {
        const {data} = response
        setLoneName(data.long_name)
        console.log('ini data', data)
      })
      const icon = `${API_URL}/maps/${parse.icon}`
      setIconFactory(icon)
      setLabelFactory(parse.factoryName)
    }
    const getLang = localStorage.getItem('language')
    if (getLang === 'en') {
      setBillingual({
        welcome: 'Welcome to',
      })
    }
  }, [])
  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section id='vms-page-4' className='d-flex flex-column align-items-center '>
        <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
          <img src={danone} alt='' width={220} />
          <img src={iconFactory} alt='' width={100} />
        </div>
        <img src={shapePage4} className='w-100 shape-page-4 d-block d-md-none' alt='' />
        <div className='row gx-10 align-items-center'>
          <div className='col-12 col-md-6 text-center-sm'>
            <h2 className='text-subtitle'>{billingual.welcome}</h2>
            <h1>{longName}</h1>
            <h2 className='text-subtitle'>{labelFactory} Factory</h2>
            <div className='gap-3 d-none d-md-flex'>
              <a href='form-register' className='btn btn-secondary btn-md-back mt-3'>
                Back
              </a>
              <a href='visitor-note' className='mt-3 btn btn-primary rounded-100 fw-semibold'>
                Explore
              </a>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='d-flex flex-column align-items-center'>
              <img src={welcome} className='w-100' alt='' />
              <a
                href='visitor-note'
                className='mt-3 btn btn-primary d-inline-block d-md-none btn-block rounded-100 fw-semibold'
              >
                Explore
              </a>
            </div>
          </div>
        </div>
        <img src={shapePage2} className='img-illustrasi-side-right d-md-none' alt='' />
        <div className='button-back d-block d-md-none'>
          <a href='form-register'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.87866 12L13.9393 3.93934L16.0606 6.06066L10.1213 12L16.0606 17.9393L13.9393 20.0607L5.87866 12Z'
                fill='#585858'
              />
            </svg>
          </a>
        </div>
        <Footer />
      </section>
    </>
  )
}

export {Welcome}
