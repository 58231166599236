import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';

type DashboardVisitor = {
  [key: string]: number|string
}
const CardInduction = ({data,title, badge, statusBadge}: {data:DashboardVisitor[],title:string; badge:string; statusBadge:string}) => {
  return (
    <Card>
      <Card.Body>
        <div className="d-flex bd-highlight">
          <div className=" flex-grow-1 bd-highlight">
            <Card.Title className='text-capitalize fs-3'>{title}</Card.Title>
          </div>
          <div className=" bd-highlight">            
          <Badge bg={badge}>
            <span className='text-uppercase fs-6 text-white'>{statusBadge}</span>
          </Badge>
          </div>
        </div>
        <p className='text-muted'>Group: <span>Visitor</span> </p>
        {data.map((entry, index) => {
          return (
            <div className="d-flex bd-highlight" key={index}>
            <div className=" flex-grow-1 bd-highlight">
              <ListGroup variant="flush">
                <ListGroup.Item><i className='fa fa-user'></i> {entry.nama}</ListGroup.Item>
              </ListGroup>
            </div>
            <div className=" bd-highlight">            
            <span>{entry.no_identitas} <i className='fa fa-id-card'></i></span>
            </div>
          </div>
          )
        })}
      </Card.Body>
    </Card>
  );
}

export default CardInduction;