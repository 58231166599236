import Card from 'react-bootstrap/Card';

const CardInfoTotal = ({title, total}: {title:string;total:string|number}) => {
  return (
    <Card style={{ width: '16rem' }}>
      <Card.Body>
        <div className='d-flex flex-wrap align-items-center justify-content-center flex-column h-100 overflow-hidden mt-4'>
          <div className='text-center mb-4'>
            <h5 className='text-muted text-uppercase'>{title}</h5>
            <h1 className='text-black'>{total}</h1>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default CardInfoTotal;