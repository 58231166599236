import {FC, useState, useEffect} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useLocation, useNavigate, Link} from 'react-router-dom'
import {ErrorMessage} from '@hookform/error-message'
import {useApi} from '../../../../hooks/useApi'
import {useFileCheck} from '../../../../hooks/useModelCheck'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Form from 'react-bootstrap/Form'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Factory} from '../../../../models/factory'
import {UserDanoners} from '../../../../models/user_danoners'
import ClipLoader from 'react-spinners/ClipLoader'
import {areas} from '../../../../models/areas'
import makeAnimated from 'react-select/animated'
import Select from 'react-select'

export const CreatePage: FC = () => {
  const [collectionName, setCollectionName] = useState<string>('')
  const routeParams = useLocation()
  const navigate = useNavigate()
  const routeUrl = routeParams.pathname.replace('/', '')
  const splitCollection = routeUrl.split('/')
  const route = splitCollection[0]
  const {getModel} = useFileCheck()
  const {create, getAll} = useApi()
  const [areas, setAreas] = useState<any>([])
  const animatedComponents = makeAnimated()

  const MySwal = withReactContent(Swal)
  const collectionExist = getModel(`${route}`)
  const {layout} = collectionExist

  const [currentImage, setCurrentImage] = useState<string>('')
  const [previewImage, setPreviewImage] = useState<string>(
    toAbsoluteUrl('/image-danone/user-profile.jpg')
  )
  const [progress, setProgress] = useState<number>(0)
  const [message] = useState<string>('')
  const [factory, setFactory] = useState<Factory[]>([])
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: {errors},
  } = useForm<UserDanoners>()
  const getFactory = async () => {
    const response = await getAll<Factory>('factory')
    const {data} = response.data
    setFactory(data)
    
  }
  const getAreas = async (factory_id: string) => {
    const params:{} = {
      factory_id
    }
    const response = await getAll<areas>('areas', params)
    const {data} = response.data
    let areaOpt: any = data.map(({id, name}) => ({value: id, label: name}))
    setAreas(areaOpt) 
  }
  const changeFactory = (newSelect: string): void => {
    getAreas(newSelect)
    setValue('area_ids', []);
  }
  useEffect(() => {
    const renameCollection = () => {
      const words = route.split('_')
      const titles: string[] = []
      for (const key in words) {
        const word = words[key]
        titles.push(word.charAt(0).toUpperCase() + word.slice(1))
      }
      return titles.join(' ')
    }
    setCollectionName(renameCollection())
    getFactory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const upload = async (payload: any) => {
    const response = await create(`video/upload?bucket=user_danoners`, payload)
    return response
  }
  const onSubmit = async (data: UserDanoners, e: any) => {
    const selectedOptions: any = data.area_ids as unknown
    const areasVal = selectedOptions.map((obj: {value: string}) => obj.value)
    Object.assign(data, {
      area_ids: areasVal,
    })
    setLoading(true)
    const formData = new FormData()
    formData.append('file', currentImage)

    const payload = data
    const response = await upload(formData)
    if (response.data) {
      payload.photo = response.data.name
      await create(`${route}`, payload)
        .then((response) => {
          MySwal.fire({
            title: <strong className='text-black'>Success</strong>,
            html: <i className='text-black'>Data created!</i>,
            icon: 'success',
          })
          navigate(`/${route}`)
        })
        .catch((error) => {
          if (error.response) {
            const {data} = error.response
            MySwal.fire({
              title: <strong className='text-black'>Failed</strong>,
              html: <i className='text-black'>{data.errors[0].message}!</i>,
              icon: 'error',
            })
          }
        })
        .finally(() => setLoading(false))
    }
  }
  const onError = (errors: any, e: any) => console.log(errors, e)
  // for type file Image
  const selectImage = (e: any) => {
    const selectedFiles = e.target.files[0]
    setCurrentImage(selectedFiles)
    setPreviewImage(URL.createObjectURL(selectedFiles))
    setProgress(0)
  }
  //convert image to base 64
  return (
    <div>
      {loading === true ? (
        <div className='loader'>
          <ClipLoader
            color={'#00008B'}
            loading={true}
            size={50}
            aria-label='Loading Spinner'
            data-testid='loader'
            // style={{}}
          />
        </div>
      ) : (
        ''
      )}
      <div className='card card-custom gutter-b'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>
              Create {collectionName}
              <small></small>
            </h3>
          </div>
        </div>
        <div className='card-body'>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className='row'>
              {layout.map((fields: any, index: number) => {
                if (['text'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <Form.Label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </Form.Label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        type='text'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <Form.Text className='text-muted'>{fields.note}</Form.Text>
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['textarea'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <Form.Label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </Form.Label>
                      <textarea
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <Form.Text className='text-muted'>{fields.note}</Form.Text>
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['select'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <div className='form-group'>
                        <label className='text-black'>{fields.label}</label>
                        <select
                          className='form-control form-control-solid'
                          {...register(fields.name)}
                        >
                          {fields.options.map((option: any, indexOpt: number) => (
                            <option key={indexOpt} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <Form.Text className='text-muted'>{fields.note}</Form.Text>
                        <ErrorMessage errors={errors} name={fields.name} />
                        <ErrorMessage
                          errors={errors}
                          name={fields.name}
                          render={() => <p className='text-danger'>{fields.name} is required !</p>}
                        />
                      </div>
                    </div>
                  )
                }
                if (['date'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <Form.Label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </Form.Label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        type='date'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <Form.Text className='text-muted'>{fields.note}</Form.Text>
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['email'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <Form.Label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </Form.Label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                          pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        })}
                        type='email'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <Form.Text className='text-muted'>{fields.note}</Form.Text>
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['image'].indexOf(fields.type) >= 0) {
                  return (
                    <div key={`field-${fields.name}`}>
                      {currentImage && progress > 0 && (
                        <div className='progress my-3'>
                          <div
                            className='progress-bar progress-bar-info'
                            role='progressbar'
                            aria-valuenow={progress}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            style={{width: progress + '%'}}
                          >
                            {progress}%
                          </div>
                        </div>
                      )}

                      {previewImage && (
                        <div>
                          <img
                            className='preview'
                            src={previewImage}
                            alt='User blank logo'
                            width='30%'
                          />
                        </div>
                      )}

                      {message && (
                        <div className='alert alert-secondary mt-3' role='alert'>
                          {message}
                        </div>
                      )}
                      <div className={`mb-4 ${fields.col}`}>
                        <Form.Label className='text-black' htmlFor='input-field'>
                          {fields.label}
                        </Form.Label>
                        <Form.Control
                          {...register(fields.name, {
                            required: true,
                            validate: {
                              lessThan10MB: (files) => files[0]?.size < 5000000 || 'Max 5 mb',
                            },
                          })}
                          type='file'
                          onChange={selectImage}
                          accept='image/*'
                          className='text-black form-control form-control-solid'
                          placeholder={fields.placeholder}
                        />
                        <Form.Text className='text-muted'>{fields.note}</Form.Text>
                        <ErrorMessage errors={errors} name={fields.name} />
                        <ErrorMessage
                          errors={errors}
                          name={fields.name}
                          render={() => <p className='text-danger'>{fields.name} is required !</p>}
                        />
                      </div>
                    </div>
                  )
                }

                return layout
              })}
              <div className={`mb-4 col-6`}>
                <Form.Group>
                  <Form.Label>Factory Id</Form.Label>
                  <Form.Select
                    {...register('factory_id', {
                      required: true,
                    })}
                    onChange={(event) => changeFactory(event.target.value)}
                  >
                    <option value=''>Select Factory</option>
                    {factory.map((entry: Factory) => {
                      return (
                        <option key={entry.id} id='' value={entry.id}>
                          {entry.name}
                        </option>
                      )
                    })}
                  </Form.Select>
                </Form.Group>
                <ErrorMessage errors={errors} name='factory_id' />
                <ErrorMessage
                  errors={errors}
                  name='factory_id'
                  render={() => <p className='text-danger'>Factory is required !</p>}
                />
              </div>
              <div className={`mb-4 col-6`}>
                <div className='form-group'>
                <Form.Label>Choose Area</Form.Label>
                  <Controller
                    name='area_ids'
                    control={control}
                    render={({field}) => (
                      <Select
                      {...register('area_ids', {
                        // required: true,
                      })}
                        {...field}
                        closeMenuOnSelect={true}
                        options={areas}
                        components={animatedComponents}
                        isMulti
                      />
                    )}
                  />
                  <ErrorMessage errors={errors} name='area_ids' />
                  <ErrorMessage
                    errors={errors}
                    name='area_ids'
                    render={() => <p className='text-danger'>Area is required !</p>}
                  />
                </div>
              </div>
            </div>
            <Link to={`/${route}`}>
              <button className='btn btn-secondary cancel'>Cancel </button>
            </Link>
            <button type='submit' className='btn btn-success'>
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
