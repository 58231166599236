import React from 'react';
import Form from 'react-bootstrap/Form'
interface DateInputProps {
    onChange: (value: string) => void;
    fieldForm: any
  }
  const DateInput: React.FC<DateInputProps> = ({ onChange,fieldForm }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    };
  
    return (
        <>
        <Form.Group>
          <Form.Label className='text-black' htmlFor='input-field'>
            {fieldForm.label}
          </Form.Label>
          <Form.Control
            className='form-control form-control-solid custom-select'
            name={fieldForm.name}
            type="date" onChange={handleChange}
          >
        </Form.Control>
        </Form.Group>
          <Form.Text className='text-muted'>{fieldForm.note}</Form.Text>
        </>
    );
  };
  export default DateInput;
