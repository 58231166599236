import {FC, useEffect, useState} from 'react'
import Card from './cardInfoTotal'
// import CardInfoTrends from './trends'
import Highcharts from 'highcharts'
import {KTSVG} from '../../../_metronic/helpers'

import HighchartsReact from 'highcharts-react-official'
import CardInduction from './cardInduction'
import {useApi} from '../../../hooks/useApi'
import {Visitor} from '../../../models/visitor'
import {useAuth} from '../../auth'
import {useQueryResponse} from '../core/QueryResponseProvider'
import DateInput from '../../resources/components/fields/date'
const LiveDashboard: FC = () => {
  type Dashboard = {
    [key: string]: number | string
  }
  const {getAll} = useApi()
  const {isLoading} = useQueryResponse()
  const {currentUser} = useAuth()
  const [form, setForm] = useState({})
  const [dataDashboard, setdataDashboard] = useState<Dashboard>({
    countDriver: 0,
    countVisitor: 0,
    countKontraktorRutin: 0,
    countKontraktorNon: 0,
  })
  const [visitorList, setvisitorList] = useState([
    {
      nama: '',
      no_identitas: '',
    },
  ])
  //ternd
  interface Select2model {
    [key: string]: any
  }
  const [data, setData] = useState<Select2model[]>([])
  const [categories, setCategories] = useState([])
  const payload: {} = {
    [`!auth[authId]`]: currentUser?.id,
    [`!auth[factory_id]`]: currentUser?.factory_id,
    [`!auth[role]`]: currentUser?.role,
  }
  async function fetchData() {
    const response = await getAll<Dashboard>('dashboardLive/dashboardCount/', payload)
    const {countVisitor, countDriver, countKontraktorRutin, countKontraktorNon} = response.data
    setdataDashboard({
      countDriver,
      countVisitor,
      countKontraktorRutin,
      countKontraktorNon,
    })
    const apiUrl = 'dashboardLive/dashboardLineChart/'
    const responseChart = await getAll<Select2model>(apiUrl, payload)
    const {data, dates} = responseChart.data
    setCategories(dates)
    setData(data)
  }
  async function dashboardVisitor() {
    Object.assign(payload, {
      '!limit': 3,
    })
    const response = await getAll<Visitor>('dashboardLive/dashboardVisitor/', payload)
    const {data} = response.data
    const visitor = data.map(({nama, no_identitas}) => ({nama, no_identitas}))
    setvisitorList(visitor)
  }
  const chartOptions = {
    chart: {
      type: 'areaspline',
      // width: 660,
      height: 270,
    },
    title: {
      text: 'Monthly Visitor',
    },
    xAxis: {
      categories: categories,
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    series: data,
  }

  useEffect(() => {
    fetchData()
    dashboardVisitor()
  }, [])
  const handleForm = (name: string, value: string | number | boolean) => {
    if(name ==='startDate'){
      value = `${value} 00:00:00`
    }
    if(name ==='endDate'){
      value = `${value} 23:59:59`
    }
    setForm({...form, [name]: value})
  }
  // const resetData = () => {
  //   setForm({})
  //   fetchData()
  // }
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    Object.assign(payload, {...form})
    fetchData()
  }
  return (
    <>
      <div className='d-flex bd-highlight mb-3'>
        <div className='me-auto p-2 bd-highlight'>
          <h3 className='card-label'>
            <i className='fa fa-signal'></i> Live Dashboard
          </h3>
        </div>
        <div className='p-2 bd-highlight'>
          <button
            disabled={isLoading}
            type='button'
            className='btn btn-light-primary me-3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
            Filter
          </button>
          <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
            {/* begin::Header */}
            <div className='px-7 py-5'>
              <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
            </div>
            {/* end::Header */}

            {/* begin::Separator */}
            <div className='separator border-gray-200'></div>
            {/* end::Separator */}

            {/* begin::Content */}
            <div className='px-7 py-5' data-kt-user-table-filter='form'>
              {/* begin::Form Group */}
              <form>
                  <div className='row'>
                    <div className='col-6'>
                     <DateInput
                     fieldForm= {[
                      {
                        type: 'date',
                        col: 'col-6',
                        name: 'startDate',
                        placeholder: 'Arrive Date',
                        label: 'From Date',
                      }
                     ]}
                     onChange={(e) => handleForm('startDate', e)}
                     />
                    </div>
                    <div className='col-6'>
                    <DateInput
                     fieldForm= {[
                      {
                        type: 'date',
                        col: 'col-6',
                        name: 'endDate',
                        placeholder: 'Arrive Date',
                        label: 'To Date',
                      }
                     ]}
                     onChange={(e) => handleForm('endDate', e)}
                     />
                    </div>
                  </div>
                {/* end::Form Group */}
              </form>
              {/* begin::Actions */}
              <div className='d-flex justify-content-end'>
                {/* <button
                  type='button'
                  disabled={isLoading}
                  onClick={resetData}
                  className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='reset'
                >
                  Reset
                </button> */}
                <button
                  disabled={isLoading}
                  onClick={handleSubmit}
                  className='btn btn-primary fw-bold px-6'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='filter'
                >
                  Apply
                </button>
              </div>
              {/* end::Actions */}
            </div>
            {/* end::Content */}
          </div>
        </div>
      </div>
      <div className='row d-flex justify-content-sm-between'>
        <div className='col-2 mb-3'>
          <Card title='Kontraktor Rutin' total={dataDashboard.countKontraktorRutin} />
        </div>
        <div className='col-2 mb-3'>
          <Card title='Kontraktor Non Rutin' total={dataDashboard.countKontraktorNon} />
        </div>
        <div className='col-2 mb-3'>
          <Card title='Visitor' total={dataDashboard.countVisitor} />
        </div>
        <div className='col-2 mb-3'>
          <Card title='Driver' total={dataDashboard.countDriver} />
        </div>
      </div>
      <div className='row'>
        <div className='col-12 '>
          <div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 mb-3'>
          <CardInduction
            data={visitorList}
            title='card induction'
            badge='success'
            statusBadge='completed'
          />
        </div>
        {/* <div className='col-6 mb-3'>
        <CardInduction data={visitorList} title="induction" badge='warning' statusBadge="process" />
        </div> */}
      </div>
    </>
  )
}

export default LiveDashboard
