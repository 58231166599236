import {FC, useEffect, useState} from 'react'
import topRight from '../../layout/img/page-12/img-other/top-right.png'
import illustrasi2 from '../../layout/img/page-12/img-other/illustrasi2.png'
import illustrasi3 from '../../layout/img/page-12/img-other/illustrasi3.png'
import illustrasi4 from '../../layout/img/page-12/img-other/illustrasi4.png'
import sideRight from '../../layout/img/page-12/img-other/side-right.png'
import danone from '../../layout/img/Danone-Logo.png'
import {Footer} from '../../components/footer/Footer'
import {Helmet} from 'react-helmet'
const API_URL = process.env.REACT_APP_API_BASE_URL

const Emergency: FC = () => {
  const [labelFactory, setLabelFactory] = useState('')
  const [iconFactory, setIconFactory] = useState('')
  const [billingual, setBillingual] = useState({
    geser: 'Tekan Untuk Geser',
    title: 'KONDISI DARURAT',
    descNoPanic: `Apabila anda mendengar sirine sebagai tanda kondisi darurat, jangan panik. Ikuti
    proses evakuasi yang dilakukan oleh karyawan atau ikuti rambu jalur evakuasi
    yang anda temui untuk menuju shelter titik kumpul (area aman).`,
    descEmergency1: `Jika berada dalam kondisi darurat tanpa karyawan disekitar anda, hubungi kontak
    darurat melalui alat telepon terdekat milik perusahaan.`,
    titleEvac: 'EVAKUASI',
    descEvac: `Visitor diwajibkan segera meninggalkan gedung jika terdapat kondisi darurat yang
    memerlukan evakuasi, mohon ikuti arahan petunjuk evakuasi dari tim tanggap
    darurat.`,
  })
  useEffect(() => {
    const getLocalLabel = localStorage.getItem('factory')
    if (getLocalLabel) {
      const parse = JSON.parse(getLocalLabel)
      const icon = `${API_URL}/maps/${parse.icon}`
      setIconFactory(icon)
      setLabelFactory(parse.factoryName)
    }
    const getLang = localStorage.getItem('language')
    if (getLang === 'en') {
      setBillingual({
        geser: 'Press to slide',
        title: 'Emergency Situation',
        descNoPanic: `Do not panic, if you hear an emergency siren. Follow the evacuation process carried out by employees or follow the evacuation route
        signs that you meet to get to the gathering point shelter (safe area).`,
        descEmergency1: `if you are in an emergency without employees around you, call the emergency contact on the nearest company telephone.`,
        titleEvac: 'EVACUATION',
        descEvac: `Visitors are required to leave the building immediately if there is an emergency that requires evacuation, please follow the evacuation instructions
        from the emergency response team`,
      })
    }
  }, [])
  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section
        className='d-flex flex-wrap align-items-center justify-content-center flex-column'
        style={{overflow: 'inherit'}}
      >
        <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
          <img src={danone} alt='' width={220} />
          <img src={iconFactory} alt='' width={100} />
        </div>
        <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
        <div
          id='carouselExampleIndicators'
          className='carousel slide carousel-hygiene'
          data-bs-ride='true'
          data-bs-wrap='false'
        >
          {/* <div className='carousel-indicators'>
            <button
              type='button'
              data-bs-target='#carouselExampleIndicators'
              data-bs-slide-to='0'
              className='active'
              aria-current='true'
              aria-label='Slide 1'
            ></button>
            <button
              type='button'
              data-bs-target='#carouselExampleIndicators'
              data-bs-slide-to='1'
              aria-label='Slide 2'
            ></button>
            <button
              type='button'
              data-bs-target='#carouselExampleIndicators'
              data-bs-slide-to='2'
              aria-label='Slide 3'
            ></button>
          </div> */}
          <div className='carousel-inner'>
            <div className='carousel-item active'>
              <div className='row align-items-center'>
                <div className='col-12 col-md-6'>
                  <div className='text-center-sm mb-10'>
                    <h2>{labelFactory} Factory</h2>
                    <h1>{billingual.title}</h1>
                    <div className='d-md-flex d-none gap-3'>
                      <a href='safety-rules' className='btn btn-secondary btn-md-back'>
                        Back
                      </a>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='mt-3 mb-3 text-center'>
                    <img src={illustrasi2} alt='' width='100' style={{width: 'fit-content'}} />
                  </div>
                  <p className='px-5 mb-5 text-center'>{billingual.descNoPanic}</p>
                </div>
              </div>
            </div>
            <div className='carousel-item'>
              <div className='row align-items-center'>
                <div className='col-12 col-md-6'>
                  <div className='text-center-sm mb-10'>
                    <h2>{labelFactory} Factory</h2>
                    <h1>{billingual.title}</h1>
                    <div className='d-md-flex d-none gap-3'>
                      <a href='safety-rules' className='btn btn-secondary btn-md-back'>
                        Back
                      </a>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='px-4 text-center'>
                    <div className='mt-5 mb-5 text-center'>
                      <img src={illustrasi4} alt='' width='100' style={{width: 'fit-content'}} />
                    </div>
                  </div>
                  <p className='px-5 mb-5 text-center'>{billingual.descEmergency1}</p>
                  {labelFactory.toLowerCase() !== 'ciracas' ? (
                    <div className='text-center'>
                      <h6>JOGJA FACTORY...................1111</h6>
                      <h6>PRAMBANAN FACTORY........8111</h6>
                      <h6>CIRACAS FACTORY...............2222</h6>
                      <h6>SENTUL FACTORY.................2777</h6>
                    </div>
                  ) : (
                    <div className='text-center'>
                      <h6>CIRACAS FACTORY...............2222</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='carousel-item'>
              <div className='row align-items-center'>
                <div className='col-12 col-md-6'>
                  <div className='text-center-sm mb-10'>
                    <h2>{labelFactory} Factory</h2>
                    <h1>{billingual.titleEvac}</h1>
                    <div className='d-md-flex d-none gap-3'>
                      <a href='safety-rules' className='btn btn-secondary btn-md-back'>
                        Back
                      </a>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='mt-5 mb-5 text-center'>
                    <img src={illustrasi3} alt='' width='100' style={{width: 'fit-content'}} />
                  </div>
                  <p className='px-5 mb-5 text-center'>{billingual.descEvac}</p>
                  <div className='mt-10 text-center'>
                    <a href='video' className='btn btn-primary rounded-100 fw-semibold'>
                      Next
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <button
            className='carousel-control-prev'
            type='button'
            data-bs-target='#'
            data-bs-slide='#'
          >
            <span className='carousel-control-prev-icon' aria-hidden='true'></span>
            <span className='visually-hidden'>Previous</span>
          </button> */}
          <button
            className='carousel-control-next ms-1'
            type='button'
            data-bs-target='#carouselExampleIndicators'
            data-bs-slide='next'
          >
            <div className='d-flex bg-white nav-carousel p-4 border'>
              <div className='text-nowrap d-md-none'>Geser</div>
              <div className='text-nowrap d-none d-md-block'>{billingual.geser}</div>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z'
                  fill='#000000'
                  transform='translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) '
                />
              </svg>
            </div>
          </button>
        </div>
        <img src={sideRight} className='img-illustrasi-side-right' alt='' />
        <div className='button-back'>
          <a href='safety-rules'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.87866 12L13.9393 3.93934L16.0606 6.06066L10.1213 12L16.0606 17.9393L13.9393 20.0607L5.87866 12Z'
                fill='#585858'
              />
            </svg>
          </a>
        </div>
        <Footer />
      </section>
    </>
  )
}

export {Emergency}
