// @ts-nocheck
import {Column} from 'react-table'
import {SelectionHeader} from './SelectionHeader'
import {SelectionCell} from './SelectionCell'
import {CustomHeader} from './CustomHeader'
import {ColumnString} from '../../../../components/table/column/ColumnString'
import {useFileCheck} from '../../../../hooks/useModelCheck'
import {useLocation} from 'react-router-dom'
import { Badge } from 'react-bootstrap'
import {ActionsCell} from './ActionsCell'

export const ListPage: FC = () => {
  const location = useLocation()
  const route = location.pathname.replace('/', '')
  const {getModel} = useFileCheck()
  const collectionExist = getModel(`${route}`)
  const {columns} = collectionExist
  const formatDate = (entryDate) => {
    const date = new Date(entryDate)
    const format = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
      '0' + date.getDate()
    ).slice(-2)}`
    return format
  }
  const columntable = [
    {
      Header: (props) => <SelectionHeader tableProps={props} />,
      id: 'selection',
      Cell: ({...props}) => <SelectionCell id={props.data[props.row.index].id} />,
    },
  ]
  for (const key in columns) {
    columntable.push({
      Header: (props) => (
        <CustomHeader tableProps={props} title={columns[key].label} className='min-w-125px' />
      ),
      id: columns[key].name,

      Cell: ({...props}) => {
        const entry = props.data[props.row.index]
        let fill = <ColumnString value={entry[columns[key].name]} />
        //Custom for column
        const fieldCustom = [
          'form_register',
          'safety_rules',
          'vidio_induction',
          'kuisioner',
          'post_test',
        ]
        if (fieldCustom.indexOf(columns[key].name) >= 0 && entry[columns[key].name]) {
          fill = (
            <span className='text-center'>
              <i className='text-success fa fa-circle-check'></i>
            </span>
          )
        }
        if (columns[key].name === 'area_khusus') {
          fill = 'Tidak'
          if (entry[columns[key].name]) {
            fill = 'Ya'
          }
        }
        if (columns[key].name === 'kuisioner') {
          fill = (
            <span className='text-center'>
              <i className='text-success fa fa-circle-check'></i>
            </span>
          )
          if (entry[columns[key].name] && !entry['expirate']) { // gagal kuisioner
            fill = (<span className='text-center'>
            <i className='text-danger fa fa-times'></i>
            </span>
            )
          }
        }
        if (columns[key].name === 'post_test') {
          fill = (
            <span className='text-center'>
              <i className='text-success fa fa-circle-check'></i>
            </span>
          )
          if (!entry[columns[key].name] && !entry['expirate']) { // gagal kuisioner
            fill = (<span className='text-center'>
            <i className='text-danger fa fa-times'></i>
            </span>
            )
          }
        }
        if (columns[key].name === 'no_identitas') {
          fill = (
            <div className='flex-column'>
              <div>{entry[columns[key].name]}</div>
              <div className='text-muted text-uppercase'>{entry['type_identitas']}</div>
            </div>
          )
        }
        if (columns[key].name === 'nama') {
          fill = (
            <div className='d-flex justify-content-sm-between'>
              <div className='me-3'>
                <i className='bi fs-3 bi-person-circle'></i>
              </div>
              <div>{entry[columns[key].name]}</div>
            </div>
          )
        }
        if (columns[key].name === 'factory_id') {
          fill = <ColumnString value={entry.factoryName} />
        }
        if (columns[key].name === 'status_boking') {
          switch (entry[columns[key].name]) {
            case 'reject':
              fill = 
                <Badge bg="danger">Reject</Badge>
              break;
              case 'waiting':
              fill = 
                <Badge bg="warning">Waiting</Badge>
              break;
              case 'approve':
              fill = 
                <Badge bg="success">Approve</Badge>
              break;
            default:
              fill = <ColumnString value={entry.status_boking} />

              break;
          }
        }
        if (columns[key].name === 'bertemu_dengan') {
          fill = <ColumnString value={entry.userDanone} />
        }
        if (columns[key].name === 'tgl_kedatangan'&& entry[columns[key].name]) {
          fill = formatDate(entry[columns[key].name])
        }
        if (columns[key].name === 'expirate' && entry[columns[key].name]) {
          fill = formatDate(entry[columns[key].name])
        }
        if (columns[key].name === 'check_in' && entry[columns[key].name]) {
          fill = formatDate(entry[columns[key].name])
        }
        if (columns[key].name === 'approval_date' && entry['approval_date']) {
          fill = formatDate(entry[columns[key].name])
        }
        if (columns[key].name === 'approval_owner_date' && entry['approval_owner_date']) {
          fill = formatDate(entry[columns[key].name])
        }
        //end Custom for column
        return fill
      },
    })
  }
  columntable.push(
    {
      Header: (props) => (
        <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
      ),
      id: 'actions',
      Cell: ({...props}) => <ActionsCell id={props.data[props.row.index].id} />,
    },
  )
  const usersColumns: ReadonlyArray<Column<any>> = columntable
  return {
    usersColumns,
  }
}
