export interface MasterMaps {
    factory_id: number
    icon:string
    image:string
    land_area:string
    building_area:string
    factoryName:string
  }
  export const useCollection = () => {
    const collection = 'master_maps'
  
    const columns = [
      {
        name: 'factory_id',
        required: true,
        label: 'factory_id',
        align: 'left',
        field: 'factory_id',
        sortable: true
      },
      {
        name: 'image',
        required: true,
        label: 'image',
        align: 'left',
        field: 'image',
        sortable: true
      },
      {
        name: 'land_area',
        required: true,
        label: 'land_area',
        align: 'left',
        field: 'land_area',
        sortable: true
      },
      {
        name: 'building_area',
        required: true,
        label: 'building_area',
        align: 'left',
        field: 'building_area',
        sortable: true
      },
    ]
    const filter = [{
      type: 'reference',
      advanceFilter: true,
      col: 'col-6',
      name: 'factory_id',
      placeholder: 'Factory',
      label: 'Select Factory',
      reference: 'factory',
      option : {
        value: 'id',
        label : 'name'
      }
    }]

    return {
      collection,
      columns,
      filter

    }
  }