
import {FC, useEffect, useState} from 'react'
import illustrasi3 from '../../layout/img/page-14/img-other/icon 3 - hygene rule 1 (9).png'
import topRight from '../../layout/img/page-14/img-other/top-right.png'
import danone from '../../layout/img/Danone-Logo.png'
import prambanan from '../../layout/img/page-4/img-other/prambanan.png'
import {Helmet} from 'react-helmet'

const Hasil: FC = () => {
  const [kode, setKode] = useState('')
  const [billingual, setBillingual] = useState({
    title : 'Hasil Induction Sedang Ditinjau',
    desc : 'Tunggu approval dari user yang ingin anda ditemui hasil Approval akan kami kirimkan ke email anda.',
    notes : `Cek berkala status approval anda pada button check status yang terdapat pada halaman
    awal dengan memasukkan kode booking diatas`,
    titleCode : 'KODE BOKING'
  })
  useEffect(() => {
    const kodeRandom = localStorage.getItem('kode')
    if(kodeRandom){
      setKode(kodeRandom)
    }
    const getLang = localStorage.getItem('language')
    if(getLang === 'en'){
      setBillingual ({
        title : 'Induction Results are being Reviewed',
        desc :'Please wait for the approval from the respective person of your visit, we will send the approval status to your email address.',
        notes : `Frequently verify and check your approval status on the check status button on the start page by entering the booking code above.`,
        titleCode : 'BOOKING CODE'
      })
    }
  }, [])
  return (
    <>
    <Helmet>
      <link rel="stylesheet" href="/visitor.css" />
    </Helmet>
    <section className='d-flex flex-column align-items-center '>
      <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
        <img src={danone} alt='' width={220} />
        <img src={prambanan} alt='' width={100} />
      </div>
      <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
      <div className='row gx-10 align-items-center'>
        <div className='col-12 col-md-6 text-center-sm d-none d-md-block'>
          <h1 className='fw-bold text-black'>{billingual.title}</h1>
          <p className='mt-10'>
          {billingual.desc}
          </p>
        </div>
        <div className='col-12 col-md-6 text-center-sm d-md-none'>
        <img src={illustrasi3} className='mb-2 w-100' alt='' />
          <h1 className='fw-bold text-blue text-uppercase'>{billingual.title}</h1>
          <p className='mb-10'>
          {billingual.desc}
          </p>
        </div>
        <div className='col-12 col-md-6 text-center'>
          <img src={illustrasi3} className='mb-2 w-100 d-none d-md-block' alt='' />
          <div className='text-center'>
            <h5>{billingual.titleCode}</h5>
            <input type='text' value={kode} className='form-control text-center' disabled />
          </div>
          <p className='text-center mt-4'>
            {billingual.notes}
          </p>
          <div className='mt-10'>
            <a href='status-check' className='btn btn-primary rounded-100 fw-semibold'>
              Back To Check
            </a>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export {Hasil}
