export interface efectiveDate {
    id:number
    efective_date: string
    factory_id:string
    date_owner:string
  }
  export const useCollection = () => {
    const collection = 'efectiveDate'
    const forms: efectiveDate = {
      id:0,
      efective_date: '',
      factory_id:'',
      date_owner:''
    }
  
    const columns = [
      {
        name: 'efective_date',
        required: true,
        label: 'Efective Date',
        align: 'left',
        field: 'efective_date',
        sortable: true
      },
      // Always give this columns as default
      {
        name: 'factory_id',
        required: true,
        label: 'Factory',
        align: 'left',
        field: 'factory_id',
        sortable: true
      },
      {
        name: 'date_owner',
        required: true,
        label: 'Efective Date Owner',
        align: 'left',
        field: 'date_owner',
        sortable: true
      }
    ]
    const layout = [
  
        {
          type: 'date',
          col: 'col-4',
          name: 'efective_date',
          placeholder: 'Efective Date',
          label: 'Efective Date',
          required: true
        },
        {
          type: 'select',
          col: 'col-4',
          name: 'factory_id',
          placeholder: 'Factory Id',
          label: 'Factory Id',
          required: true
  
        },  
        {
          type: 'select',
          col: 'col-4',
          name: 'date_owner',
          placeholder: 'Efective Date Owner',
          label: 'Efective Date Owner',
          required: true
  
        }  
    ]
    const filter = [{
      type: 'reference',
      advanceFilter: true,
      col: 'col-6',
      name: 'factory_id',
      placeholder: 'Factory',
      label: 'Select Factory',
      reference: 'factory',
      option : {
        value: 'id',
        label : 'name'
      }
    },
    {
      type: 'date',
      col: 'col-6',
      advanceFilter: true,
      name: 'efective_date',
      placeholder: 'Efective Date',
      label: 'Efective Date'
    },
  ]

    return {
      collection,
      forms,
      columns,
      layout,
      filter

    }
  }