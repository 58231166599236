import {FC, useEffect, useState, useRef} from 'react'
import topRight from '../../layout/img/page-13/img-other/top-right.png'
import sideRight from '../../layout/img/page-13/img-other/side-right.png'
import danone from '../../layout/img/Danone-Logo.png'
import loading from '../../layout/img/loading.gif'
import {Footer} from '../../components/footer/Footer'
import {useNavigate} from 'react-router-dom'
import {Videos} from '../../models/videos'
import {useApi} from '../../hooks/useApi'
import {Helmet} from 'react-helmet'
import { PublicVisitor } from '../../models/publicVisitor'
const API_URL = process.env.REACT_APP_API_BASE_URL

const Vidio: FC = () => {
  const navigate = useNavigate()
  const [disabledNext, setDisabledNext] = useState(true)
  const {getAll} = useApi()
  const [valVidio, setValvidio] = useState<string>('')
  const [labelFactory, setLabelFactory] = useState('')
  const [iconFactory, setIconFactory] = useState('')
  const [checkIn, setCheckIn] = useState('')
  const [expirete, setExpirete] = useState('')
  const [volumeOn, setVolumeOn] = useState(true)
  const [visitorIdBefore, setVisitorIdBefore] = useState('')
  const [isIconDisabled, setIsIconDisable] = useState(true)
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  async function fetchData() {
    const factory = localStorage.getItem('factory')
    let facParse
    if (factory) {
      const parse = JSON.parse(factory)
      facParse = parse
      const icon = `${API_URL}/maps/${parse.icon}`
      setIconFactory(icon)
      setLabelFactory(parse.factoryName)
    }

    const payload = {
      factory_id: facParse['factory_id'],
    }
    const response = await getAll<Videos>('videos', payload)
    const {data} = response.data
    const value = API_URL + `/${data[0].path}/` + data[0].name
    setValvidio(value)
    setIsLoading(false);
  }
  const fullVidio = () => {
    const data = localStorage.getItem('vidio')

    if (data) {
      setDisabledNext(false)
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    const formRegister = localStorage.getItem('form-register')
    const factory = localStorage.getItem('factory')
    if (formRegister && factory) {
      const parse = JSON.parse(formRegister)
      const factoryParse = JSON.parse(factory)
      const params = {
        type_identitas: parse.type_identitas,
        no_identitas: parse.no_identitas,
        factory_id: factoryParse['factory_id'],
        '!sort[id]': -1,
      }
      getAll<PublicVisitor>('public-visitor', params).then((response) => {
        const {data} = response.data
        const currentDate = new Date().toISOString().split('T')[0]
        setCheckIn(currentDate)
        const expDate = new Date()
        expDate.setDate(expDate.getDate() + 7)
        let newExp = expDate.toISOString().split('T')[0]
        if (
          data.length !== 0 &&
          new Date(data[0].expirate).toISOString().split('T')[0] > currentDate
        ) {
          newExp = new Date(data[0].expirate).toISOString().split('T')[0]
          setDisabledNext(false)
          setVisitorIdBefore(data[0].id)
        } else if (
          data.length !== 0 &&
          new Date(data[0].expirate).toISOString().split('T')[0] < currentDate
        ) {
          setDisabledNext(true)
        }
        setExpirete(newExp)
      })
    }
    fullVidio()
    // falseHidden()
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const vidioInduction = () => {
    const vidio = {
      vidio_induction: true,
    }
    localStorage.setItem('visitorIdBefore', JSON.stringify(visitorIdBefore))
    localStorage.setItem('vidio_induction', JSON.stringify(vidio))
    localStorage.setItem('check_in', JSON.stringify(checkIn))
    localStorage.setItem('expirate', JSON.stringify(expirete))
    navigate('/post-test')
  }

  const handleVideoEnd = () => {
    localStorage.setItem('vidio', JSON.stringify(true))
    setDisabledNext(false)
  }
  const volumeMute = () => {
    setVolumeOn(false)
    setIsIconDisable(false)
  }
  const volumeUnMute = () => {
    setVolumeOn(true)
    setIsIconDisable(true)
  }
  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  const toggleFullscreen = () => {
    if (videoRef.current) {
        videoRef.current.requestFullscreen();
    }
  };
  return (
    <>
      <Helmet>
        <link rel='stylesheet' href='/visitor.css' />
      </Helmet>
      <section>
        <div className='d-flex flex-wrap align-items-center justify-content-center flex-column h-100 overflow-hidden'>
          <div className='header d-none d-md-flex justify-content-between align-items-center w-100'>
            <img src={danone} alt='' width={220} />
            <img src={iconFactory} alt='' width={100} />
          </div>
          <img src={topRight} className='shape-page-1 img-shape-1' alt='' />
          <div className='row gx-10 align-items-center w-100'>
            <div className='col-12 col-md-6 text-center-sm'>
              <h2>{labelFactory} Factory</h2>
              <h1>Video Safety Induction</h1>
              <div className='d-md-flex d-none gap-3 mt-10'>
                <a href='emergency' className='btn btn-secondary btn-md-back'>
                  Back
                </a>
                <button
                  onClick={vidioInduction}
                  className='btn btn-primary rounded-100 fw-semibold'
                  hidden={disabledNext}
                >
                  Next
                </button>
              </div>
            </div>
            <div className='col-12 col-md-6'>
              <div className='d-flex flex-column flex-wrap align-content-center justify-content-start'>
              {isLoading ? (
                  <img src={loading} alt='Loading...'width="20%" className='mb-10 mt-3' />
              ) : (
                <video
                // controls
                // controlsList='nodownload noremoteplayback noplaybackrate'
                ref={videoRef}
                onEnded={handleVideoEnd}
                width='100%'
                height='300'
                autoPlay={true}
                muted={volumeOn}
              >
                <source key={valVidio} src={valVidio} type='video/mp4' />
                </video>
              )}
              </div>
              <div className='d-flex justify-content-center'>
              <button className='rounded mx-2' title="Volume">
              {isIconDisabled? <i className="fa fa-volume-xmark p-3" onClick={volumeMute}></i> : <i className="fa fa-volume-high p-3" onClick={volumeUnMute}></i>}
              </button>
              <button onClick={handlePlayPause} className='rounded mx-2' title="Play/Pause">
              {isPlaying ? <i className="fa fa-pause p-3"></i> : <i className="fa fa-play p-3"></i>}
              </button>
              <button onClick={toggleFullscreen} className='rounded mx-2' title="Fullscreen"><i className="fa fa-expand p-3"></i></button>
              </div>
              <div className='mt-10 d-md-none d-block text-center'>
                <button
                  onClick={vidioInduction}
                  className='btn btn-primary rounded-100 fw-semibold'
                  hidden={disabledNext}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          <img src={sideRight} className='img-illustrasi-side-right' alt='' />
        </div>
        <div className='button-back'>
          <a href='emergency'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.87866 12L13.9393 3.93934L16.0606 6.06066L10.1213 12L16.0606 17.9393L13.9393 20.0607L5.87866 12Z'
                fill='#585858'
              />
            </svg>
          </a>
        </div>
        <Footer />
      </section>
    </>
  )
}

export {Vidio}
