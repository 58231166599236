import React, {useEffect, useRef, useState} from 'react'
import $ from 'jquery'
import 'select2'
import {useApi} from '../../../../hooks/useApi'
import Form from 'react-bootstrap/Form'
interface Select2Props {
  onChange: (value: string) => void
  fieldForm: any
}
interface Select2model {
  [key: string]: any
}
const Select2: React.FC<Select2Props> = ({onChange, fieldForm}) => {
  const [options, setOptions] = useState<Select2model[]>([])
  const selectRef = useRef<HTMLSelectElement>(null)
  const {getAll} = useApi()
  const apiUrl = fieldForm.reference
  const dataSelect2 = async () => {
    const response = await getAll<Select2model>(apiUrl, fieldForm.param)
    const {data} = response.data
    setOptions(data)
  }
  useEffect(() => {
    dataSelect2()
  }, [apiUrl])

  useEffect(() => {
    // if (selectRef.current) {
    //   $(selectRef.current).select2();
    // }

    // Clean up Select2 instance when the component is unmounted
    return () => {
      if (selectRef.current) {
        $(selectRef.current).select2('destroy')
      }
    }
  }, [])

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value
    onChange(selectedValue)
  }

  return (
    <>
    <Form.Group>
      <Form.Label className='text-black' htmlFor='input-field'>
        {fieldForm.label}
      </Form.Label>
      <Form.Select
        className='form-control form-control-solid custom-select'
        name={fieldForm.name}
        ref={selectRef}
        onChange={handleSelectChange}
      >
        <option value=''>--Select--</option>
        {options.map((option) => (
          <option key={option[fieldForm.option.value]} value={option[fieldForm.option.value]}>
            {option[fieldForm.option.label]}
          </option>
        ))}
    </Form.Select>
    </Form.Group>
      <Form.Text className='text-muted'>{fieldForm.note}</Form.Text>
      </>
  )
}

export default Select2
