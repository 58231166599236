// @ts-nocheck
import {Column} from 'react-table'
import {SelectionHeader} from './SelectionHeader'
import {SelectionCell} from './SelectionCell'
import {CustomHeader} from './CustomHeader'
import {ColumnString} from '../../../../components/table/column/ColumnString'
import {ActionsCell} from './ActionsCell'
import {useFileCheck} from '../../../../hooks/useModelCheck'
import {Link} from 'react-router-dom'

import {useLocation} from 'react-router-dom'

export const ListPage: FC = () => {
  const location = useLocation()
  // const route = 'efectiveDate_questions'
  const route = location.pathname.replace('/', '')
  const {getModel} = useFileCheck()
  const collectionExist = getModel(`${route}`)
  const {columns} = collectionExist

  const columntable = [
    {
      Header: (props) => <SelectionHeader tableProps={props} />,
      id: 'selection',
      Cell: ({...props}) => <SelectionCell id={props.data[props.row.index].id} />,
    },
  ]
  for (const key in columns) {
    columntable.push({
      Header: (props) => (
        <CustomHeader tableProps={props} title={columns[key].label} className='min-w-125px' />
      ),
      id: columns[key].name,
      Cell: ({...props}) => {
        const entry = props.data[props.row.index]
        // console.log(entry)
        let fill = <ColumnString value={entry[columns[key].name]} />
        if (columns[key].name === 'factory_id') {
          fill = <ColumnString value={entry.factoryName} />
        }
        if (columns[key].name === 'efective_date') {
          const date = new Date(entry[columns[key].name])
          const format = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
            '0' + date.getDate()
          ).slice(-2)}`
          fill = <ColumnString value={format} />
        }
        const queryParameters = new URLSearchParams(window.location.search)
        const date_owner = queryParameters.get('date_owner')
        let colecttion = 'master_question'
        if (date_owner !== 'quesioners') {
          colecttion = 'posts'
        }
        return (
          <Link className='menu-link px3' to={`/${colecttion}?efective_date=${entry.id}`}>
            {fill}
          </Link>

          // <a href={date} style={{cursor: 'pointer'}}>
          //   <ColumnString value={entry[columns[key].name]} />
          // </a>
        )
      },
    })
  }
  columntable.push({
    Header: (props) => (
      <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell id={props.data[props.row.index].id} />,
  })
  const usersColumns: ReadonlyArray<Column<any>> = columntable
  return {
    usersColumns,
  }
}
