import {FC, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useLocation, Link, useNavigate} from 'react-router-dom'
import {ErrorMessage} from '@hookform/error-message'
import {useApi} from '../../../../hooks/useApi'
import {useFileCheck} from '../../../../hooks/useModelCheck'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Factory} from '../../../../models/factory'
import {MasterQuestion} from '../../../../models/master_question'
import ClipLoader from 'react-spinners/ClipLoader'

export const EditPage: FC = () => {
  const [collectionName, setCollectionName] = useState<string>('')
  const [options, setOptions] = useState<string[]>([])
  const [optionsEn, setOptionsEn] = useState<string[]>([''])
  const [correctAnswer, setcorrectAnswer] = useState<string>('')
  const [correctAnswerEn, setcorrectAnswerEn] = useState<string>('')
  const [formUpdate, setFormUpdate]: any = useState({})
  const [factory, setFactory] = useState<Factory[]>([])
  const [factorySelector, setFactorySelector] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [eDate, setEDate] = useState('')
  const [paramEDate, setparamEDate] = useState('')
  const routeParams = useLocation()
  const routeUrl = routeParams.pathname.replace('/', '')
  const splitCollection = routeUrl.split('/')
  // const route = 'master_question'
  const route = splitCollection[0]
  const id = splitCollection[1]
  const {getModel} = useFileCheck()
  const {getOneById, update, getAll} = useApi()
  const MySwal = withReactContent(Swal)
  const collectionExist = getModel(`${route}`)
  const {layout} = collectionExist
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<MasterQuestion>({})
  const getFactory = async () => {
    const response = await getAll<Factory>('factory')
    const {data} = response.data
    setFactory(data)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const getName = layout.map(function (layoutName: any) {
      return layoutName.name
    })
    getOneById(`${route}`, `${id}`).then((response) => {
      const {data}: any = response
      getName.forEach((field: any) => setValue(field, data[field]))
      setFormUpdate({...data})
      setOptions(data.answer || [])
      setOptionsEn(data.answer_en || [])
      setcorrectAnswer(data['correct_answer'])
      setcorrectAnswerEn(data['correct_answer_en'])
      setFactorySelector(data['factory_id'])
      setValue('question', data['question'])
      setValue('question_en', data['question_en'])
      setValue('correct_answer', data['correct_answer'])
      setValue('correct_answer_en', data['correct_answer_en'])
      setValue('factory_id', data['factory_id'])
      setValue('efective_date_id', data['efective_date_id'])
      setEDate(data['efective_date_id'])
      setparamEDate(`?efective_date=${data['efective_date_id']}`)

    })
    const renameCollection = () => {
      const words = route.split('_')
      const titles: string[] = []
      for (const key in words) {
        const word = words[key]
        titles.push(word.charAt(0).toUpperCase() + word.slice(1))
      }
      return titles.join(' ')
    }
    setCollectionName(renameCollection())
    getFactory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue])
  const onSubmit = async (data: MasterQuestion, e: any) => {
    setLoading(true)
    Object.assign(data, {
      answer: options,
      answer_en: optionsEn,
      correct_answer: data.correct_answer,
      correct_answer_en: data.correct_answer_en,
    })
    const payload = data
    await update(`${route}`, id, payload)
      .then((response) => {
        MySwal.fire({
          title: <strong className='text-black'>Success</strong>,
          html: <i className='text-black'>Data Update!</i>,
          icon: 'success',
        })
        navigate(`/${route}?efective_date=${eDate}`)
      })
      .catch((error) => {
        if (error.response) {
          const {data} = error.response
          MySwal.fire({
            title: <strong className='text-black'>Failed</strong>,
            html: <i className='text-black'>{data.errors[0].message}!</i>,
            icon: 'error',
          })
        }
      })
      .finally(() => setLoading(false))
  }
  const onError = (errors: any, e: any) => console.log(errors, e)
  const handleChange = (i: number, e: any) => {
    let newFormValues = [...options]
    newFormValues[i] = e.target.value
    setOptions(newFormValues)
    setValue('answer', newFormValues)
    if (correctAnswer === options[i]) {
      setcorrectAnswer(e.target.value);
      setValue('correct_answer', e.target.value);
    }
  }
  const addFormFields = () => {
    setOptions((options) => [...options, ''])
  }
  const removeFormFields = (i: number) => {
    let newFormValues = [...options]
    newFormValues.splice(i, 1)
    setOptions(newFormValues)
  }
  const handleChangeEn = (i: number, e: any) => {
    let newFormValues = [...optionsEn]
    newFormValues[i] = e.target.value
    setOptionsEn(newFormValues)
    setValue('answer_en', newFormValues)
    if (correctAnswerEn === optionsEn[i]) {
      setcorrectAnswerEn(e.target.value);
      setValue('correct_answer_en', e.target.value);
    }
  }
  const addFormFieldsEn = () => {
    setOptionsEn((optionsEN) => [...optionsEn, ''])
  }
  const removeFormFieldsEn = (i: number) => {
    let newFormValues = [...optionsEn]
    newFormValues.splice(i, 1)
    setOptionsEn(newFormValues)
  }
  const handleCorrectAnswerChangeEn = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setcorrectAnswerEn(e.target.value);
    setValue('correct_answer_en', e.target.value);
  };
  return (
    <div>
      {loading === true ? (
        <div className='loader'>
          <ClipLoader
            color={'#00008B'}
            loading={true}
            size={50}
            aria-label='Loading Spinner'
            data-testid='loader'
            // style={{}}
          />
        </div>
      ) : (
        ''
      )}
      <div className='card card-custom gutter-b'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>
              Edit {collectionName}
              <small></small>
            </h3>
          </div>
        </div>
        <div className='card-body'>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className='row'>
            <div className='mb-2 col-4'>
                <label className='form-label text-black'>Factory Id</label>
                <select
                  {...register('factory_id', {
                    required: true,
                  })}
                  className='form-control form-control-solid text-black'
                  id=''
                >
                  <>
                    {factory.map((entry: Factory) => {
                      return (
                        <option
                          key={entry.id}
                          className='form-control form-control-solid text-black'
                          id=''
                          value={entry.id}
                          selected={factorySelector === entry.id}
                        >
                          {entry.name}
                        </option>
                      )
                    })}
                  </>
                </select>
                <ErrorMessage errors={errors} name='factory_id' />
                <ErrorMessage
                  errors={errors}
                  name='factory_id'
                  render={() => <p className='text-danger'>Factory is required !</p>}
                />
              </div>
              {layout.map((fields: any, index: number) => {
                if (['text'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        defaultValue={formUpdate[fields.name]}
                        type='text'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['textarea'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </label>
                      <textarea
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        defaultValue={formUpdate[fields.name]}
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['select'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <div className='form-group'>
                        <label className='text-black'>{fields.label}</label>
                        <select
                          className='form-control form-control-solid text-black'
                          {...register(fields.name)}
                          defaultValue={formUpdate[fields.name]}
                        >
                          {fields.options.map((option: any, indexOpt: number) => (
                            <option className='text-black' key={indexOpt} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <ErrorMessage errors={errors} name={fields.name} />
                        <ErrorMessage
                          errors={errors}
                          name={fields.name}
                          render={() => <p className='text-danger'>{fields.name} is required !</p>}
                        />
                      </div>
                    </div>
                  )
                }
                if (['date'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        defaultValue={formUpdate[fields.name]}
                        type='date'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['email'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                          pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        })}
                        defaultValue={formUpdate[fields.name]}
                        type='email'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                return layout
              })}
            </div>
            <h3 className='mt-5'>In Indonesian</h3>
            <hr />
            <div className='row'>
             <div className='mb-2 col-12'>
                <label className='form-label text-black'>Question (Indonesian)</label>
                <textarea
                    {...register('question', {
                      required: 'question',
                    })}
                    defaultValue={formUpdate['question']}
                    className='text-black form-control form-control-solid'
                    placeholder='Write your question in Indonesia'
                    name='question'
                  />
                  <ErrorMessage errors={errors} name='question' />
                  <ErrorMessage
                    errors={errors}
                    name='question'
                    render={() => <p className='text-danger'>Question is required !</p>}
                  />
              </div>
              <div className='col-6'>
                <label className='form-label text-black'>Answer</label>
                {options?.map((element: string, indexOpt: number) => (
                  <div key={indexOpt} className='row'>
                    <div className='mb-2 col-10'>
                      <input
                        type='text'
                        className='form-control form-control-solid text-black'
                        placeholder={`Answer${indexOpt + 1}`}
                        defaultValue={element}
                        // {...register(`answer[${indexOpt}]`, {
                        //   required: true,
                        // })}
                        onChange={(e) => handleChange(indexOpt, e)}
                      />
                      <ErrorMessage errors={errors} name='answer' />
                      <ErrorMessage
                        errors={errors}
                        name={`anser[${indexOpt}]`}
                        render={() => (
                          <p className='text-danger'>Answer{indexOpt + 1} is required !</p>
                        )}
                      />
                    </div>
                    {indexOpt ? (
                      <div className='mb-2 col-2'>
                        <button
                          type='button'
                          className='btn btn-light-danger remove'
                          onClick={() => removeFormFields(indexOpt)}
                          style={{width: '10px'}}
                        >
                          <i className='fas fa-minus'></i>
                        </button>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>  
              <div className='mb-2 col-12'>
                <button
                  className='btn btn-light-primary add'
                  type='button'
                  onClick={() => addFormFields()}
                  style={{width: '10px'}}
                >
                  <i className='fas fa-plus'></i>
                </button>
              </div>
              <div className='mb-2 col-6'>
                <label className='form-label text-black'>Corect Answer</label>
                <select
                  {...register('correct_answer')}
                  className='form-control form-control-solid text-black'
                  value={correctAnswer}
                >
                  <>
                    {options.map((element: string, index: number) => {
                      return (
                        <option
                          className='text-black'
                          key={index}
                          value={element}
                          selected={correctAnswer === element}
                        >
                          {element}
                        </option>
                      )
                    })}
                  </>
                  <ErrorMessage errors={errors} name='correct_answer' />
                  <ErrorMessage
                    errors={errors}
                    name={`correct_answer`}
                    render={() => <p className='text-danger'>Correct Answer is required !</p>}
                  />
                </select>
                {/* <input
                  type="text"
                  className="form-control form-control-solid text-black"
                  name='correct_answer'
                /> */}
              </div>
            </div>
            <h3 className='mt-5'>In English</h3>
            <hr />
            <div className='row'>
             <div className='mb-2 col-12'>
                <label className='form-label text-black'>Question (English)</label>
                <textarea
                    {...register('question_en', {
                      required: 'question_en',
                    })}
                    defaultValue={formUpdate['question_en']}
                    className='text-black form-control form-control-solid'
                    placeholder='Write your question in English'
                    name='question_en'
                  />
                  <ErrorMessage errors={errors} name='question_en' />
                  <ErrorMessage
                    errors={errors}
                    name='question_en'
                    render={() => <p className='text-danger'>English Question is required !</p>}
                  />
              </div>
              <div className='col-6'>
                <label className='form-label text-black'>Answer</label>
                {optionsEn?.map((element: string, indexOpt: number) => (
                  <div key={indexOpt} className='row'>
                    <div className='mb-2 col-10'>
                      <input
                        type='text'
                        className='form-control form-control-solid text-black'
                        placeholder={`answer_en${indexOpt + 1}`}
                        defaultValue={element}
                        // {...register(`answer[${indexOpt}]`, {
                        //   required: true,
                        // })}
                        onChange={(e) => handleChangeEn(indexOpt, e)}
                      />
                      <ErrorMessage errors={errors} name='answer_en' />
                      <ErrorMessage
                        errors={errors}
                        name={`answer_en[${indexOpt}]`}
                        render={() => (
                          <p className='text-danger'>Answer{indexOpt + 1} is required !</p>
                        )}
                      />
                    </div>
                    {indexOpt ? (
                      <div className='mb-2 col-2'>
                        <button
                          type='button'
                          className='btn btn-light-danger remove'
                          onClick={() => removeFormFieldsEn(indexOpt)}
                          style={{width: '10px'}}
                        >
                          <i className='fas fa-minus'></i>
                        </button>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>  
              <div className='mb-2 col-12'>
                <button
                  className='btn btn-light-primary add'
                  type='button'
                  onClick={() => addFormFieldsEn()}
                  style={{width: '10px'}}
                >
                  <i className='fas fa-plus'></i>
                </button>
              </div>
              <div className='mb-2 col-6'>
                <label className='form-label text-black'>Corect Answer</label>
                <select
                  {...register('correct_answer_en')}
                  className='form-control form-control-solid text-black'
                  value={correctAnswerEn}
                  onChange={handleCorrectAnswerChangeEn}

                >
                  <>
                    {optionsEn.map((element: string, index: number) => {
                      return (
                        <option
                          className='text-black'
                          key={index}
                          value={element}
                          selected={correctAnswerEn === element}
                        >
                          {element}
                        </option>
                      )
                    })}
                  </>
                  <ErrorMessage errors={errors} name='correct_answer_en' />
                  <ErrorMessage
                    errors={errors}
                    name={`correct_answer_en`}
                    render={() => <p className='text-danger'>Correct Answer is required !</p>}
                  />
                </select>
              </div>
            </div>
            <div className='mt-10'>
              <Link to={`/${route + paramEDate}`}>
                <button className='btn btn-secondary cancel'>Cancel </button>
              </Link>
              <button type='submit' className='btn btn-success'>
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
