import {FC, useState, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useLocation, useNavigate, Link} from 'react-router-dom'
import {ErrorMessage} from '@hookform/error-message'
import {useApi} from '../../../../hooks/useApi'
import {useFileCheck} from '../../../../hooks/useModelCheck'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Form from 'react-bootstrap/Form'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Factory} from '../../../../models/factory'
import {sysparam} from '../../../../models/sysparam'
import {areas} from '../../../../models/areas'
import ClipLoader from 'react-spinners/ClipLoader'

export const CreatePage: FC = () => {
  const [collectionName, setCollectionName] = useState<string>('')
  const routeParams = useLocation()
  const navigate = useNavigate()
  const routeUrl = routeParams.pathname.replace('/', '')
  const splitCollection = routeUrl.split('/')
  const route = splitCollection[0]
  const {getModel} = useFileCheck()
  const {create, getAll} = useApi()
  const MySwal = withReactContent(Swal)
  const collectionExist = getModel(`${route}`)
  const {layout} = collectionExist
  const [currentImage, setCurrentImage] = useState<File>()
  const [previewImage, setPreviewImage] = useState<string>(
    toAbsoluteUrl('/image-danone/user-profile.jpg')
  )
  const [progress, setProgress] = useState<number>(0)
  const [message] = useState<string>('')
  const [factory, setFactory] = useState<Factory[]>([])
  const [zone, setZone] = useState<sysparam[]>([])
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<areas>()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFactory = async () => {
    const response = await getAll<Factory>('factory')
    const {data} = response.data
    setFactory(data)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const renameCollection = () => {
      const words = route.split('_')
      const titles: string[] = []
      for (const key in words) {
        const word = words[key]
        titles.push(word.charAt(0).toUpperCase() + word.slice(1))
      }
      return titles.join(' ')
    }
    setCollectionName(renameCollection())
    getFactory()
  }, [])
  const onSubmit = async (data: areas, e: any) => {
    setLoading(true)
    Object.assign(data, {
      photo: currentImage,
    })
    const payload = {data}
    await create(`${route}`, payload.data)
      .then((response) => {
        MySwal.fire({
          title: <strong className='text-black'>Success</strong>,
          html: <i className='text-black'>Data created!</i>,
          icon: 'success',
        })
        navigate(`/${route}`)
      })
      .catch((error) => {
        if (error.response) {
          const {data} = error.response
          MySwal.fire({
            title: <strong className='text-black'>Failed</strong>,
            html: <i className='text-black'>{data.errors[0].message}!</i>,
            icon: 'error',
          })
        }
      })
      .finally(() => setLoading(false))
  }
  const onError = (errors: any, e: any) => console.log(errors, e)
  // for type file Image
  const selectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList
    setCurrentImage(selectedFiles?.[0])
    setPreviewImage(URL.createObjectURL(selectedFiles?.[0]))
    setProgress(0)
  }
  return (
    <div>
      {loading === true ? (
        <div className='loader'>
          <ClipLoader
            color={'#00008B'}
            loading={true}
            size={50}
            aria-label='Loading Spinner'
            data-testid='loader'
            // style={{}}
          />
        </div>
      ) : (
        ''
      )}
      <div className='card card-custom gutter-b'>
        <div className='card-header'>
          <div className='card-title'>
            <h3 className='card-label'>
              Create {collectionName}
              <small></small>
            </h3>
          </div>
        </div>
        <div className='card-body'>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className='row'>
              {layout.map((fields: any, index: number) => {
                if (['text'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <Form.Label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </Form.Label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        type='text'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <Form.Text className='text-muted'>{fields.note}</Form.Text>
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['textarea'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <Form.Label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </Form.Label>
                      <textarea
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <Form.Text className='text-muted'>{fields.note}</Form.Text>
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['select'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <div className='form-group'>
                        <label className='text-black'>{fields.label}</label>
                        <select
                          className='form-control form-control-solid'
                          {...register(fields.name)}
                        >
                          {fields.options.map((option: any, indexOpt: number) => (
                            <option key={indexOpt} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <Form.Text className='text-muted'>{fields.note}</Form.Text>
                        <ErrorMessage errors={errors} name={fields.name} />
                        <ErrorMessage
                          errors={errors}
                          name={fields.name}
                          render={() => <p className='text-danger'>{fields.name} is required !</p>}
                        />
                      </div>
                    </div>
                  )
                }
                if (['date'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <Form.Label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </Form.Label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                        })}
                        type='date'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <Form.Text className='text-muted'>{fields.note}</Form.Text>
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['email'].indexOf(fields.type) >= 0) {
                  return (
                    <div className={`mb-4 ${fields.col}`} key={`field-${fields.name}`}>
                      <Form.Label className='text-black' htmlFor='input-field'>
                        {fields.label}
                      </Form.Label>
                      <input
                        {...register(fields.name, {
                          required: fields.required,
                          pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        })}
                        type='email'
                        className='text-black form-control form-control-solid'
                        placeholder={fields.placeholder}
                        name={fields.name}
                      />
                      <Form.Text className='text-muted'>{fields.note}</Form.Text>
                      <ErrorMessage errors={errors} name={fields.name} />
                      <ErrorMessage
                        errors={errors}
                        name={fields.name}
                        render={() => <p className='text-danger'>{fields.name} is required !</p>}
                      />
                    </div>
                  )
                }
                if (['image'].indexOf(fields.type) >= 0) {
                  return (
                    <div key={`field-${fields.name}`}>
                      {currentImage && progress > 0 && (
                        <div className='progress my-3'>
                          <div
                            className='progress-bar progress-bar-info'
                            role='progressbar'
                            aria-valuenow={progress}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            style={{width: progress + '%'}}
                          >
                            {progress}%
                          </div>
                        </div>
                      )}

                      {previewImage && (
                        <div>
                          <img
                            className='preview'
                            src={previewImage}
                            alt='User blank logo'
                            width='30%'
                          />
                        </div>
                      )}

                      {message && (
                        <div className='alert alert-secondary mt-3' role='alert'>
                          {message}
                        </div>
                      )}
                      <div className={`mb-4 ${fields.col}`}>
                        <Form.Label className='text-black' htmlFor='input-field'>
                          {fields.label}
                        </Form.Label>
                        <Form.Control
                          {...register(fields.name, {
                            required: fields.required,
                          })}
                          type='file'
                          onChange={selectImage}
                          accept='image/*'
                          className='text-black form-control form-control-solid'
                          placeholder={fields.placeholder}
                        />
                        <Form.Text className='text-muted'>{fields.note}</Form.Text>
                        <ErrorMessage errors={errors} name={fields.name} />
                        <ErrorMessage
                          errors={errors}
                          name={fields.name}
                          render={() => <p className='text-danger'>{fields.name} is required !</p>}
                        />
                      </div>
                    </div>
                  )
                }

                return layout
              })}
            </div>
            <Link to={`/${route}`}>
              <button className='btn btn-secondary cancel'>Cancel </button>
            </Link>
            <button type='submit' className='btn btn-success'>
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
