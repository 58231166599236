/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {ID} from '../../../../_metronic/helpers'
import { ActionCellApprove } from './ActionCellApprove'

type Props = {
  id: ID
}
const ActionsCell: FC<Props> = ({id}) => {

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])


  return (
    <>
        <ActionCellApprove id={id} />
    </>
  )
}

export {ActionsCell}
