import {FC} from 'react'
import danone from '../../layout/img/page-4/img-other/Danone-Logo-2017-present 9.png'

const Footer: FC = () => {
  return (
    <>
      <footer className='d-flex d-md-none justify-content-center w-100'>
        <img src={danone} alt='' />
      </footer>
    </>
  )
}

export {Footer}
