import {FC, createContext, useContext, useState} from 'react'
import {ResourceContextModel, state} from '../../../base_models/ResourceContext'
import {WithChildren} from '../../../_metronic/helpers'
import {useLocation} from 'react-router-dom'
import {useFileCheck} from '../../../hooks/useModelCheck'

const Context = createContext<ResourceContextModel>(state)

const ResourceProvider: FC<WithChildren> = ({children}) => {
  const location = useLocation()
  const {getModel} = useFileCheck()
  const routeParams = location.pathname.replace('/', '')
  const collectionModel = getModel(routeParams).collection

  const [collection, setCollection] = useState<string>(collectionModel || '')

  const updateCollection = (name: string) => {
    setCollection(name)
  }

  return (
    <Context.Provider
      value={{
        collection,
        updateCollection,
      }}
    >
      {children}
    </Context.Provider>
  )
}

const useResourceContext = () => useContext(Context)

export {ResourceProvider, useResourceContext}
